import { useEffect, useState } from "react"
import { getRegionsWhereLabsAreHiring } from "../../../services/regions"
import { TRegion } from "../../../lib/types/regions";
import { TProcessStates } from "../../../lib/types/general";
import { useRouteNavigation } from "../../../lib/common/routes";

const useSearchLabs = () => {
    const [regions, setRegions] = useState<TRegion[]>([]);
    const [workingState, setWorkingState] = useState<TProcessStates>("idle");

    useEffect(() => {
        setWorkingState("working");
        getRegionsWhereLabsAreHiring().then((data) => {

            setRegions(data.data);

            setWorkingState("done");
        })
    }, [])

    return {
        workingState,
        regions
    }
}

export const Labs = () => {

    const { regions, workingState } = useSearchLabs();
    const { getRoute, navigateRouteClickHandler } = useRouteNavigation();


    return (
        <>
            <div className={"container-fluid bg-dark text-white px-0 py-5" + ((workingState !== 'done') ? 'cssLoading' : '')}>
                <div className="container">
                    <div className="row p-3 p-md-5">
                        <h1 className="highlighted-title home-title">LAB LOCATOR</h1>
                        <h2 className="ms-2">CHECK OUT WHICH LABS ARE HIRING AND WHERE</h2>

                        <div className="col-lg-5 text-container-black pt-4 pb-0 px-4 d-flex align-items-center">

                            {/* <div className="row pb-3 ps-1 fs-4"><strong>LAB LOCATOR</strong></div> */}
                            {/* <div className="row ps-3 fs-5 mb-0 fw-bold">
                                CHECK OUT WHICH LABS ARE HIRING AND WHERE
                            </div> */}
                            <div className="row ps-4">
                                Take a look at the region you're interested in and see if there are any labs looking to hire.
                                <br /><br />
                                We are adding labs all the time so check back if you don't see what you're after.
                                <br /><br />
                                If you do see a lab that looks interesting, sign up.
                                <br /><br />
                                You never know what opportunities are waiting for you.
                            </div>
                        </div>
                        <div className="col-lg-7 mt-4 mt-lg-2 ">
                            {/* <div className="row p-3 p-lg-4 m-0 m-lg-2"></div> */}
                            <div className="row mt-5 text-center">
                                {
                                    regions.map((r, idx) =>
                                        <a
                                            href={getRoute("hiringlabs", r.id)}
                                            onClick={(e) => navigateRouteClickHandler("hiringlabs", e, r.id)}
                                            title={`Search for labs in ${r.name}`}
                                            className="col-12 col-md-6 col-lg-4 text-decoration-none h5 mb-4"
                                        >
                                            <div key={"h_" + idx}
                                                className="map-regions rounded-3 p-5 d-flex align-items-center justify-content-center"
                                                style={{ backgroundImage: `url(${r.img_path})` }}>
                                                <div>{r.name}</div>
                                            </div>
                                        </a>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}