import { Instance, createPopper } from '@popperjs/core'
import { useRef } from 'react'


type TPopoverProps = {
    title: string,
    message: string,
    refObject: React.RefObject<any>,
    nextPopup?: () => void
}

export const popover = ({ title, message, refObject, nextPopup }: TPopoverProps) => {

    let refPopup: React.RefObject<any>;
    let popperInstance: Instance;
    let grayScreen: HTMLElement;

    const coverScreen = document.createElement("div");

    const hideHandler = () => {
        if (popperInstance) {
            refPopup.current.style.display = "none";
            refObject.current.removeChild(grayScreen);
            document.body.removeChild(coverScreen);
            popperInstance.destroy();
        }
    }

    const PopupComponent = () => {
        refPopup = useRef<any>(null);
        return <>

            <div >
                <div ref={refPopup} id="tooltip" role="tooltip" className='bg-light' style={{ display: 'none' }}>
                    <div className='bg-primary text-white'>
                        <p className='p-2 d-flex justify-content-between align-items-center'
                        >{title}
                            <i className="fa-solid fa-circle-question fa-lg"></i>
                        </p>
                    </div>
                    <div className='p-2'>
                        <p>{message}</p>
                    </div>
                    <div className='m-3 d-flex justify-content-end'
                    >
                        {nextPopup !== undefined &&
                            <button className='btn btn-warning btn-sm me-2'
                                onClick={() => {
                                    hideHandler();
                                    nextPopup();
                                }}
                            >Next</button>}
                        <button className='btn btn-secondary btn-sm' onClick={() => hideHandler()}>Done</button>
                    </div>
                    <div id="arrow" data-popper-arrow></div>
                </div>
            </div>
        </>
    }

    const showHandler = () => {



        refPopup.current.style.display = "block"
        refPopup.current.style.zIndex = "10001"
        const htmlElementRef = refObject.current;
        htmlElementRef.style.position = "relative"
        htmlElementRef.scrollIntoView({ behavior: 'smooth', block: "nearest" });

        const grayScreenStyle = {
            position: "absolute",
            left: "0px",
            bottom: "0px",
            width: (htmlElementRef.clientWidth || htmlElementRef.offsetWidth) + "px",
            height: (htmlElementRef.clientHeight || htmlElementRef.offsetHeight) + "px",
            boxShadow: "#c8c8c89a 0px 0px 0px 9999px",
            zIndex: "1000",
            borderRadius: "1.375rem",
        }
        grayScreen = document.createElement("div");
        Object.assign(grayScreen.style, grayScreenStyle);
        htmlElementRef.appendChild(grayScreen)
        coverScreen.className = "wizard-cover";
        document.body.appendChild(coverScreen);
        coverScreen.onclick = hideHandler;

        popperInstance = createPopper(htmlElementRef, refPopup.current, {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
                {
                    name: 'flip',
                    options: {
                        allowedAutoPlacements: ['top', 'bottom'],
                    },
                },
            ],
            placement: "auto",
        });
        popperInstance.update();
    }


    return {
        showHandler,
        Component: <PopupComponent />
    }
}