import { useEffect, useRef, useState } from "react";
import { DropdownMultiSelect, TOptionToShow } from "../../../common/dropdown-multi-select";

export type TReportFilterSettings = {
    name: string;
    state: TOptionToShow[];
};
type TFilterPanelProps = {
    filterCallback: (filterSettings: TReportFilterSettings) => void;
    resetCallback: () => void;
    searchedName: string;
    stateFilterOptions: TOptionToShow[];
};

export const ReportsFilterPanel = (props: TFilterPanelProps) => {
    const [stateFilter, setStateFilter] = useState<TOptionToShow[]>(props.stateFilterOptions);
    const { filterCallback, stateFilterOptions } = props;
    const namesRef = useRef<HTMLInputElement>(null);


    const currStateFilterCallback = (options: TOptionToShow[]) => {
        filterCallback({
            state: options,
            name: namesRef.current?.value || ""
        });
    };

    const resetFiltersClickHandler = () => {
        if (namesRef.current) {
            namesRef.current.value = "";
        }
        setStateFilter(stateFilterOptions);
        filterCallback({
            name: "",
            state: stateFilterOptions
        })
    };

    useEffect(() => {
        if (namesRef.current) {
            namesRef.current.value = props.searchedName
        }
        // eslint-disable-next-line
    }, []);

    return (<>
        <div className="admin-section rounded-1 shadow-sm p-3 mb-3">
            <div className="row">
                <div className="col-md-6 col-lg-4">
                    <input
                        ref={namesRef}
                        type="text"
                        onChange={() => currStateFilterCallback(stateFilter.filter(o => o.selected))}
                        className="mb-2 py-1 px-3 text-start border border-1 border-info rounded w-100"
                        placeholder="Filter by name:"
                        aria-label="filter by name:"
                        aria-describedby="button-addon2"
                    />
                </div>
                <div className="col-md-6 col-lg-4">
                    <DropdownMultiSelect
                        title="Filter by report state:"
                        applyFilterCallback={currStateFilterCallback}
                        optionsToShow={stateFilter}
                        setOptionsToShow={setStateFilter}
                        btnColor="info"
                    />
                </div>
                <div className="col-lg-4 text-center">
                    <button
                        className="btn btn-outline-info py-1 w-100"
                        onClick={resetFiltersClickHandler}
                        data-testid="reset-button"
                    >
                        Reset
                    </button>
                </div>
            </div>
        </div>
    </>)
}
