import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { RouteEnumType } from "../types/routes";

export const useRouteNavigation = () => {
    const navigateTo = useNavigate();

    const getRoute = (
        where: RouteEnumType,
        extraURLParams?: string
    ): string => {
        const extraParams = extraURLParams ? "/" + extraURLParams : "";

        switch (where) {
            case "home":
                return "/";
            case "homeRegistered":
                return "/home";
            case "logoutduetoinactivity":
                return "/logoutduetoinactivity";
            case "aboutUs":
                return "/aboutUs";
            case "signupTech":
                return "/signup/tech";

            case "signupLab":
                return "/signup/lab";
            case "dashboardTech":
                return "/dashboard/tech";
            case "dashboardLab":
                return "/dashboard/lab";
            case "subscriptionSelection":
                return "/subscription/selection";
            case "dentalTechInfo":
                return "/info/tech" + extraParams;
            case "dentalLabInfo":
                return "/info/lab" + extraParams;
            case "dentalLabAccount":
                return "/account/lab";
            case "dentalTechAccount":
                return "/account/tech";
            case "dentalTechSearch":
                return "/search/techs";
            case "dentalLabSearch":
                return "/search/labs";
            case "hiringlabs":
                return "/hiringlabs" + extraParams;
            case "adminDashboard":
                return "/backOffice/dashboard";
            case "adminReportList":
                return "/backOffice/report-list";
            case "adminUserInfoLab":
                return "/backOffice/userInfo/lab";
            case "adminUserInfoTech":
                return "/backOffice/userInfo/tech";
            case "adminPositions":
                return "/backOffice/admin-positions";
            case "termsOfUse":
                return "/terms-of-use";
            case "privacyPolicy":
                return "/privacy-policy";
            case "cookiePolicy":
                return "/cookie-policy";
            case "contact":
                return "/contact-us";
            case "story":
                return "/story";
            case "aboutMark":
                return "/about-us";
            default:
                return "";
        }
    };

    const navigateRouteClickHandler = (
        where: RouteEnumType,
        e: MouseEvent,
        extraURLParams?: string,
        canGoBack = false
    ) => {
        e.preventDefault();

        navigateToRoute(where, extraURLParams, canGoBack);
    };

    const navigateToRoute = (
        where: RouteEnumType,
        extraURLParams?: string,
        canGoBack = false
    ) => {
        const routeTo = getRoute(where);
        const extraParams = extraURLParams ? "/" + extraURLParams : "";
        navigateTo(routeTo + extraParams, {
            state: { canGoBack },
        });
    };

    return { navigateRouteClickHandler, getRoute, navigateToRoute };
};
