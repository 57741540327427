import { useRouteNavigation } from "../../../lib/common/routes";
import { RouteEnumType } from "../../../lib/types/routes";
import { useUserService } from "../../../services/users";

export const Footer = () => {

    const { navigateRouteClickHandler, getRoute } = useRouteNavigation();
    const { isLoggedIn, getMyAccountRoute,
        getDashboardRoute, navigateToDashboardClickHandler,
        navigateToMyHomeClickHandler, getHomeRoute,
        navigateToMyAccountClickHandler, userInfo } = useUserService();

    const tech_lab_list: RouteEnumType = !isLoggedIn ?
        "home" :
        (userInfo?.type === "lab" ? "dentalTechSearch" : "dentalLabSearch");

    const tech_lab_list_labelName = tech_lab_list === "home"
        ? ""
        : (tech_lab_list === "dentalLabSearch" ? "Lab List" : "Tech List");

    return (
        <>
            <div className="container-fluid p-5 footer text-white text-left mt-5">
                <div className="container">
                    <div className="row">
                        <img className="w-auto" src="../images/global/footer-logo-transparent.png" alt="" />
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-3 pt-5">
                            <h5>GENERAL</h5>

                            <a className="btn-link" href={getHomeRoute()} onClick={navigateToMyHomeClickHandler} >Home</a><br />
                            <a className="btn-link" href={getRoute("contact")} onClick={(e) => navigateRouteClickHandler("contact", e)} >Contact</a><br />

                            {!isLoggedIn && (<>
                                <a className="btn-link" href={getRoute("signupTech")} onClick={(e) => navigateRouteClickHandler("signupTech", e)}>Tech Signup</a> <br />
                                <a className="btn-link" href={getRoute("signupLab")} onClick={(e) => navigateRouteClickHandler("signupLab", e)}>Lab Signup</a>
                            </>)
                            }
                            {
                                isLoggedIn && (
                                    <>

                                        <a className="btn-link" href={getDashboardRoute()} onClick={navigateToDashboardClickHandler}>My Dashboard</a> <br />
                                        <a className="btn-link" href={getMyAccountRoute()} onClick={navigateToMyAccountClickHandler} >My Account</a> <br />
                                        <a className="btn-link" href={getRoute(tech_lab_list)} onClick={(e) => navigateRouteClickHandler(tech_lab_list, e)}>{tech_lab_list_labelName}</a>
                                    </>
                                )
                            }

                        </div>
                        <div className="col-md-4 col-lg-3 pt-5">
                            <h5>LEGAL</h5>
                            <a className="btn-link" href={getRoute("termsOfUse")} onClick={(e) => navigateRouteClickHandler("termsOfUse", e)}>Terms of Use</a> <br />
                            <a className="btn-link" href={getRoute("privacyPolicy")} onClick={(e) => navigateRouteClickHandler("privacyPolicy", e)}>Privacy Policy</a><br />
                            {/* <a>Copyright Policy</a> */}
                            <a className="btn-link" href={getRoute("cookiePolicy")} onClick={(e) => navigateRouteClickHandler("cookiePolicy", e)}>Cookie Policy</a>
                        </div>
                        <div className="col-md-4 col-lg-3 pt-5">
                            <h5>CONTACT US</h5>
                            <p>Tel: 541 . 779 . 6503</p>
                            <p>Email: info@winwingo.com</p>
                            <p className="m-0">1150 Crater Lake Ave. Suite H <br /> Medford, OR. 97504</p>
                        </div>
                        <div className="col-lg-3 col-md-6 pt-5">
                            <h5>SUBSCRIBE</h5>
                            <p>Sign up to receive news and updates.</p>
                            <div className="input-group mb-3">
                                <input disabled type="text" className="form-control" placeholder="Coming soon!" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button disabled className="btn btn-outline-light" type="button" id="button-addon2">Submit</button>
                            </div>
                        </div>
                        {/* <div className="col-md-3"></div> */}
                        <div className="col-md-12 text-center pt-5">
                            <p>Version: {process.env.REACT_APP_SFW_VERSION}</p>
                            <p>© 2024 WinWinGo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}