import { useAuthContext } from "../contexts/auth-context";
import { apiRequestSecuredPost } from "../lib/common/api";

export const useSubscriptionService = () => {
  const { getUserTokenId } = useAuthContext();

  /**
   *
   * @param months
   * @returns string (url of checkout)
   */
  const generateSubscriptionSession = async (
    months: "12" | "6" | "1"
  ): Promise<string> => {
    const response = await apiRequestSecuredPost<any>(
      "payment/checkoutsession",
      await getUserTokenId(),
      {
        months,
      }
    );

    return response.data.sessionInfo.url;
  };

  const savePaymentInfo = async (
    checkoutSessionId: string,
    subscriptionId: number
  ) => {
    await apiRequestSecuredPost<any>(
      "users/savepaymentinfo",
      await getUserTokenId(),
      {
        checkoutSessionId,
        subscriptionId,
      }
    );
  };

  return {
    generateSubscriptionSession,
    savePaymentInfo,
  };
};
