import { useState } from "react";
import { useAuthContext } from "../contexts/auth-context";
import {
    apiRequestPost,
    apiRequestSecureGet,
    apiRequestSecuredPost,
} from "../lib/common/api";
import {
    TGenericIdName,
    TMatchInfo,
    TProcessStates,
} from "../lib/types/general";
import {
    TApiLab,
    TGetLabs,
    TLabFilter,
    TLabSearchResultsResponse,
    TTicketmaster,
    TYelpApiResponse,
    TYelpLandmarkResponse,
} from "../lib/types/labs";
import { getRegions } from "./regions";

const Labs_Urls = {
    forInvitations: "users/labs/forInvitations",
    contactList: "users/labs/frommycontactlist/page",
    search: "users/labs/search/page",
    searchNames: "users/labs/search/names/page",
    publicHiringLabs: "labs/hiringByRegion/page",
    blacklist: "users/getblacklist/page",
    matches: "/labs/matches",
};

export const useLabs = () => {
    const { getUserTokenId, isLoggedIn } = useAuthContext();

    const [state, setState] = useState<TProcessStates>("idle");
    const [regions, setRegions] = useState<TGenericIdName[]>([]);

    const getRegionsForLabs = async () => {
        if (regions.length > 0) return regions;
        const resp = await getRegions();
        setRegions(resp);
    };

    const getMatches = async (): Promise<TMatchInfo[]> => {
        setState("working");
        const data = await apiRequestSecureGet<{ data: TMatchInfo[] }>(
            Labs_Urls.matches,
            await getUserTokenId()
        );
        setState("idle");
        return data.data.data;
    };
    const getLabs = async (
        url: string,
        filter: TLabFilter,
        pageNumber: string
    ): Promise<{ labs: TApiLab[]; totalCount: number }> => {
        setState("working");
        const [, response] = await Promise.all([
            getRegionsForLabs(),
            apiRequestSecuredPost<TGetLabs>(
                pageNumber ? `${url}=${pageNumber}` : url,
                await getUserTokenId(),
                {
                    filter: {
                        positions: filter.filterPositions,
                        name: filter.filterName,
                        regions: filter.filterRegions,
                    },
                }
            ),
        ]);
        const { labs, totalCount } = response.data;
        setState("idle");

        return { labs, totalCount };
    };

    const getLabsForInvitations = () =>
        getLabs(Labs_Urls.forInvitations, {}, ""); //TODO: Fix when implementing pagination

    const getLabsFromContactList = (pageNumber: number) =>
        getLabs(Labs_Urls.contactList, {}, pageNumber.toString());

    const getLabsSearch = (filter: TLabFilter, pageNumber: number) =>
        getLabs(Labs_Urls.search, filter, pageNumber.toString());

    const getLabsSearchName = (pageNumber: number, filter: TLabFilter) =>
        getLabs(Labs_Urls.searchNames, filter, pageNumber.toString());

    const getLab = async (userId: string): Promise<TApiLab[]> => {
        setState("working");
        const response = await apiRequestSecureGet<TLabSearchResultsResponse>(
            "users/labs/" + userId,
            await getUserTokenId()
        );

        setState("idle");
        return response.data.users.labs;
    };

    const getLabsInBlacklist = async (pageNumber: number) =>
        getLabs(Labs_Urls.blacklist, {}, pageNumber.toString());

    const getHiringLabs_public = async (
        filter: TLabFilter,
        pageNumber: number
    ) => {
        setState("working");
        const filterData = {
            filter: {
                regions: filter.filterRegions,
                positions: filter.filterPositions,
            },
        };

        const data = isLoggedIn
            ? await apiRequestSecuredPost<TGetLabs>(
                  `${Labs_Urls.publicHiringLabs}=${pageNumber}`,
                  await getUserTokenId(),
                  filterData
              )
            : await apiRequestPost<TGetLabs>(
                  `${Labs_Urls.publicHiringLabs}=${pageNumber}`,
                  filterData
              );

        setState("idle");
        return {
            data: data.data.labs,
            totalCount: data.data.totalCount,
        };
    };
    const getRestaurants = async (zip: string): Promise<TYelpApiResponse> => {
        const response = await apiRequestSecureGet<TYelpApiResponse>(
            "labs/restaurants/" + zip,
            await getUserTokenId()
        );
        return response.data;
    };
    const getEvents = async (zip: string): Promise<TTicketmaster> => {
        const response = await apiRequestSecureGet<TTicketmaster>(
            "labs/events/" + zip,
            await getUserTokenId()
        );
        return response.data;
    };

    const getLandmarks = async (
        zip: string
    ): Promise<TYelpLandmarkResponse> => {
        const response = await apiRequestSecureGet<TYelpLandmarkResponse>(
            "labs/landmarks/" + zip,
            await getUserTokenId()
        );
        return response.data;
    };

    return {
        state,
        regions,
        getLab,
        getLabsForInvitations,
        getLabsFromContactList,
        getLabsSearch,
        getLabsSearchName,
        getMatches,
        getRestaurants,
        getEvents,
        getLandmarks,
        public: {
            getHiringLabs: getHiringLabs_public,
        },
        getLabsInBlacklist,
    };
};
