import { useState } from "react";
import {
  TInviteMessage,
  TMessage,
  TProcessStates,
} from "../../lib/types/general";
import {
  TAcceptRejectInvitationClickHandler,
  TSetAsReadMessageClickHandler,
  useNotificationContext,
} from "../../contexts/notification-context";
import { useRouteNavigation } from "../../lib/common/routes";
import { RouteEnumType } from "../../lib/types/routes";
import { TUserToChatInfo, useServiceContext } from "../../contexts/service";
import { useFileManagement } from "../../services/file-management";
import { ASSETS_ROUTES } from "../../lib/common/constants";

const InvitationInfo = ({
  invitationInfo,
  acceptInvitationClickHandler,
  rejectInvitationClickHandler,
}: {
  invitationInfo: TInviteMessage;
  acceptInvitationClickHandler: TAcceptRejectInvitationClickHandler;
  rejectInvitationClickHandler: TAcceptRejectInvitationClickHandler;
}) => {
  const [state, setState] = useState<TProcessStates>("idle");
  const { getRoute, navigateRouteClickHandler } = useRouteNavigation();

  const acceptClickHandler = async () => {
    setState("working");
    await acceptInvitationClickHandler(invitationInfo.from);
    setState("idle");
  };

  const rejectClickHandler = async () => {
    setState("working");
    await rejectInvitationClickHandler(invitationInfo.from);
    setState("idle");
  };

  const infoSectionBasedOnInviteFromType: RouteEnumType =
    invitationInfo.from_user_type === "lab"
      ? "dentalLabInfo"
      : "dentalTechInfo";
  const infoRoute = getRoute(
    infoSectionBasedOnInviteFromType,
    invitationInfo.from
  );

  return (
    <li className="list-group-item p-1 text-start small" style={{ zIndex: "999" }}
    >
      <a
        href={infoRoute}
        onClick={(e) =>
          navigateRouteClickHandler(
            infoSectionBasedOnInviteFromType,
            e,
            invitationInfo.from
          )
        }
      >
        {invitationInfo.name}
      </a>
      {state === "idle" ? (
        <div className="row justify-content-center mt-2">
          <div className="col-5 mb-2">
            <button
              disabled={state !== "idle"}
              className="btn btn-primary btn-sm border-0 rounded px-3 py-0"
              onClick={acceptClickHandler}
              data-testid="accept-button"
            >
              <small>
                Accept
              </small>
            </button>
          </div>
          <div className="col-4">
            <button
              disabled={state !== "idle"}
              className="btn btn-secondary btn-sm border-0 rounded px-3 py-0"
              onClick={rejectClickHandler}
              data-testid="ignore-button"
            >
              <small>
                Ignore
              </small>
            </button>
          </div>
        </div>
      ) : (
        <i className="fa-solid fa-spinner fa-spin"></i>
      )}

    </li>
  );
};

type TMessageInfoParams = {
  message: TMessage;
  setMessageAsRead: TSetAsReadMessageClickHandler;
};
export const MessageInfo = (params: TMessageInfoParams) => {
  const { message, setMessageAsRead } = params;

  const { getRoute, navigateToRoute } = useRouteNavigation();

  const ActionComponent = () => {
    const resolveURL = (): string => {
      if (message.action_data.goto) {
        switch (message.action_data.goto) {
          case "dentalTechInfo":
            return getRoute("dentalTechInfo", message.action_data.data);
          case "dentalLabInfo":
            return getRoute("dentalLabInfo", message.action_data.data);
          case "subscriptionSelection":
            return getRoute("subscriptionSelection");

          default:
            break;
        }
      }
      return "";
    };

    const { chats: {
      setShowChat, setUserIdToChat
    } } = useServiceContext()


    return (
      <>
        {message.action_data.goto !== undefined && !message.isFromChats && (
          <a
            href={resolveURL()}
            onClick={(e) => {
              e.preventDefault();
              if (message.action_data.goto) {
                navigateToRoute(
                  message.action_data.goto as RouteEnumType,
                  message.action_data.data
                );
              }
            }}
          >
            {message.title}
          </a>
        )}
        {
          message.action_data.goto !== undefined && message.isFromChats &&
          <a
            href={resolveURL()}
            onClick={(e) => {
              e.preventDefault();
              setUserIdToChat((message.action_data.data as TUserToChatInfo));
              setShowChat(true);
              setMessageAsRead(message.id)
            }}
          >
            {message.title}
          </a>

        }

      </>
    );
  };

  //** Delete Button (X circle) appears when mouse over message */
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const messageUserId = message.isFromChats ? (message.action_data.data as TUserToChatInfo).id : message.action_data.data;
  const { getImagePath } = useFileManagement();

  return (
    <li
      style={{ zIndex: "999" }}
      className="list-group-item p-1 small"
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
    >
      <div className="row">
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img className="notification-logo-user rounded-circle"
            alt="img-user-logo"
            src={getImagePath("logo", "fixed", messageUserId)}
            onError={(e) => {
              (e.target as any).src = ASSETS_ROUTES.IMGS.techAvatarUrl
            }}
          />
        </div>
        <div className="col-8">
          {!message.action_data && message.title}
          {message.action_data && <ActionComponent />}
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <a
            href="#messageRead"
            data-testid="close-button-msg"
            className="btn-link w-auto text-black"
            onClick={() => {
              setMessageAsRead(message.id);
            }}
            role="button"
            style={{
              display: isMouseOver ? "block" : "none",
            }}
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </a>
        </div>
      </div>
    </li>
  );
};

type TConnectionRequestInfoAndButtonsProps = {
  userId: string;
  wideStyle: boolean;
};
export const ConnectionRequestInfoAndButtons = ({
  userId,
  wideStyle,
}: TConnectionRequestInfoAndButtonsProps) => {
  const { acceptInvitationClickHandler, rejectInvitationClickHandler } =
    useNotificationContext();

  const [state, setState] = useState<TProcessStates>("idle");
  const [message, setMessage] = useState<string>(
    !wideStyle ? "Connection request" : "This user wants to contact you!"
  );

  const acceptClickHandler = async () => {
    setState("working");
    await acceptInvitationClickHandler(userId);
    setMessage("You're now connected!");
    setState("done");
  };

  const rejectClickHandler = async () => {
    setState("working");
    await rejectInvitationClickHandler(userId);
    setMessage("Connection rejected");
    setState("done");
  };

  return !wideStyle ? (
    <>
      <div className="alert alert-info text-center" role="alert">
        <p>
          <strong>{message}</strong>
        </p>
        <div className="row">
          {state === "working" ? (
            <div className="text-center">
              <i
                style={{ fontSize: "2rem" }}
                className="fa-solid fa-spinner fa-spin"
              ></i>
            </div>
          ) : state === "done" ? (
            <></>
          ) : (
            <>
              <div className="d-grid gap-2 d-md-block">
                <button
                  className="btn btn-primary px-4 py-0"
                  onClick={(e) => acceptClickHandler()}
                >
                  Accept
                </button>
                <button
                  onClick={(e) => rejectClickHandler()}
                  className="btn btn-danger ms-lg-2 px-4 py-0"
                >
                  Reject
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="alert alert-info w-75 mx-auto text-center" role="alert">
      {message}
      {state === "working" ? (
        <i
          style={{ fontSize: "1.3rem", marginLeft: "1rem" }}
          className="fa-solid fa-spinner fa-spin"
        ></i>
      ) : state === "done" ? (
        <></>
      ) : (
        <>
          <div className="d-md-inline mt-2">
            <button
              className="btn btn-primary px-4 py-0 mx-3"
              onClick={(e) => acceptClickHandler()}
            >
              Accept
            </button>
            <button
              onClick={(e) => rejectClickHandler()}
              className="btn btn-danger ms-lg-2 px-4 py-0"
            >
              Reject
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export const Notifications = () => {
  const {
    notificationsNumber,
    invitationsList,
    messagesList,
    acceptInvitationClickHandler,
    rejectInvitationClickHandler,
    setMessageReadClickHandler,
  } = useNotificationContext();
  const [showNotificationsModal, setShowNotificationsModal] =
    useState<boolean>(false);
  const notificationsNumberStyle: React.CSSProperties = {
    backgroundColor: "red",
    color: "white",
    padding: "2px 5px",
    borderRadius: "100rem",
    fontSize: "0.6rem",
    fontWeight: "bold",
    marginLeft: "-5px",
  };

  const notificationsModalStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: "998",
    right: "5rem",
    top: "5.5rem",
    display: showNotificationsModal ? "" : "none",
    fontSize: ".8rem",
    width: "14rem",
  };
  const openModalCoverStyle: React.CSSProperties = {
    position: "fixed",
    zIndex: "997",
    top: 0,
    left: 0,
  };

  const closeModal = () => setShowNotificationsModal(false);

  const NotificationsModal = () => {
    return (
      <>
        <div
          id="ModalMessages"
          className="bg-white border border-1 border-light rounded shadow text-start p-3"
          style={notificationsModalStyle}
          data-testid="notifications"
        >
          {invitationsList.length === 0 && messagesList.length === 0 && (
            <p className="m-0">You have no notifications.</p>
          )}
          <ul className="list-group list-group-flush">
            {invitationsList.map((invitation, index) => (
              <InvitationInfo
                key={"invite" + index}
                invitationInfo={invitation}
                acceptInvitationClickHandler={acceptInvitationClickHandler}
                rejectInvitationClickHandler={rejectInvitationClickHandler}
              />
            ))}
            {messagesList.map((message, index) => (
              <MessageInfo
                key={"msg_" + index}
                setMessageAsRead={setMessageReadClickHandler}
                message={message}
              />
            ))}
          </ul>
          <div style={openModalCoverStyle} onClick={closeModal}
            className="w-100 h-100"></div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className=" mx-2 mt-2">
        <NotificationsModal />
        <button
          className="bg-transparent border-0"
          // href="#notifications"
          onClick={(e) => {
            e.preventDefault();
            setShowNotificationsModal((prev) => !prev);
          }}
        >
          <i
            data-testid="notifications-bell"
            className="fa-regular fa-bell text-white fs-5"
          ></i>
          {notificationsNumber > 0 && (
            <span
              data-testid="notifications-alarm"
              style={notificationsNumberStyle}
            >
              <strong>
                {notificationsNumber}
              </strong>
            </span>
          )}
        </button>
      </div>
    </>
  );
};