import { Spinner } from "../common/spinner";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { TGenericIdName, TProcessStates } from "../../../../lib/types/general";
import { getIndustryRoles, useIndustryRoles } from "../../../../services/generalInfo";

const PositionRow = ({ position, index, onDelete }: { position: TGenericIdName, index: number, onDelete: (posId: string) => void }) => {
    const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
    const [isEditing, setIsEditing] = useState(false);
    const { deleteIndustryRole, updateIndustryRole } = useIndustryRoles();
    const positionEditNameRef = useRef<HTMLInputElement>(null);

    const deletePosition = async (e: MouseEvent, posId: string) => {
        e.preventDefault();
        e.stopPropagation();

        await deleteIndustryRole(posId);
        onDelete(posId);
    }

    const editPosition = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEditing(true);
    }

    const saveEditPosition = async (posId: string) => {
        const inputName = positionEditNameRef.current?.value;
        if (!inputName) return;
        setCurrWorkingState("working");
        position.name = inputName;
        await updateIndustryRole(posId, inputName);
        setIsEditing(false)
        setCurrWorkingState("done");
    };

    return (
        <div className="list-group-item list-group-item-action">
            <small>
                <div className="row">
                    <div className="col-1 col-md-1"><strong>{index}</strong></div>
                    {!isEditing && <>
                        <div className="col-10 col-md-5">
                            {currWorkingState === 'done' ? position.name : <Spinner />}
                        </div>
                        { }
                        <div className="col-12 col-md-5 text-end text-md-center mt-3 mt-md-0">
                            <button type="button"
                                className="btn btn-secondary"
                                style={{ fontSize: "x-small", width: "4rem" }}
                                onClick={(e) => editPosition(e)}
                            >
                                Edit
                            </button>
                            <button type="button"
                                className="btn btn-danger ms-3"
                                style={{ fontSize: "x-small", width: "2rem" }}
                                onClick={(e) => deletePosition(e, position.id)}
                            >
                                <i className="fa-regular fa-trash-can fa-sm text-white"
                                    data-testid="delete-icon"></i>
                            </button>
                        </div>
                    </>}
                    {isEditing && <>
                        <div className="col-10 col-md-5">
                            <input type="text" ref={positionEditNameRef} className="form-control" defaultValue={position.name} aria-label={position.name} aria-describedby="button-addon2" />
                        </div>

                        <div className="col-12 col-md-5 text-center mt-3 mt-md-0">
                            <button type="button"
                                className="btn btn-info"
                                style={{ fontSize: "x-small", width: "4rem" }}
                                onClick={() => saveEditPosition(position.id)}
                            >
                                Save
                            </button>
                            <button type="button"
                                className="btn btn-danger ms-3"
                                style={{ fontSize: "x-small", width: "4rem" }}
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </>}
                </div>
            </small>
        </div>
    );
};


const PositionList = ({ positions, onDeletePosition }: {
    positions: TGenericIdName[], onDeletePosition: (posId: string) => void
}) => {
    const handleDeletePosition = async (posId: string) => {
        await onDeletePosition(posId);
    };
    return (
        <div className="list-group">
            <div className="list-group-item list-group-item-action active">
                <div className="row">
                    <div className="col-0 col-md-1"></div>
                    <div className="col-6 col-md-5">Position</div>
                    <div className="col-5 col-md-5 text-center">Admin</div>
                </div>
            </div>
            {positions.map((position, index) => (
                <PositionRow key={index} position={position} index={index + 1} onDelete={() =>
                    handleDeletePosition(position.id)
                } />
            ))}
        </div>
    );
};


export const PositionsAdmin = () => {
    const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
    const [positions, setPositions] = useState<TGenericIdName[]>([]);
    const { addIndustryRole, getPositionList } = useIndustryRoles();
    const positionNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getIndustryRoles()
            .then((data) => {
                setPositions(data);
            });
    }, []);

    const refreshList = async () => {
        setCurrWorkingState("working");
        const positionList = await getPositionList();
        setPositions(positionList.data.results);
        setCurrWorkingState("done");
    }

    const handlePositionAdded = async () => {
        const newPosition = positionNameRef.current?.value;
        if (!newPosition) return;
        setCurrWorkingState("working");
        await addIndustryRole(newPosition);
        await refreshList();
        positionNameRef.current.value = "";
        setCurrWorkingState("done");
    };

    const handlePositionDeleted = async (posId: string) => {
        setCurrWorkingState("working");
        const filteredPositions = positions.filter(position => position.id !== posId);
        setPositions(filteredPositions);
        setCurrWorkingState("done");
    };

    return (
        <div className="row">
            <div className="col">
                <div className="admin-section p-3 mb-2">
                    <div className="input-group">
                        <input type="text" ref={positionNameRef} className="form-control" placeholder="Add new position" aria-label="Add new position" aria-describedby="button-addon2" />
                        <div className="input-group-append">
                            <button className="btn btn-primary add-button" type="button" id="button-addon2"
                                onClick={handlePositionAdded}
                            >
                                {currWorkingState === "working" && <><Spinner /></>}
                                {currWorkingState === "done" && <>Add</>}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="admin-section p-3">
                    {currWorkingState === "working" && <><Spinner /> Loading...</>}
                    {currWorkingState === "done" && <PositionList positions={positions} onDeletePosition={handlePositionDeleted} />}
                </div>
            </div>
        </div>
    );
};
