import { useRouteNavigation } from "../../../lib/common/routes";
import { TopMenu } from "./top-menu";
import { RouteEnumType } from "../../../lib/types/routes";
import { useUserService } from "../../../services/users";

const ButtonSignup = ({ title, navigateTo }: { title: string, navigateTo: RouteEnumType }) => {
    const { navigateRouteClickHandler } = useRouteNavigation();

    return (
        <button className="btn btn-home-signUp btn-outline-light px-5 text-white"
            onClick={(e) => navigateRouteClickHandler(navigateTo, e)}
            title={title}
        >
            {title}
        </button>
    );
}

export const Intro = () => {
    const { isLoggedIn } = useUserService();
    return (
        <>
            <TopMenu />
            <div className="container-fluid bg-dark">
                <div className="container pt-4 pb-5 pt-md-1">
                    <div className="row">
                        <div className=" col-lg-7 text-start">
                            <h1 className="text-white px-3 pb-2 home-title">How <br /><span className="highlighted-title">Dental Techs</span> <br /> Find Paradise</h1>
                            <h2 className="text-white py-3 ps-3 pe-4 lh-base">Matching Skilled Tech's with Great Labs for your Ideal Lifestyle</h2>
                            <div className="mt-3 mb-5">
                                <div className="d-flex flex-column justify-content-center flex-sm-row gap-3 mt-lg-3 gap-lg-5 ">
                                    {(!isLoggedIn) && (
                                        <>
                                            <ButtonSignup
                                                title="Tech Sign Up"
                                                navigateTo="signupTech" />
                                            <ButtonSignup
                                                title="Lab Sign Up"
                                                navigateTo="signupLab" />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mb-5">
                            <div className="image-section-intro rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}