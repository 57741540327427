/**
 * replaces secessions of the same char at the end of the string
 * @param val
 * @param charToRemove
 */
const string_replaceAtEndRepeatedly = (
  val: string,
  charToRemove: string
): string => {
  return val
    .split(charToRemove)
    .reduce((prev, curr) => (curr ? [...prev, curr] : prev), [] as string[])
    .join(charToRemove);
};

export const cleanURL = (url: string) => {
  let ret = string_replaceAtEndRepeatedly(url, '/');
  ret = string_replaceAtEndRepeatedly(ret, '&');
  ret = string_replaceAtEndRepeatedly(ret, '#');
  ret = string_replaceAtEndRepeatedly(ret, '?');

  return ret;
};
