type TPagination = {
    totalCount: number,
    itemsPerPage: number,
    currentPage: number,
    refObject?: React.RefObject<any>,
    onPageChange: (page: number) => void;
}

export const Pagination = (props: TPagination) => {

    const { totalCount, itemsPerPage, currentPage, refObject } = props;

    const totalPages = Math.ceil(totalCount / itemsPerPage);
    const htmlElementRef = refObject?.current;

    const handleClick = (page: number) => {
        props.onPageChange(page - 1);
        htmlElementRef.scrollIntoView({ behavior: 'smooth', block: "nearest" });
    };


    const renderPaginationButtons = () => {
        const buttons = [];
        for (let i = 1; i <= totalPages; i++) {
            buttons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handleClick(i)}>
                        {i}
                    </button>
                </li>
            );
        }
        return buttons;
    };

    return (
        <>
            <ul className="pagination justify-content-center mt-4">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" aria-label="Previous" onClick={() => handleClick(currentPage - 1)}>
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                {renderPaginationButtons()}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" aria-label="Next" onClick={() => handleClick(currentPage + 1)}>
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </>
    );
};