import { Header } from "../common/header"
import { Footer } from "./main-page/footer"

export const AboutMark = () => {

    const members =
        [
            {
                name: "Greg Jackson",
                position: "Team Leader and Project Development Manager",
                photo: "/images/global/emptyprofile.jpg"
            },
            {
                name: "Jeff Baxter",
                position: "Interactive Media Designer",
                photo: "/images/global/emptyprofile.jpg"
            },
            // {
            //     name: "Sara Beyer",
            //     position: "eCommerce Marketing, Corporate Structure and Start Up Consulting",
            //     photo: "/images/global/emptyprofile.jpg"
            // },
        ]


    return (
        <>
            <Header title="About Us" />
            <div className="container-fluid">
                <div className="container">
                    <div className="d-block d-md-none">
                        <img src="/images/global/mark-plane2.jpeg" alt="MarkPlanePhoto" className="mark-img shadow rounded w-100" />
                    </div>
                    <div className="row bg-light rounded p-4 mx-md-4 mt-3">
                        <div className="col-md-12 col-lg-6 p-3">
                            <h3 className="">Meet Mark</h3>
                            <p className="">I grew up on a small farm in Eagle Point Oregon and was always restless and eager to learn new things. After graduating high school I moved to San Diego to live in a big city and go to college. I found a passion for small business and dropped out of college to start my first business at 21. My father was close to retirement at what was then the early 90’s and he offered to tech me the dental lab business.
                                I moved back up to Oregon and began my Dental tech career.
                                <br />
                                Well 30 years later I’m still at it and that's what leads me to my latest business WinWinGo.
                                <br />
                                <br />
                                As a lab owner one of the biggest challenges is hiring talented dental tech’s so we can grow our companies.
                                Well I’m out to solve that problem. With a creative team of talented software engineers and business experts we have built the world’s best dental lab industry focused employment site.
                                <br />
                                <br />
                                Having lived the dental lab industry for 30 years its easy for me to understand the needs and wants of both dental labs and dental technicians and thats why I am uniquely qualified to build an employment company built to benefit both.</p>
                            Let me proudly introduce my team.
                            <div className="text-end pt-4">
                                <img src="images/global/signature-Mark-Hidde_black.png" alt="Signature" className="w-25" />
                            </div>
                        </div>
                        <div className="col p-md-5 p-0 d-none d-md-block">
                            <img src="/images/global/mark-plane2.jpeg" alt="MarkPlanePhoto" className="mark-img shadow rounded w-100" />
                        </div>
                    </div>
                    <div className="row bg-light rounded p-4 mx-md-4 mt-3 justify-content-center gap-4 ">
                        <h3 className="p-3 m-3"> The Team </h3>
                        {members.map((person) => (
                            <div className="col-md-5 col-lg-3 text-center">
                                <div>
                                    <img src={person.photo} alt="TeamPhoto" className="rounded h-75 w-100" />
                                    <h5 className="mt-3">{person.name}</h5>
                                    <p className="">{person.position}</p>
                                </div>
                            </div>
                            // <div className="col-md-5 col-lg-3">
                            //     <div className="card h-100">
                            //         <img src={person.photo} alt="TeamPhoto" className="card-img-top h-75" />
                            //         <div className="card-body bg-dark text-white rounded-bottom h-auto" >
                            //             <h5 className="card-title">{person.name}</h5>
                            //             <p className="card-subtitle fw-light">{person.position}</p>
                            //         </div>
                            //     </div>
                            // </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}