import { ASSETS_ROUTES } from "../../../lib/common/constants";
import { useRouteNavigation } from "../../../lib/common/routes";
import { TGenericIdName, TMatchInfo } from "../../../lib/types/general"
import { useFileManagement } from "../../../services/file-management";


type TMatchInfoProps = {
    match: TMatchInfo
}

const MatchInfo = ({ match }: TMatchInfoProps) => {
    const { tech_id } = match;
    const { navigateRouteClickHandler } = useRouteNavigation();
    const { getImagePath } = useFileManagement();

    const imgUrl = getImagePath('logo', "fixed", tech_id);

    return <>
        <div
            title={match.name}
            onClick={(e) => navigateRouteClickHandler("dentalTechInfo", e, tech_id, true)}
            className="d-inline w-auto bg-light me-2 rounded border border-primary  lab-suggestion-badge">
            <img
                className="rounded-circle list-logo suggested-lab-logo"
                alt="laboratory logo"
                role="button"
                onError={(e: any) => {
                    e.target.src = ASSETS_ROUTES.IMGS.labAvatarUrl;
                }}
                src={imgUrl} /> <small className="ms-2">{match.name}</small>
        </div>
    </>
}

type TMatchListProps = {
    matches: TMatchInfo[],
    industryRoles: TGenericIdName[]
}
export const MatchList = ({ matches, industryRoles }: TMatchListProps) => {


    const getFirstPositionMatching = (labPosIds: string[], techSkillsIds: string[]): string => {
        const posLength = labPosIds.length;

        for (let p = 0; p < posLength; p++) {
            if (techSkillsIds.includes(labPosIds[p])) {
                return industryRoles.find((role) => role.id === labPosIds[p])?.name || "";
            }
        }

        return "";
    }

    const groupedMatches = (Object as any).groupBy(matches.filter(t => t.tech_positions_id), (match: TMatchInfo) => {
        if (!match.lab_positions_id) return "";
        if (!match.tech_positions_id) return "";
        return getFirstPositionMatching(match.lab_positions_id, match.tech_positions_id);
    })

    const mainReasons = Object.keys(groupedMatches);
    const isMoreThanOneMatch = mainReasons.length > 1 || groupedMatches[mainReasons[0]].length > 1;
    const matchLabsLabel = `We think this ${isMoreThanOneMatch ? 'technicians are' : 'technician is'} a good fit for your lab.`;

    return <>
        <h6 className="px-3 py-2">{matchLabsLabel}</h6>
        <div className="py-3 mt-3 border-1 rounded px-4 py-2 labs-suggestion-list" style={{ backgroundColor: "white" }}>

            {mainReasons.map((reason, idx) => <div
                key={`row_reason_${idx}`}
                className="row  mb-2">
                <span className="d-inline w-auto me-2 reason-text">Applying for {reason}: </span>
                {groupedMatches[reason].map((match: TMatchInfo) => <MatchInfo
                    key={`match_info_${match.lab_id}`}
                    match={match} />)}
            </div>)}
        </div>

    </>
}