import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

type Params = {
  [key: string]: any;
};

export const baseAPIURL = process.env.REACT_APP_API_ENDPOINT;

const getParamsAsURL = (params: Params): string => {
  return Object.keys(params).reduce(
    (prev, curr) => `${prev}/${curr}/${params[curr]}`,
    ""
  );
};

const getSecuredHeaders = (userTokenId: string): AxiosRequestConfig<Params> => {
  return {
    headers: {
      Authorization: "Bearer " + userTokenId,
    },
  };
};

export const apiRequestGet = <T>(
  endpoint: string,
  params?: Params
): Promise<AxiosResponse<T>> => {
  const finalUrl =
    baseAPIURL + endpoint + "/" + (params ? getParamsAsURL(params) : "");
  return axios.get<T>(finalUrl);
};

export const apiRequestSecureGet = <T>(
  endpoint: string,
  userTokenId: string,
  params?: Params
): Promise<AxiosResponse<T>> => {
  const finalUrl =
    baseAPIURL + endpoint + (params ? getParamsAsURL(params) : "");
  return axios.get<T>(finalUrl, getSecuredHeaders(userTokenId));
};

export const apiRequestSecuredPost = <T>(
  endpoint: string,
  userTokenId: string,
  params?: Params
): Promise<AxiosResponse<T>> => {
  return axios.post(
    baseAPIURL + endpoint,
    params,
    getSecuredHeaders(userTokenId)
  );
};

export const apiRequestSecuredPatch = <T>(
  endpoint: string,
  userTokenId: string,
  params?: Params
): Promise<AxiosResponse<T>> => {
  return axios.patch(
    baseAPIURL + endpoint,
    params,
    getSecuredHeaders(userTokenId)
  );
};

export const apiRequestSecuredDelete = <T>(
  endpoint: string,
  userTokenId: string,
  params?: Params
): Promise<AxiosResponse<T>> => {
  const finalUrl =
    baseAPIURL + endpoint + (params ? getParamsAsURL(params) : "");
  return axios.delete(finalUrl, getSecuredHeaders(userTokenId));
};

export const apiRequestSecuredPut = <T>(
  endpoint: string,
  userTokenId: string,
  params?: Params
): Promise<AxiosResponse<T>> => {
  return axios.put(
    baseAPIURL + endpoint,
    params,
    getSecuredHeaders(userTokenId)
  );
};

export const apiRequestPost = <T>(
  endpoint: string,
  params?: Params
): Promise<AxiosResponse<T>> => {
  return axios.post(baseAPIURL + endpoint, params);
};

export const getCacheKillQueryString = () => {
  return `cachekill=${Math.ceil(Math.random() * 1000000)}`;
};
