import { useAuthContext } from "../../../contexts/auth-context";
import { ASSETS_ROUTES } from "../../../lib/common/constants";
import { useRouteNavigation } from "../../../lib/common/routes";
import { RouteEnumType } from "../../../lib/types/routes";
import { DashboardLink, MyAccountLink, MyHomeLink } from "../../common/header";
import { Notifications } from "../../common/notifications";
import { SignInAndOut } from "../../common/user-signin-modal";
export const TopMenu = () => {
  const { userInfo, isLoggedIn } = useAuthContext();
  const { navigateRouteClickHandler, getRoute } = useRouteNavigation();
  const homeRoute: RouteEnumType = isLoggedIn ? "homeRegistered" : "home";
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
        <div className="container-fluid text-center me-4">
          <a href={getRoute(homeRoute)} onClick={(e) => navigateRouteClickHandler(homeRoute, e)}>
            <img src={ASSETS_ROUTES.IMGS.logo} className="logo" alt="WinWinGo" title="WinWinGo" />
          </a>
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-block d-lg-none">
              {isLoggedIn && <Notifications />}{" "}
            </div>

            <button className="navbar-toggler border-0 rounded-3" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon fs-1"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-4">
              <MyHomeLink />
              {userInfo?.type && isLoggedIn ? (
                <>
                  <DashboardLink />
                  <MyAccountLink />
                </>
              ) : (
                <>
                  <a className="nav-link nav-row rounded-3 ms-2" href={getRoute("story")} onClick={(e) => navigateRouteClickHandler("story", e)}>Story</a>
                  <a className="nav-link nav-row rounded-3 ms-2" href={getRoute("aboutMark")}>About</a>
                </>
              )}
              <div className="d-none d-lg-block">
                {isLoggedIn && <Notifications />}{" "}
              </div>
              <SignInAndOut />

            </div>
          </div>
        </div>
      </nav>
    </>
  );
};