
export const BlockedUserNotice = () => {

    return (
        <>
            <div className="alert alert-danger text-justify mx-3" role="alert">
                <i className="fa-solid fa-triangle-exclamation fa-xl me-3"></i>

                <strong>Notice:  Your Account Has Been Blocked</strong>
                <br /><br />
                <p>
                    We regret to inform you that after a thorough review of your profile, we
                    have made the decision to block your account. As a result, you will no
                    longer be able to access Win Win Go services. Our review process is
                    conducted to ensure compliance with our community guidelines and terms of
                    service.
                </p>
                <p>
                    If you believe this decision was made in error or would like to appeal
                    the block, please contact our support team at <strong>admin@wwg.com</strong>. We will investigate your case further
                    and provide you with any necessary assistance.
                </p>
                <p>We appreciate your understanding and cooperation in this matter.</p>
            </div>
        </>)
}