import { useRouteNavigation } from "../../../lib/common/routes";
import { Icon } from "./common/icon";
import { spinner } from "./common/spinner";
import { useMainBusinessLogic } from "./main-business-logic"

export const AdminMainSection = () => {

    const { navigateToRoute } = useRouteNavigation();
    const { reportStats, userStats, workingState } = useMainBusinessLogic();

    return <>
        <div className="row mb-3">
            <div className="col-md-6 col-lg-4">
                <div className="admin-section p-3 mb-2">
                    <Icon
                        text={<i className="fa-solid fa-flask-vial" />}
                        color="info" />
                    <span className="ms-2 text-secondary"><strong>Laboratories</strong></span>
                    <div className="row text-center">
                        <div className="col-md-6">
                            <h3>{workingState === 'working' ? spinner : userStats.labs.enabled}</h3>
                            <span className="text-success small"><strong>Active</strong></span>
                            <hr className="d-md-none" />
                        </div>
                        <div className="col-md-6">
                            <h3>{workingState === 'working' ? spinner : userStats.labs.disabled}</h3>
                            <span className="text-danger small"><strong>Blocked</strong></span>
                        </div>
                    </div>

                </div>

            </div>
            <div className="col-md-6 col-lg-4">

                <div className="admin-section mb-2 p-3">
                    <Icon
                        text={<i className="fa-solid fa-tooth" />}
                        color="warning" />
                    <span className="ms-2 text-secondary"><strong>Technicians</strong></span>
                    <div className="row text-center">
                        <div className="col-md-6">
                            <h3>{workingState === 'working' ? spinner : userStats.techs.enabled}</h3>
                            <span className="text-success small"><strong>Active</strong></span>
                            <hr className="d-md-none" />
                        </div>
                        <div className="col-md-6">
                            <h3>{workingState === 'working' ? spinner : userStats.techs.disabled}</h3>
                            <span className="text-danger small"><strong>Blocked</strong></span>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col">
                <div className="admin-section mb-2 p-3">
                    <Icon
                        text={<i className="fa-solid fa-flag" />}
                        color="primary" />
                    <button
                        className="btn btn-link"
                        onClick={() => navigateToRoute("adminReportList")}>
                        <span className="ms-2 text-secondary"><strong>Reported Users</strong></span>
                    </button>
                    <div className="row text-center">
                        <div className="col-md-4">
                            <h3>{workingState === 'working' ? spinner : reportStats.unhandled}</h3>
                            <span className="text-danger small"><strong>Unhandled</strong></span>
                            <hr className="d-md-none" />
                        </div>
                        <div className="col-md-4">
                            <h3>{workingState === 'working' ? spinner : reportStats.underReview}</h3>
                            <span className="text-success small"><strong>Under review</strong></span>
                            <hr className="d-md-none" />
                        </div>
                        <div className="col-md-4">
                            <h3>{workingState === 'working' ? spinner : reportStats.appealed}</h3>
                            <span className="text-info small"><strong>Appeals</strong></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="admin-section p-3">
                    <div className="mt-4 mb-4">&nbsp;</div>
                    <h2 className="mt-4">This is the very first <br />WinWinGo's Admin</h2>
                    <div className="mt-4 mb-4">&nbsp;</div>
                    <hr></hr>
                    <div className="mt-4 mb-4">&nbsp;</div>
                    <p className="lead">In this section you'll find a lot of useful things to break your application!</p>
                    <div className="mt-4 mb-4">&nbsp;</div>

                </div>

            </div>
        </div>
    </>
}