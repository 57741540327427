import { MouseEvent, useEffect, useRef, useState } from "react"
import { useServiceContext } from "../../contexts/service";
import { TChatMessage, TChatResponse } from "../../lib/types/chats";
import { useAuthContext } from "../../contexts/auth-context";

type TMessageProps = {
    data: TChatMessage
    currentUserId: string
}
export const Message = ({ data, currentUserId }: TMessageProps) => {

    const messageClassName = data.state === "unread" ? "message-unread" : "message-read";
    const itsMe = data.from_user_id === currentUserId;
    const contentAlign = "wwg-chat-message " + (itsMe ? "row justify-content-end" : "row justify-content-start");
    const messageClass = "p-2 mb-1 mx-4 rounded shadow " + (itsMe ? "ps-3 chatItsMe" : "chatNotMe px-3");
    return <div className={contentAlign}>
        <div className={messageClass} >
            <div className="row">
                <div className="col">
                    {data.content}
                </div>
                {itsMe &&
                    <div className="col-1 d-flex align-items-end pb-2 me-3 px-0">
                        <i className={`fa-solid fa-check-double ${messageClassName}`}></i>
                    </div>
                }
            </div>

        </div>
    </div>
}

type TChatProps = {
    withUserId: string,
    withUserName: string
}
export const Chat = ({ withUserId }: TChatProps) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const divConversationRef = useRef<HTMLDivElement>(null);

    const {
        chats: { sendMessage, startConversation, getMessages },
        notifications: { setNewConversationMessageHandler, setReadNotificationMessageHandler }
    } = useServiceContext();
    const { userInfo } = useAuthContext();
    const [allowChat, setAllowChat] = useState<boolean>(true)
    const [chatInfo, setChatInfo] = useState<TChatResponse>({
        data: {
            messages: [], conversationId: ""
        }
    })

    const _sendMessage = async () => {
        if (!inputRef) return;
        if (!inputRef.current?.value) return;
        if (!inputRef.current.value.trim()) return;
        const message = inputRef.current.value;
        inputRef.current.value = "";
        await sendMessage(message, chatInfo.data.conversationId);
        //hack to force the update of messages
        setChatInfo(prev => ({ ...prev }));
    }

    const sendHandler = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        _sendMessage();
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if (!divConversationRef.current) {
                scrollToBottom();
            } else {
                divConversationRef.current.scrollTop = divConversationRef.current.scrollHeight;
            }
        }, 100);
    }



    useEffect(() => {
        clearTimeout((window as any).wwgchatsAutomaticUpdateMessage);
        startConversation(withUserId).then(data => {
            setChatInfo(data);
            scrollToBottom();
        }).catch(err => { setAllowChat(false) });
        return () => {
            clearTimeout((window as any).wwgchatsAutomaticUpdateMessage);
        }
        //eslint-disable-next-line
    }, [withUserId])

    useEffect(() => {
        if (!chatInfo) return;
        if (!chatInfo.data) return;


        const updateMessages = async (conversationId: string, lastMessageDateNoTZ: string) => {

            const data = await getMessages(conversationId, lastMessageDateNoTZ);
            if (data.data.messages.length > 0) {
                //-//clearTimeout((window as any).wwgchatsAutomaticUpdateMessage);

                setChatInfo(prev => {
                    const newData = { ...prev };
                    newData.data.messages = [...newData.data.messages, ...data.data.messages];
                    return newData;
                });
                scrollToBottom();
            }
        }

        let dateFilter = "";
        if (chatInfo.data.messages && chatInfo.data.messages.length > 0) {
            dateFilter = chatInfo.data.messages[chatInfo.data.messages.length - 1].sent_date;
        }
        setNewConversationMessageHandler(() => {
            updateMessages(chatInfo.data.conversationId, dateFilter);
        })
        setReadNotificationMessageHandler(p => {
            if (chatInfo.data.conversationId !== p.conversationId) return;
            const indexStart = chatInfo.data.messages.findIndex(m => m.sent_date === p.fromDate);
            const indexEnd = chatInfo.data.messages.findIndex(m => m.sent_date === p.toDate);
            for (let i = indexStart; i <= indexEnd; i++) {
                const chatState = chatInfo.data.messages[i];
                if (chatState && chatState.state) chatState.state = "read";
                setChatInfo({ ...chatInfo });
            }
        })
        return () => {
            setNewConversationMessageHandler(undefined);
            setReadNotificationMessageHandler(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatInfo])

    // const contentStyle: CSSProperties = {
    //     backgroundImage: "url(/images/global/bgchat.jpeg)",
    //     backgroundSize: "cover",
    //     maxHeight: "450px",
    //     overflowX: "hidden",
    //     overflowY: "auto"
    // }

    return <>
        <div ref={divConversationRef} className={`m-0 py-2 chatContentStyle ${allowChat ? '' : ' opacity-50'}`}>
            {
                chatInfo.data.messages.map(m => (<Message
                    key={m.sent_date}
                    currentUserId={userInfo?.id as string}
                    data={m} />))
            }
        </div>
        {allowChat && <div className="p-2 d-flex justify-content-between bg-white">
            <input
                onKeyUp={(e) => {
                    if (e.key.toLowerCase() === "enter") {
                        _sendMessage();
                    }
                }}
                ref={inputRef}
                type="text" placeholder="write your message"
                className="form-control"
            />
            <button className="btn btn-primary ms-2 px-4" onClick={sendHandler} ><i className="fa-regular fa-paper-plane"></i></button>
        </div >}
        {!allowChat && <div className="bg-light">
            <div className="p-2 text-black-50"> <small>
                User is not in your contacts.
            </small>
            </div>
        </div >}
    </>
}


export const EmergentChat = ({ withUserId, withUserName }: TChatProps) => {

    const { chats: { showChat, setShowChat, endConversation } } = useServiceContext();


    // const chatstyle: CSSProperties = {
    //     position: "fixed",
    //     right: "2rem",
    //     bottom: "0px",
    //     width: "290px",
    //     borderColor: " lightgray",
    //     zIndex: 1000
    // }

    const oncClickCloseChatHandler = () => {
        endConversation(withUserId)
        setShowChat(!showChat);
    }

    return <div className="container p-0 shadow-lg chatStyle">
        <div className="d-flex justify-content-between bg-dark rounded-top-3 text-white">
            <h6 className="p-2 m-0" >
                Chat with <strong>{withUserName}</strong> </h6>
            <i className="fa-close fa-solid p-2 me-1 " onClick={oncClickCloseChatHandler} role="button"></i>
        </div>
        <div style={{
            display: showChat ? "block" : "none"
        }}>
            <Chat withUserId={withUserId} withUserName={withUserName} />

        </div>

    </div>
}