import { popover } from "../../common/popover";

export type TRefTechDashboard = {
    refs: {
        searchBtnRef: React.RefObject<any>,
        editProfileRef: React.RefObject<any>,
        suggestionsRef: React.RefObject<any>,
        lablistRef: React.RefObject<any>,
        blackListRef: React.RefObject<any>,
    },
}


export const wizardTechDashboard = ({ refs }: TRefTechDashboard) => {
    const { searchBtnRef, editProfileRef, suggestionsRef, lablistRef, blackListRef } = refs;

    const { Component: BlackListPopover, showHandler: showBlackListHandler } = popover({
        message: "The Block List ensures that specified labs cannot view your profile. Your profile will not appear in their search results nor recommendations section, and if they attempt to access it, they will see a 'Profile Does Not Exist' message.",
        title: "My Dashboard: 5/5",
        refObject: blackListRef,
    });
    const { Component: LabListPopover, showHandler: showLabListHandler } = popover({
        message: "Check out this section where you'll find a list of labs you're connected with. Just a heads up, both you and the labs need to accept the invitation requests to pop up in this list.",
        title: "My Dashboard: 4/5",
        refObject: lablistRef,
        nextPopup: showBlackListHandler,
    });
    const { Component: SuggestionsPopover, showHandler: showSuggestionsHandler } = popover({
        message: "Welcome to the WinWinGo AI-powered recommendation section! Here, we present a curated list of labs that we believe are perfect for you. These suggestions are tailored based on your hobbies and interests, and are aligned with the lab's description and location.",
        title: "My Dashboard: 3/5",
        refObject: suggestionsRef,
        nextPopup: showLabListHandler
    });
    const { Component: EditProfilePopover, showHandler: showEditProfileHandler } = popover({
        message: "Click here to go to your profile page.",
        title: "My Dashboard: 2/5",
        refObject: editProfileRef,
        nextPopup: showSuggestionsHandler
    });
    const { Component: SearchLabsPopover, showHandler: showSearchHandler } = popover({
        message: "Click here to see a list of all available laboratories. You will be able to apply filters and view basic information about each lab.",
        title: "My Dashboard: 1/5",
        refObject: searchBtnRef,
        nextPopup: showEditProfileHandler
    });

    return {
        showPopover: showSearchHandler,
        wizardComponent: (
            <>
                {SearchLabsPopover}
                {EditProfilePopover}
                {SuggestionsPopover}
                {LabListPopover}
                {BlackListPopover}
            </>)
    }

};
