import { useAuthContext } from '../contexts/auth-context';
import {
  apiRequestGet,
  apiRequestSecuredDelete,
  apiRequestSecuredPatch,
  apiRequestSecuredPost,
} from '../lib/common/api';
import { TGenericIdName } from '../lib/types/general';

let industryRolesCache: TGenericIdName[] = [];

export const getIndustryRoles = async (): Promise<TGenericIdName[]> => {
  if (industryRolesCache.length) return industryRolesCache;

  const industryRoles = await apiRequestGet<{ results: TGenericIdName[] }>(
    'industryroles'
  );

  industryRolesCache = industryRoles.data.results;

  return industryRoles.data.results;
};

export const useIndustryRoles = () => {
  const { getUserTokenId } = useAuthContext();

  const getPositionList = async () => {
    const industryRoles = await apiRequestGet<{ results: TGenericIdName[] }>(
      'industryroles'
    );
    return industryRoles;
  };

  const addIndustryRole = async (newPosition: string) => {
    const userTokenId = await getUserTokenId();
    return apiRequestSecuredPost('industryroles/add', userTokenId, {
      position: newPosition,
    });
  };

  const deleteIndustryRole = async (posId: string) => {
    const userTokenId = await getUserTokenId();
    const url = `industryroles/delete/${posId}`;

    return apiRequestSecuredDelete(url, userTokenId);
  };

  const updateIndustryRole = async (posId: string, newName: string) => {
    const userTokenId = await getUserTokenId();
    const url = `industryroles/update/${posId}`;
    return apiRequestSecuredPatch(url, userTokenId, { name: newName });
  };

  return {
    getPositionList,
    addIndustryRole,
    deleteIndustryRole,
    updateIndustryRole,
  };
};
