import { useEffect, useRef, useState } from "react";
import { NotificationProvider } from "../../../contexts/notification-context";
import { useFileManagement } from "../../../services/file-management";
import { Header } from "../../common/header";
import { Footer } from "../main-page/footer";
import { InviteButton } from "../../common/invite-button";
import { getIndustryRoles } from "../../../services/generalInfo";
import { TGenericIdName } from "../../../lib/types/general";
import { useRouteNavigation } from "../../../lib/common/routes";
import { TApiLab } from "../../../lib/types/labs";
import { useLabs } from "../../../services/laboratories";
import { DentalLabSearchFilterPanel, TLabsFilterSettings } from "../../common/dental-lab-search-filter-panel";
import { getRegions } from "../../../services/regions";
import { getCacheKillQueryString } from "../../../lib/common/api";
import { ASSETS_ROUTES, LAB_SEARCH_PAGINATION_SIZE } from "../../../lib/common/constants";
import { Pagination } from "../../common/pagination";
import { Spinner } from "../../common/spinner";
import { BackButton } from "../../common/backbutton";
import { phoneFormat } from "../../../lib/common/validation";

type TDentalLabInfoProps = {
    userInfo: TApiLab,
    industryRoles: TGenericIdName[],
    regions: TGenericIdName[]
}
export const DentalLabInfo = (props: TDentalLabInfoProps) => {

    const { userInfo, industryRoles, regions } = props;
    const { getImagePath } = useFileManagement();
    const { navigateRouteClickHandler } = useRouteNavigation();

    const imgLogo = userInfo.images?.logo ? getImagePath(userInfo.images.logo, "fixed", userInfo.id) + '?' + getCacheKillQueryString() : ASSETS_ROUTES.IMGS.labAvatarUrl;
    //const imgBackground = userInfo.images?.background && getImagePath(userInfo.images?.background, userInfo.id);
    const openPositions = userInfo.other?.positions?.map(posId => industryRoles.find(rol => rol.id === posId)?.name);
    const currRegion = userInfo.other.desiredRegion ? regions.find(region => region.id === userInfo.other.desiredRegion)?.name : "";

    const phoneFormatHandler = (phoneNumberString: string) => {
        const match = phoneNumberString.match(phoneFormat);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return "Phone number not verified";
    }

    const OpenPositionListExpandable = () => {

        const [expanded, setExpanded] = useState<boolean>(false);
        const posAmount = openPositions.length;
        const posCollapsedLength = 4;
        const posRemaining = posAmount - posCollapsedLength;

        const resumedPossitions = openPositions.slice(0, 4);


        return (<>
            {expanded &&
                <>
                    {
                        openPositions.map(pos => (<span className="badge text-bg-success w-auto m-1 px-2">{pos}</span>))

                    }
                    <button
                        onClick={() => setExpanded(false)}
                        className="btn btn-link">show less</button>
                </>
            }
            {!expanded && <>
                {
                    resumedPossitions.map(p => (<span className="badge text-bg-success w-auto m-1 px-2">{p}</span>))
                }{
                    posRemaining > 0 && <button
                        className="btn btn-link"
                        onClick={(e) => setExpanded(true)}
                    >... and {posRemaining} more</button>
                }
            </>
            }
        </>
        )
    }

    const tooltipText = `${userInfo.name} is interested in connecting with you! Visit their profile to accept the connection.`
    return (
        <div className="col-lg-3 col-md-5 list-group list-group-flush rounded shadow  p-3 search-card card border-0">
            <div className="list-group-item list-group-item-action card-body border-0 rounded p-2">
                <div className="row align-items-start text-center text-md-start"
                    onClick={(e) => navigateRouteClickHandler("dentalLabInfo", e, userInfo.id, true)} role="button">
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                        {!!imgLogo &&
                            <div className="position-relative">
                                <img
                                    className="card-img-top rounded-circle shadow card-tech-top__lab_logo"
                                    src={imgLogo}
                                    alt="Lab Logo" />
                                {userInfo.inmycontactlist &&
                                    <div className="contact-lab" data-tooltip="You're in contact with this laboratory">
                                        <i className="fa-solid fa-user-check fa-xs "></i>
                                    </div>}
                                {!userInfo.inmycontactlist &&
                                    <div>
                                        {!userInfo.invitationsenttome && userInfo.invitationsentstate !== 'accepted' &&
                                            <div className="no-contact-lab" data-tooltip="Not in contact">
                                                <i className="fa-solid fa-user fa-xs "></i>
                                            </div>
                                        }
                                        {userInfo.invitationsenttome && userInfo.invitationsentstate !== 'unread' &&
                                            <div className="invitation-requested-lab" data-tooltip={tooltipText}>
                                                <i className="fa-solid fa-user-clock fa-xs "></i>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="col-md-8 ">

                        <h6 className="fw-bold mt-3 mt-md-0">
                            {userInfo.name}<br />
                        </h6>
                        {(!!currRegion) && <p className="mb-1" style={{ fontSize: "small" }}> <small>
                            <i className="fa-solid fa-earth-americas text-primary"> </i> {currRegion}
                        </small>
                        </p>}
                        {(!!userInfo.other.location) && <p className="mb-1 lh-sm" style={{ fontSize: "small" }}> <small>
                            <i className="fa-solid fa-location-dot text-primary"> </i> {userInfo.other.location}
                        </small>
                        </p>}
                        {(!!userInfo.other.phone) && <p className="mb-1" style={{ fontSize: "small" }}> <small>
                            <i className="fa-solid fa-phone text-primary"> </i>
                            {phoneFormatHandler(userInfo.other.phone)}
                        </small>
                        </p>}
                    </div>
                </div>

                <div className="row py-4 px-3">
                    {
                        openPositions && openPositions.length > 0 &&
                        <>
                            <p className="fw-bold mb-0"><small>Available Position(s)</small></p>
                            <OpenPositionListExpandable />
                        </>
                    }
                </div>

            </div>
            <div className="card-footer border-0 bg-white">

                {!userInfo.inmycontactlist && !userInfo.invitationsenttome &&
                    userInfo.invitationsentstate !== 'accepted' &&
                    <InviteButton
                        userToInviteId={userInfo.id}
                        userToInviteInvitationState={userInfo.invitationsentstate}
                    />
                }
            </div>
        </div>
    )
}

export const DentalLabSearch = () => {

    const { getLabsSearch, state } = useLabs();
    const [labs, setLabs] = useState<TApiLab[]>([]);
    const [industryRoles, setIndustryRoles] = useState<TGenericIdName[]>([]);
    const [regions, setRegions] = useState<TGenericIdName[]>([]);
    const [totalCount, setTotalCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1);

    const refFilter = useRef<HTMLDivElement>(null);
    const regionFilters = useRef<string[]>();
    const industryRolesFilters = useRef<string[]>();
    const nameFilter = useRef<string[]>();

    useEffect(() => {

        Promise.all([getRegions(), getIndustryRoles()]).then(([regions, roles]) => {
            setIndustryRoles(roles);
            setRegions(regions);
        })
        // eslint-disable-next-line
    }, []);

    const refreshList = async (offset: number) => {
        const labsResult = await getLabsSearch(
            {
                filterPositions: industryRolesFilters.current,
                filterRegions: regionFilters.current,
                filterName: nameFilter.current,
            },
            offset
        );

        setLabs(labsResult.labs);
        setTotalCount(labsResult.totalCount);
        setPageNumber(offset + 1);
    };


    const resetCallback = () => {
        regionFilters.current = undefined;
        industryRolesFilters.current = undefined;
        nameFilter.current = undefined;
        refreshList(0);
    }

    const filterCallback = async (filterSettings: TLabsFilterSettings) => {
        const { regions, industryRoles, names } = filterSettings;

        regionFilters.current = regions ? regions.map(o => o.data.id) : [];
        industryRolesFilters.current = industryRoles ? industryRoles.map(o => o.data.id) : [];
        nameFilter.current = names ? names.map(o => o.data.name) : [];

        await refreshList(0);
    }

    return (
        <>
            <NotificationProvider>
                <Header title="Dental Labs List" />
                <div className="container-fluid" ref={refFilter}>
                    <div className="m-3">
                        <BackButton btnText=" Back to Dashboard" />
                    </div>
                    <DentalLabSearchFilterPanel
                        searchJustFromContactList={false}
                        filterCallback={filterCallback}
                        resetCallback={resetCallback}
                    />
                </div>
                <div className="container-fluid">
                    {
                        state === 'working' && (<>
                            <Spinner />
                        </>)
                    }
                    {state === 'idle' && (
                        <div className="container">
                            {labs.length > 0 ? (
                                <div className="row p-4 justify-content-center gap-4">
                                    {labs.map((userInfo) => (

                                        <DentalLabInfo
                                            key={userInfo.id}
                                            industryRoles={industryRoles}
                                            regions={regions}
                                            userInfo={userInfo} />
                                    ))}
                                    <Pagination
                                        currentPage={pageNumber}
                                        totalCount={totalCount}
                                        itemsPerPage={LAB_SEARCH_PAGINATION_SIZE}
                                        refObject={refFilter}
                                        onPageChange={(offset) => {
                                            refreshList(offset);
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className="text-center text-warning mt-3">No matching labs found.</p>
                            )}
                        </div>
                    )}
                </div>
                <Footer />
            </NotificationProvider>
        </>
    )
}