import { MouseEvent } from "react"
import { Header } from "../common/header"
import { Footer } from "./main-page/footer"
import { useSubscriptionService } from "../../services/subscriptions"

type TSubscriptionCard = {
    redirectionLink: string,
    title: string,
    price: string,
    subtitle: string,
    color: string,
    months: "12" | "6" | "1"
}


const SubscriptionCard = ({ title,
    price, subtitle, color,
    redirectionLink, months }: TSubscriptionCard) => {

    const { generateSubscriptionSession } = useSubscriptionService();

    const clickHandler = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = await generateSubscriptionSession(months);
        //        window.location.href = redirectionLink;
    }

    return (

        <div data-testid="subscription_card" className="card h-100 shadow-lg" style={{ backgroundColor: color }} >
            <div className="card-title text-center pt-3">
                <h2 className="text-white">{title}</h2>
            </div>
            <div className="card-body bg-light">
                <p>
                    <strong>
                        {subtitle}
                    </strong>
                </p>
                <p className="card-text ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
            </div>
            <div className="p-3 bg-light rounded-bottom">
                <p className="card-text text-center h5 fw-bold" style={{ color: color }}>{price}</p>
                <button className="btn w-100 fw-bold text-white"
                    data-testid="subscription-button"
                    onClick={clickHandler} style={{ backgroundColor: color }}>
                    Subscribe
                </button>
            </div>
        </div>
    )
}

export const SubscriptionSelection = () => {


    return (
        <>
            <Header title="Select your subscription" />
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <SubscriptionCard
                                months="1"
                                color="#e16539"
                                price="16 USD/month"
                                subtitle="One month subscription"
                                title="Monthly"
                                redirectionLink={process.env.REACT_APP_SUBSCRIPTION_1 as string}
                            />
                        </div>
                        <div className="col-md-4 mb-3">

                            <SubscriptionCard
                                months="6"
                                color="#f4b355"
                                price="60 USD"
                                subtitle="Six month subscription"
                                title="Half Year"
                                redirectionLink={process.env.REACT_APP_SUBSCRIPTION_6 as string}

                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <SubscriptionCard
                                months="12"
                                color="#5fb57c"
                                price="100 USD"
                                subtitle="One year subscription"
                                title="Yearly"
                                redirectionLink={process.env.REACT_APP_SUBSCRIPTION_12 as string}

                            />

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}