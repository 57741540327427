import { apiRequestGet } from "../lib/common/api";
import { TGenericApiResponse } from "../lib/types/general";
import { TRegion } from "../lib/types/regions";

let regionsCache: TRegion[] = [];

export const getRegions = async (): Promise<TRegion[]> => {
  if (regionsCache.length) return regionsCache;

  const regions = await apiRequestGet<{ results: TRegion[] }>("georegions");

  regionsCache = regions.data.results;

  return regions.data.results;
};

let regionsWithHiringLabsCache: TRegion[] = [];
export const getRegionsWhereLabsAreHiring = async (): Promise<
  TGenericApiResponse<TRegion[]>
> => {
  if (regionsWithHiringLabsCache.length)
    return {
      data: regionsWithHiringLabsCache,
    };

  const regions = await apiRequestGet<TGenericApiResponse<TRegion[]>>(
    "georegions/hiringlabs"
  );

  regionsWithHiringLabsCache = regions.data.data;

  return regions.data;
};
