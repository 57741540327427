import { Field } from "formik"
import { TGenericIdName } from "../../lib/types/general"

type TPositionsCheckListProps = {
    positions: TGenericIdName[],
}
type TPositionsCheckListNoFormikProps = {
    positions: TGenericIdName[],
    checkedList: {
        [key: string]: boolean
    },
    refs: {
        current: {
            [keys: string]: boolean
        }
    },
    wideColumns?: boolean
}

/**
 * To be found in sign up forms
 * @param props 
 * @returns 
 */
export const PositionsCheckList = (props: TPositionsCheckListProps) => {

    const { positions } = props;

    return (
        <>
            {
                positions.map((pos) => {


                    return (<>
                        <div className="col-md-4">
                            <div className="form-check">
                                <Field name={"positions"} id={pos.id} type="checkbox"
                                    data-testid="checkbox-value-formik"
                                    className="form-check-input"
                                    value={pos.id}
                                    key={pos.id}
                                />

                                <label className="form-control-label ms-2" htmlFor={pos.id}>
                                    {pos.name}</label>
                            </div>
                        </div>
                    </>)
                })
            }
        </>
    )
}


/**
 * Used in dental-lab-info. 
 * As a lab in My Account you can edit the positions you are looking for.
 * @param props 
 * @returns 
 */
export const PositionsCheckListNoFormik = (props: TPositionsCheckListNoFormikProps) => {


    const { positions, checkedList, refs, wideColumns = false } = props;

    return (
        <>
            {
                positions.map((pos) => {
                    return (
                        <div className={" " + (wideColumns ? ' col-md-6' : 'col-md-6')}>
                            <div className="input-group p-0 m-1 ">
                                <div className="">
                                    <input type="checkbox"
                                        defaultChecked={checkedList[pos.id]}
                                        data-testid="checkbox-value-no-formik"
                                        className="form-check-input"
                                        key={pos.id}
                                        id={pos.id}
                                        onChange={(e) => {
                                            refs.current[pos.id] = e.target.checked
                                        }}
                                    />

                                    <label className="form-label p-0 ms-2" htmlFor={pos.id}>
                                        {pos.name}</label>
                                </div>
                            </div>
                        </div>)
                })
            }
        </>
    )
}