import { Header } from "../common/header"
import { Footer } from "./main-page/footer"

export const ContactUs = () => {

    return (
        <>
            <Header title="Contact Us" />
            <div className="container-fluid">
                <div className="container">

                    <div className="row contact-bg-svg pt-5 pb-0 pb-md-5">
                        <div className="col-12 col-md-8 d-inline-flex align-items-center flex-column my-5" >
                            <div className="text-start">
                                <p>Feel free to reach out to us anytime!</p>
                                <br />
                                <div className="ps-3">
                                    <p className="m-0"><i className="fa-solid fa-envelope me-2"></i>
                                        <strong>Email:</strong></p>
                                    <p className="">admin@wwg.com</p>
                                    <p className="m-0"><i className="fa-solid fa-phone me-2"></i>
                                        <strong>Phone:</strong></p>
                                    <p className="">+1234567890</p>
                                    <p className="m-0"><i className="fa-solid fa-location-dot me-2"></i>
                                        <strong>Address:</strong></p>
                                    <p className="">1150 Crater Lake Ave. Suite H Medford, OR. 97504</p>
                                </div>
                                <br />
                                <p>We're here to help and would love to hear from you.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 contact-bg-img d-flex align-items-center justify-content-center"
                            style={{ backgroundImage: "url('images/global/home-block-4C.jpg')" }}>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};