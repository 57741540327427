import { MouseEvent, useEffect, useRef, useState } from "react";
import { useUserService } from "../../services/users";
import { useLocation } from "react-router-dom";

export const UserSignInModal = () => {

    const userRef = useRef<any>();
    const pwdRef = useRef<any>();
    const errorRef = useRef<string>("");
    const [isSigningIn, setIsSigningIn] = useState(false);
    const { singInUser, logOutUser, resetPassword } = useUserService();
    const locationInfo = useLocation();
    const [forgotPass, setForgotPass] = useState(false);
    const [wrongEmail, setWrongEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false)

    const loginHandler = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        errorRef.current = "";
        const user = userRef.current?.value;
        const pwd = pwdRef.current?.value;
        if (user && pwd) {
            setIsSigningIn(true);
            try {
                await singInUser(user, pwd); //const userInfo = await singInUser(user, pwd);
                if (pwdRef.current) pwdRef.current.value = "";
                if (userRef.current) userRef.current.value = "";
                $('#ModalLoginForm').modal('hide');

            } catch (error) {
                console.log('errorrrrrr', error)
                errorRef.current = "Invalid username or password"
                logOutUser();
            } finally {
                setIsSigningIn(false);
            }
        }
    }

    const handleForgotPassword = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        errorRef.current = "";
        const emailConfirm = userRef.current?.value;
        setWrongEmail(false)
        const response = await resetPassword(emailConfirm)
        if (response) {
            setEmailSent(true)
            setForgotPass(true)
            return
        }
        if (!response) {
            setWrongEmail(true)
            setForgotPass(true)
            return
        }
    }

    useEffect(() => {
        if (locationInfo.pathname === "/logoutduetoinactivity") {
            setTimeout(() => {

                $('#ModalLoginForm').modal('show')
            }, 1000);
        }
    }, [locationInfo.pathname])

    return (<>
        <div id="ModalLoginForm" className="modal fade text-start" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">WinWinGo Login</h5>
                    </div>
                    {!!!forgotPass &&
                        <div className="modal-body">
                            <form >
                                <input type="hidden" name="_token" />
                                <div className="mb-3">
                                    <label className="form-label fw-bold ms-2" htmlFor="userNameInput">Email</label>
                                    <input ref={userRef} type="email" className="form-control input-lg" name="email" data-testid="user-input" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fw-bold ms-2" htmlFor="passwordInput" >Password</label>
                                    <input ref={pwdRef} type="password" className="form-control input-lg" name="password" data-testid="password-input" />
                                </div>
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="remember" />
                                        <label className="form-check-label" htmlFor="remember">
                                            Remember me
                                        </label>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p><span className='badge rounded-pill text-bg-danger px-3'>{errorRef.current}</span></p>
                                    <button data-testid="login-button" disabled={isSigningIn} onClick={loginHandler} className="btn btn-primary w-100">Login</button>
                                    <button className="btn btn-link mt-1 text-primary" onClick={() => setForgotPass(true)}>Forgot Your Password?</button>
                                </div>
                            </form>
                        </div>
                    }
                    {!!forgotPass &&
                        <div className="modal-body">
                            <p> <strong> Reset Password </strong></p>
                            <form>
                                {!!wrongEmail &&
                                    <p className="text-danger text-center"><strong>User not found</strong></p>
                                }
                                {!!!emailSent && <>
                                    <input type="hidden" name="_token" />
                                    <div className="form-group">
                                        <label className="control-label">Please confirm your email address</label>
                                        <div>
                                            <input ref={userRef} type="email" className="form-control input-lg mt-2" name="email" data-testid="user-input" />
                                        </div>
                                        <div className="text-end">
                                            <button className="btn btn-primary mt-3" type="button" onClick={(e) => handleForgotPassword(e)}>Send Email</button>
                                            <button className="btn btn-danger mt-3 ms-3" type="button" onClick={() => {
                                                setForgotPass(false)
                                                setWrongEmail(false)
                                            }}>Cancel</button>
                                        </div>
                                    </div>
                                </>}

                                {!!emailSent && (
                                    <div className="form-group">
                                        <p className="text-wrap"><strong>Please check your email inbox</strong> and click on the link we sent you. <br /> If you don't see it there, it might have gone to your spam folder.</p>
                                        <div className="text-end">
                                            <button className="btn btn-primary" style={{ width: "7rem" }} type="button"
                                                onClick={() => {
                                                    $('#ModalLoginForm').modal('hide');
                                                    setEmailSent(false)
                                                    setForgotPass(false)
                                                }}
                                            >OK</button>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>)
}


export const SignIn = () => (<a href="/" onClick={(e) => {
    e.preventDefault();
    $('#ModalLoginForm').modal('show')
}}>
    <a href="#signin" className="nav-link nav-row text-center rounded-3 signin ms-2">
        Sign In
    </a>
</a>);

export const SignOut = ({
    signOutHandler
}: { signOutHandler: () => void }) => (
    <button
        className="nav-link nav-row text-center rounded-3 signin ms-2"
        onClick={(e) => {
            e.preventDefault();
            signOutHandler();
        }}>

        Sign Out

    </button>)

export const SignInAndOut = () => {
    const { isLoggedIn, logOutUser } = useUserService();

    return (
        <>
            <UserSignInModal />
            {isLoggedIn ? (<SignOut signOutHandler={logOutUser} />) : (<SignIn />)}
        </>);
}
