import { useNavigate } from "react-router-dom";
import { TBackButton } from "../../lib/types/general";

export const BackButton = ({ btnText }: TBackButton) => {
  const isButtonVisible = window.history.state?.usr?.canGoBack === true;

  const navigateTo = useNavigate();
  const goBack = () => {
    navigateTo(-1);
  };

  return (
    <div>
      {isButtonVisible && (
        <button
          type="button"
          className="btn btn-dark text-nowrap"
          onClick={goBack}
          data-testid="back-button"
        >
          <i className="fa-solid fa-arrow-left" />
          {btnText}
        </button>
      )}
    </div>
  );
};
