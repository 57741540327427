import { useEffect, useRef, useState } from "react"
import { useAuthContext } from "../../../contexts/auth-context"
import { NotificationProvider } from "../../../contexts/notification-context"
import { Header } from "../../common/header"
import { Footer } from "../main-page/footer"
import { DashboardNotifications } from "./dashboard-notifications"
import { LabList } from "./lab-list"
import { useFileManagement } from "../../../services/file-management"
import { useRouteNavigation } from "../../../lib/common/routes"
import { TRefTechDashboard, wizardTechDashboard } from "./wizard-tech-dashboard"
import { getCacheKillQueryString } from "../../../lib/common/api"
import { useUserService } from "../../../services/users"
import { BlockedUserNotice } from "../../common/block-notice"
import { ASSETS_ROUTES, LAB_SEARCH_PAGINATION_SIZE } from "../../../lib/common/constants"
import { TApiLab } from "../../../lib/types/labs"
import { useLabs } from "../../../services/laboratories"
import { useTechnicians } from "../../../services/technicians"
import { TMatchInfo } from "../../../lib/types/general"
import { MatchList } from "./matches"
import { Pagination } from "../../common/pagination"
import { Spinner } from "../../common/spinner"

export const DentalTechDashboard = () => {
    const { userInfo } = useAuthContext();
    const { getImagePath } = useFileManagement();
    const { navigateRouteClickHandler, getRoute } = useRouteNavigation();
    const { navigateToMyAccountClickHandler } = useUserService();
    const { getLabsInBlacklist } = useLabs();
    const { getMatches, state } = useTechnicians();
    const [blacklist, setBlacklist] = useState<TApiLab[]>([]);
    const [blackListTotalCount, setBlackListTotalCount] = useState(0)
    const [blackListPageNumber, setBlackListPageNumber] = useState(1);
    const [matches, setMatches] = useState<TMatchInfo[]>([]);
    const [disableWizard, setDisableWizard] = useState(true);


    // Wizard Component Logic Starts
    const wizardRefs: TRefTechDashboard = {
        refs: {
            searchBtnRef: useRef<HTMLButtonElement>(null),
            editProfileRef: useRef<HTMLButtonElement>(null),
            suggestionsRef: useRef<HTMLDivElement>(null),
            lablistRef: useRef<HTMLLinkElement>(null),
            blackListRef: useRef<HTMLDivElement>(null),
        }
    };

    const { showPopover, wizardComponent } = wizardTechDashboard(wizardRefs);
    // Wizard Component Logic Ends
    const blacklistLabs = (offset: number) => {
        getLabsInBlacklist(offset).then(results => {
            setBlacklist(results.labs)
            setBlackListTotalCount(results.totalCount)
            setBlackListPageNumber(offset + 1)
        })
    }
    useEffect(() => {
        setDisableWizard(true)
        Promise.all([blacklistLabs(0), getMatches()]).then(([blackListResults, matchesResults]) => {
            setMatches(matchesResults);
            setDisableWizard(false)
        })
        // eslint-disable-next-line
    }, [])

    const BlackList = () => {
        const { deleteLabBlacklist } = useUserService();

        const deleteBlacklistHandler = async (lab_id: string) => {
            await deleteLabBlacklist(lab_id)
            // setBlacklist(prevBlacklist => prevBlacklist.filter(lab => lab.id !== lab_id))
            blacklistLabs(0);

        }

        return (
            <>
                <ul className="list-group">
                    {blacklist.map((item) => (
                        <li
                            key={item.id}
                            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border border-light border-1 rounded-0"
                        >
                            <a
                                href={getRoute("dentalLabInfo", item.id)}
                                onClick={(e) => navigateRouteClickHandler("dentalLabInfo", e, item.id, true)} >
                                {item.name}
                            </a>
                            <button
                                data-testid="close-button-msg"
                                className="ms-2 btn p-0 "
                                onClick={() => deleteBlacklistHandler(item.id)}
                            >
                                <i className="fa-solid fa-circle-xmark"></i>
                            </button>
                        </li>
                    ))}
                    {(blacklist.length >= LAB_SEARCH_PAGINATION_SIZE || blackListPageNumber >= 2) && (
                        <Pagination
                            currentPage={blackListPageNumber}
                            totalCount={blackListTotalCount}
                            itemsPerPage={LAB_SEARCH_PAGINATION_SIZE}
                            refObject={wizardRefs.refs.blackListRef}
                            onPageChange={(offset) => {
                                blacklistLabs(offset)
                            }}
                        />
                    )}
                </ul>
            </>
        );
    };

    return (
        <>
            <NotificationProvider>
                <Header title={`${userInfo?.name}'s Dashboard`} />
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 px-0 px-lg-3">
                                <div className="mb-4 rounded dashboard-background-container" style={{ backgroundImage: `url(${getImagePath("background", "fixed", userInfo?.id) + '?' + getCacheKillQueryString()})` }} data-testid="test-tech-bg">
                                    <div className="row">
                                        <div className="col-lg-3 py-4 text-center">
                                            <img
                                                className="rounded-circle border border-2 border-light dashboard-logo"
                                                alt="hgap"
                                                src={getImagePath("logo", "fixed", userInfo?.id) + '?' + getCacheKillQueryString()}
                                                data-testid="test-tech-logo"
                                                onError={(e) => { (e.target as HTMLImageElement).src = ASSETS_ROUTES.IMGS.techAvatarUrl }}
                                            />
                                        </div>
                                        <div className="col-lg-5 text-center d-flex align-items-center justify-content-center text-white">
                                            <h4>
                                                {userInfo?.name}
                                            </h4>
                                        </div>
                                        <div className="col-lg-4 d-flex align-items-center flex-column py-4">
                                            <button
                                                ref={wizardRefs.refs.searchBtnRef}
                                                className="btn btn-primary w-75 text-white " data-test-id="btnpoper"
                                                onClick={(e) => navigateRouteClickHandler("dentalLabSearch", e, "", true)}
                                                disabled={!!!userInfo?.enabled}
                                            >Search Laboratories</button>
                                            <button
                                                ref={wizardRefs.refs.editProfileRef}
                                                className="btn btn-secondary mt-2 w-75"
                                                onClick={navigateToMyAccountClickHandler}
                                                disabled={!!!userInfo?.enabled}
                                            >Edit Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 p-4 gray-container gray-container__tips overflow-auto border border-1 border-light rounded" >
                                <h6 className="fw-bold">Helpful Tips</h6>
                                <hr />
                                <p><small>
                                    Explore how to navigate through WinWinGo! Click here to begin the walkthrough.
                                    <button
                                        className="btn btn-info btn-sm border-0 rounded py-0 mx-2 px-5 text-white"
                                        onClick={() => {
                                            showPopover()
                                        }}
                                        disabled={!!!userInfo?.enabled || disableWizard}
                                    >
                                        Start
                                    </button>
                                </small></p>
                                <hr />
                                <p><small>
                                    <strong>Resume tips</strong>, in the opening paragraph always mention what you plan to do for a potential lab with your productivity and ability’s with real numbers and types of restorations. Labs love to see this confidence and boldness.
                                </small></p>
                                <hr />
                                <p><small>
                                    Make sure to explain gaps in employment.
                                </small></p>
                                <hr />
                                <p><small>
                                    Be clear when deciding on the best way to communicate, lab owners are very busy and you don’t want important responses to be missed.
                                </small></p>
                                <hr />
                                <p><small>
                                    Consider asking for a one week work along to get a feel for a new area and new work environment.
                                </small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-4 mt-lg-0">
                    <div className="container">

                        <div className="row">
                            {!!userInfo?.enabled && (
                                <>
                                    <div className="col-lg-9 px-0 px-lg-3">
                                        <div ref={wizardRefs.refs.suggestionsRef}
                                            className="ai-container rounded border-light p-4 mb-3">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="fw-bold lh-base ai-color"> <i className="fa-solid fa-wand-magic-sparkles me-3 fa-lg" ></i>
                                                    WinWinGo AI-Powered Recommendations</h6>
                                                <div data-toggle="collapse" data-target="#AiInfo" aria-expanded="true" aria-controls="AiInfo" role="button">
                                                    <i className="fa-solid fa-circle-info ai-color"></i></div>
                                            </div>
                                            <div className="collapse p-0" id="AiInfo">
                                                <div className="lh-sm ai-color">
                                                    <p><small>Welcome to your personalized AI-powered recommendation section! Here, we'll present a curated list of labs that perfectly match your profile. These suggestions are tailored based on your hobbies, interests, personal description and skills, and are aligned with the lab's description and location.</small></p>
                                                </div>
                                            </div>
                                            {state === "working" && (
                                                <>
                                                    <Spinner />
                                                </>
                                            )}
                                            {matches !== undefined && matches.length > 0 && state === "idle" &&
                                                <MatchList matches={matches} />
                                            }
                                            {matches.length === 0 && state === "idle" && (
                                                <p className="p-2"><strong>No matches yet?</strong> Remember to fill out your profile with your hobbies, interests, skills, and a brief description of yourself to help us find the best possible matches for you.</p>
                                            )}
                                        </div>
                                        <div className="gray-container border border-1 rounded border-light p-3 min-vh-100">
                                            <h4 className="py-3 px-3" ref={wizardRefs.refs.lablistRef}>Job Prospects</h4>
                                            {/* <hr /> */}
                                            <LabList refLabList={wizardRefs.refs.lablistRef} />
                                        </div>
                                    </div>

                                    <div className="col-lg-3 px-0">
                                        <div className="d-none d-lg-block bg-light border border-1 rounded border-light p-3 min-vh-50 overflow-auto">
                                            <h6 className="py-3 px-2">Notifications</h6>
                                            <DashboardNotifications />
                                        </div>

                                        <div className="d-block mt-3 bg-light rounded p-3 ">
                                            <div className="d-flex justify-content-between" ref={wizardRefs.refs.blackListRef}>
                                                <h6 className="pt-3 px-2">Labs in my Block List</h6>
                                                <div data-toggle="collapse" data-target="#blacklistInfo" aria-expanded="true" aria-controls="blacklistInfo" role="button">
                                                    <i className="fa-solid fa-circle-info py-3 pe-3 text-info"></i></div>
                                            </div>
                                            <div className="collapse p-0" id="blacklistInfo">
                                                <div className="px-2" style={{ fontSize: "small" }}>
                                                    <p><small>The Block List ensures that specified labs cannot view your profile. Your profile will not appear in their search results nor recommendations section, and if they attempt to access it, they will see a 'Profile Does Not Exist' message.</small></p>
                                                </div>
                                            </div>
                                            <div className="pt-3">
                                                <BlackList />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {!!!userInfo?.enabled && (
                                <BlockedUserNotice />
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
                {wizardComponent}
            </NotificationProvider>
        </>
    )
}