import dayjs from "dayjs"
import { TReportState, TReportedUserRow } from "../../../../services/backoffice/backoffice-service"
import { Spinner } from "../common/spinner"
import { TEnableDisableUserHandler, TUpdateReportStateHandler, useReportMainBusinessLogic } from "./report-main-business-logic"
import { useRouteNavigation } from "../../../../lib/common/routes"
import { RouteEnumType } from "../../../../lib/types/routes"
import { EnableDisableButton } from "../common/enable-disable-button"
import { MouseEvent, useRef, useState } from "react"
import { TProcessStates } from "../../../../lib/types/general"
import { ReportsFilterPanel, TReportFilterSettings } from "./filter-reports"
import { TOptionToShow } from "../../../common/dropdown-multi-select"


type TCReportedUserListProps = {
    userList: TReportedUserRow[],
    enableDisableUserHandler: TEnableDisableUserHandler,
    updateReportStateHandler: TUpdateReportStateHandler
}

const CReportedUserList = ({ userList, enableDisableUserHandler, updateReportStateHandler }: TCReportedUserListProps) => {

    const { navigateToRoute } = useRouteNavigation();


    const Row = ({ data }: { data: TReportedUserRow }) => {


        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("idle");
        const [reportState, setReportState] = useState<TReportState>(data.state);
        const optionState: TReportState[] = ['draft', 'under_review', 'archived', 'accepted', 'rejected', 'appeal'];
        const routeToGo: RouteEnumType = data.user_type === "tech" ? "adminUserInfoTech" : "adminUserInfoLab";
        const enableDisableClickHandler = async () => {
            try {
                setCurrWorkingState("working");
                await enableDisableUserHandler(data.reported_user_id, !data.reported_enabled);
                data.reported_enabled = !data.reported_enabled;
                setCurrWorkingState("done");
            } catch (error) {
                setCurrWorkingState("error");
            }
        }

        const handleStateUpdate = async (e: MouseEvent<HTMLButtonElement>, reportedUserId: string, state: TReportState) => {
            try {
                if (state === data.state) return;
                setCurrWorkingState("working");
                await updateReportStateHandler(
                    reportedUserId,
                    state,
                );
                data.state = state;
                setReportState(state);
                setCurrWorkingState("done");
            } catch (error) {
                setCurrWorkingState("error");
            }
        };


        const getButtonColor = (reportState: TReportState) => {
            switch (reportState) {
                case 'under_review':
                    return 'btn-success';
                case 'archived':
                    return 'btn-secondary';
                case 'accepted':
                    return 'btn-primary';
                case 'rejected':
                    return 'btn-danger';
                case 'appeal':
                    return 'btn-warning';
                default:
                    return 'btn-info';
            }
        };
        return (
            <tr className="list-group-item list-group-item-action d-inline-flex" style={{ fontSize: "0.8rem" }}>
                <td className="col-2">
                    <a
                        href="#userReported"
                        onClick={() => navigateToRoute(routeToGo, data.reported_user_id, true)}
                        className="btn btn-link text-start p-0 btn-sm">
                        <small>
                            {`${data.reported_name} (${data.user_type})`}
                        </small>
                    </a>
                </td>
                <td className="col-2">
                    {dayjs(data.creation_date).format("MMMM DD, YYYY ")}
                </td>
                <td className="col-2">
                    {data.reporter_name}
                </td>
                <td className="col-2">
                    <small className="font-weight-light font-italic">
                        {data.reason}
                    </small>
                </td>
                <td className="col-2 text-center">
                    <div className="btn-group w-100">
                        <button type="button"
                            className={`btn ${getButtonColor(reportState)} dropdown-toggle px-0 text-capitalize mx-0 mx-md-3`}
                            style={{ fontSize: "x-small" }}
                            data-toggle="dropdown" aria-expanded="false">
                            {reportState.replaceAll("_", " ")}
                        </button>
                        <div className="dropdown-menu mt-1"  >
                            {optionState.map((state, index) => (
                                <button key={`state_${index}`} className=" btn btn-link dropdown-item text-capitalize"
                                    style={{ fontSize: "small" }}
                                    onClick={(e) =>
                                        handleStateUpdate(e, data.reported_user_id, state)

                                    }
                                >{state.replaceAll("_", " ")}</button>
                            ))}
                        </div>
                    </div>
                </td>
                <td className="col-1 px-md-0 ps-md-1">
                    {currWorkingState === "error" && <p className="small text-danger">
                        something went wrong, please try again
                    </p>}

                    <EnableDisableButton
                        btnDisabled={currWorkingState === "working"}
                        textExtraClass="small"
                        text={data.reported_enabled ? 'disable' : 'enable'}
                        onClickHandler={enableDisableClickHandler}
                        enabled={data.reported_enabled}
                    />
                </td>
                <td className="col-1 text-end text-md-center p-0">
                    {currWorkingState === "working" && (
                        <button className="btn btn-link text-dark" >
                            {<Spinner />}
                        </button>
                    )}
                </td>
            </tr>
        )
    }

    return (
        <>
            <div className="table-responsive rounded-1">
                <table className="table">
                    <thead className="list-group">
                        <small>
                            <tr className="list-group-item list-group-item-action list-group-item-header active d-inline-flex" >

                                <th scope="col" className="col-2 ps-3">
                                    Name
                                </th>
                                <th scope="col" className="col-2 ps-2">
                                    Date
                                </th>
                                <th scope="col" className="col-2">
                                    Reported by
                                </th>
                                <th scope="col" className="col-2 ps-1">
                                    Reason
                                </th>
                                <th scope="col" className="col-2 text-start px-4">
                                    State
                                </th>
                                <th scope="col" className="col-2 px-0">
                                    Quick Action
                                </th>
                            </tr>
                        </small>
                    </thead>
                    <tbody className="list-group rounded-0">
                        {
                            userList.map(u => <Row data={u} />)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export const AdminReportMain = () => {
    const { workingState, reportedUserList,
        enableOrDisableUser, updateReportState,
        getReportedUsers } = useReportMainBusinessLogic();
    const nameFilter = useRef<string>("");
    const isFilterSearching = useRef<boolean>(false);
    const stateFilter = useRef<TReportState[]>([]);
    const stateFilterOptions: TOptionToShow[] = [
        { data: { id: "draft", name: "Draft" }, selected: false },
        { data: { id: "under_review", name: "Under Review" }, selected: false },
        { data: { id: "archived", name: "Archived" }, selected: false },
        { data: { id: "accepted", name: "Accepted" }, selected: false },
        { data: { id: "rejected", name: "Rejected" }, selected: false },
        { data: { id: "appeal", name: "Appeal" }, selected: false },
    ];


    const applyFilters = async (nameToSearch: string) => {
        if (isFilterSearching.current) return;

        isFilterSearching.current = true;
        await getReportedUsers({
            filterStates: stateFilter.current && stateFilter.current.length > 0 ? stateFilter.current : [],
            filterUserNames: nameToSearch ? [nameToSearch] : []
        });
        isFilterSearching.current = false;
        if (nameToSearch !== nameFilter.current) applyFilters(nameFilter.current);

    }

    const filterCallback = (filterSettings: TReportFilterSettings) => {
        const { state, name } = filterSettings;
        nameFilter.current = name || "";
        stateFilter.current = state ? state.map(o => o.data.id) as TReportState[] : [];
        applyFilters(nameFilter.current);
    }

    const resetFilters = () => {
        nameFilter.current = "";
        stateFilter.current = [];
        applyFilters(nameFilter.current);
    }

    return (
        <div className="row">
            <div className="col">
                <ReportsFilterPanel
                    filterCallback={filterCallback}
                    resetCallback={resetFilters}
                    searchedName={nameFilter.current}
                    stateFilterOptions={stateFilterOptions}
                />
                <div className="admin-section ">
                    {workingState === "working" && <div className="p-4"><Spinner /> Loading...</div>}
                    {workingState !== "working" && reportedUserList.length > 0 && (
                        <CReportedUserList
                            userList={reportedUserList}
                            enableDisableUserHandler={enableOrDisableUser}
                            updateReportStateHandler={updateReportState}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
