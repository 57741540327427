import { ASSETS_ROUTES } from "../../../lib/common/constants";
import { useRouteNavigation } from "../../../lib/common/routes";
import { TMatchInfo } from "../../../lib/types/general"
import { useFileManagement } from "../../../services/file-management";


type TMatchInfoProps = {
    match: TMatchInfo
}

const MatchInfo = ({ match }: TMatchInfoProps) => {
    const { lab_id } = match;
    const { navigateRouteClickHandler } = useRouteNavigation();
    const { getImagePath } = useFileManagement();

    const imgUrl = getImagePath('logo', "fixed", lab_id);

    return <>
        <div
            title={match.name}
            onClick={(e) => navigateRouteClickHandler("dentalLabInfo", e, lab_id, true)}
            className="d-inline w-auto bg-light me-2 rounded border border-primary  lab-suggestion-badge">
            <img
                className="rounded-circle list-logo suggested-lab-logo"
                alt="laboratory logo"
                role="button"
                onError={(e: any) => {
                    e.target.src = ASSETS_ROUTES.IMGS.labAvatarUrl;
                }}
                src={imgUrl} /> <small className="ms-2">{match.name}</small>
        </div>
    </>
}

type TMatchListProps = {
    matches: TMatchInfo[]
}
export const MatchList = ({ matches }: TMatchListProps) => {

    const groupedMatches = (Object as any).groupBy(matches, (match: TMatchInfo) => {

        const keys = Object.keys(match.lab_about_the_area);
        let mainReason = keys[0];
        let maxVal = -1;
        for (let i = 0; i < keys.length; i++) {
            const val = match.lab_about_the_area[keys[i]];
            if (val > maxVal) {
                maxVal = val;
                mainReason = keys[i];
            }
        }
        return mainReason;
    })

    const mainReasons = Object.keys(groupedMatches);
    const isMoreThanOneMatch = mainReasons.length > 1 || groupedMatches[mainReasons[0]].length > 1;
    const matchLabsLabel = `We think this ${isMoreThanOneMatch ? 'labs are' : 'lab is'} a good fit for you.`;

    return <>
        <h6 className="px-3 py-2">{matchLabsLabel}</h6>
        <div className="py-3 mt-3 border-1 rounded px-4 py-2 labs-suggestion-list" style={{ backgroundColor: "white" }}>

            {mainReasons.map((reason, idx) => <div
                key={`row_reason_${idx}`}
                className="row  mb-2">
                <span className="d-inline w-auto me-2 reason-text">Because you like {reason}: </span>
                {groupedMatches[reason].map((match: TMatchInfo) => <MatchInfo
                    key={`match_info_${match.lab_id}`}
                    match={match} />)}
            </div>)}
        </div>

    </>
}