import {
  apiRequestSecureGet,
  apiRequestSecuredPost,
  apiRequestSecuredPut,
} from '../../lib/common/api';
import { TUserTypes } from '../../lib/types/users';

export type TReportStats = {
  unhandled: number;
  underReview: number;
  appealed: number;
};

export type TReportState =
  | 'draft'
  | 'under_review'
  | 'archived'
  | 'accepted'
  | 'rejected'
  | 'appeal';

export type TUsersStats = {
  labs: { disabled: number; enabled: number };

  techs: { disabled: number; enabled: number };
};

export type TReportedUserRow = {
  creation_date: string;
  reason: string;
  reported_name: string;
  reported_user_id: string;
  reporter_name: string;
  reporter_user_id: string;
  state: TReportState;
  user_type: TUserTypes;
  reported_enabled: boolean;
};

type TAPIReportedUserReponse = {
  totalRows: number;
  users: TReportedUserRow[];
};

const URLS = {
  reportStats: 'reports/stats',
  userStats: '/users/stats',
  reportedUsers: 'reports/get/',
  enableDisable: 'users/enable-disable',
  updateReportState: 'reports/update-state',
};

export const getStats = async (userTokenId: string) => {
  return apiRequestSecureGet<{ data: TReportStats }>(
    URLS.reportStats,
    userTokenId
  );
};

export const getUserStats = async (tokenId: string) => {
  return apiRequestSecureGet<{ data: TUsersStats }>(URLS.userStats, tokenId);
};

export type TReportedUsersFilters = {
  filterStates: TReportState[];
  filterUserNames: string[];
};
export const getReportedUsers = async (
  page: number,
  tokenId: string,
  filters: TReportedUsersFilters
) => {
  return apiRequestSecuredPost<{ data: TAPIReportedUserReponse }>(
    `${URLS.reportedUsers}${page}`,
    tokenId,
    filters
  );
};

export const enableOrDisableUser = async (
  userId: string,
  enabled: boolean,
  tokenId: string
) => {
  return apiRequestSecuredPost(URLS.enableDisable, tokenId, {
    userId,
    enabled,
  });
};

export const updateReportState = async (
  state: TReportState,
  reported_user_id: string,
  tokenId: string
) => {
  return apiRequestSecuredPut(URLS.updateReportState, tokenId, {
    state,
    reported_user_id,
  });
};
