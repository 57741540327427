type TEnableDisableButtonProps = {
    enabled: boolean,
    onClickHandler: React.MouseEventHandler<HTMLButtonElement>
    text?: string,
    textExtraClass?: string
    btnDisabled: boolean
}
export const EnableDisableButton = (
    { enabled, onClickHandler, text, textExtraClass, btnDisabled }: TEnableDisableButtonProps
) => {

    return <button
        disabled={btnDisabled}
        onClick={onClickHandler}
        className={`btn btn-link text-decoration-none p-0 btn-sm`}>
        <i className={`fa-solid fa-toggle-${enabled ? 'on' : 'off'} text-${enabled ? 'success' : 'danger'}`}></i>
        {!text ? '' : <span
            className={`ms-2 text-dark d-none d-md-block ${textExtraClass || ''}`}>{text}</span>}
    </button>
}