import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from "react";
import { DropdownMultiSelect, TApplyfilterCallback, TOptionToShow, } from "./dropdown-multi-select";
import { getIndustryRoles } from "../../services/generalInfo";
import { TGenericIdName } from "../../lib/types/general";
import { useRouteNavigation } from "../../lib/common/routes";
import { useLocation } from "react-router-dom";
import { useLabs } from "../../services/laboratories";
import { getRegions } from "../../services/regions";

export type TLabsFilterSettings = {
  names: TOptionToShow[];
  regions: TOptionToShow[];
  industryRoles: TOptionToShow[];
};

type TFilterPanelProps = {
  filterCallback: (filterSettings: TLabsFilterSettings) => void;
  searchJustFromContactList: boolean;
  resetCallback: () => void;
};

type TInputDropdownLabhNameSearchProps = {
  namesRef: MutableRefObject<any>;
  nameFilterCallback: TApplyfilterCallback;
  searchJustFromContactList: boolean;
};
const InputDropdownLabNameSearch = (
  props: TInputDropdownLabhNameSearchProps
) => {
  const { navigateRouteClickHandler, getRoute } = useRouteNavigation();
  const [searchResults, setSearchResults] = useState<TGenericIdName[]>([]);
  const { getLabsSearchName, getLabsFromContactList } =
    useLabs();
  const lastNameRef = useRef<string>("");
  const isSearching = useRef<boolean>(false);

  const { namesRef, nameFilterCallback, searchJustFromContactList } = props;

  const searchNameMethod = searchJustFromContactList
    ? getLabsFromContactList
    : getLabsSearchName;

  const searchResultCoverStyle: CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "997",
  };


  const searchByName = async () => {
    if (!namesRef.current.value) {
      setSearchResults([]);
      return;
    }
    if (isSearching.current) return;

    isSearching.current = true;
    const valueToSearch = namesRef.current.value.toLocaleLowerCase().trim();
    const valueToSearchArray = valueToSearch
      .split(" ")
      .filter((v: string) => v);
    const results = await searchNameMethod(
      0, { filterName: valueToSearchArray },
    );

    isSearching.current = false;
    const newValueToSearch = namesRef.current.value.toLocaleLowerCase().trim();
    if (valueToSearch !== newValueToSearch) {
      searchByName();
      return;
    }
    setSearchResults(
      results.labs.map(
        (u): TGenericIdName => ({
          id: u.id,
          name: u.name, // highlight(u.name, valueToSearchArray)
        })
      )
    );
  };

  const performSearch = (e: any) => {
    setSearchResults([]);
    e.preventDefault();
    e.stopPropagation();
    const currValue = namesRef.current.value.trim();

    if (lastNameRef.current && lastNameRef.current === currValue) return;
    if (!currValue) {
      nameFilterCallback([]);
    }

    const currValueArray = currValue.split(" ").filter((v: string) => v);

    lastNameRef.current = currValue;

    const values =
      currValueArray.map((v: string) => {
        const ret: TOptionToShow = {
          data: {
            id: "1",
            name: v,
          },
          selected: true,
        };
        return ret;
      }) || [];

    if (values.length > 0) {
      nameFilterCallback(values);
    }
  };

  return (
    <div>
      <input
        style={{ zIndex: "0" }}
        className="mb-2 py-1 px-3 text-start border border-2 border-info rounded w-100"
        ref={namesRef}
        placeholder="filter by name"
        type="text"
        onChange={searchByName}
        onBlur={(e) => {
          if (searchResults.length > 0) return;
          performSearch(e);
        }}
      />
      {searchResults.length > 0 && (
        <>
          <div className="list-group searchResultStyle rounded-3 shadow">
            {searchResults.map((v) => (
              <a
                className="list-group-item list-group-item-action py-2 px-3"
                href={getRoute("dentalLabInfo", v.id)}
                onClick={(e) =>
                  navigateRouteClickHandler("dentalLabInfo", e, v.id, true)
                }
                dangerouslySetInnerHTML={{ __html: v.name }}
              ></a>
            ))}
          </div>
          <div style={searchResultCoverStyle} onClick={performSearch}></div>
        </>
      )}
    </div>
  );
};

type TQStringDecoded = {
  name: {
    json: TOptionToShow[];
    value: string;
  };
  regions: {
    json: TOptionToShow[];
    value: string;
  };
  industryRoles: {
    json: TOptionToShow[];
    value: string;
  };
};
const decodeQString = (): TQStringDecoded | undefined => {
  const qString = window.location.search;
  if (!qString) return undefined;

  const parameters = qString.split("?")[1].split("q=");
  if (parameters.length < 2) return undefined;
  const valueToDecode = JSON.parse(atob(decodeURIComponent(parameters[1])));
  return valueToDecode;
};

export const DentalLabSearchFilterPanel = (props: TFilterPanelProps) => {
  const [regionsFilter, setRegionFilters] = useState<TOptionToShow[]>([]);
  const [industryRolesFilter, setIndustryRolesFilters] = useState<
    TOptionToShow[]
  >([]);

  const { filterCallback, searchJustFromContactList } = props;
  const namesRef = useRef<any>("");
  const byDefaultFilterSettings: TQStringDecoded = {
    name: {
      json: [],
      value: "",
    },
    regions: {
      json: [],
      value: "",
    },
    industryRoles: {
      json: [],
      value: "",
    },
  };

  const invoqueFilterCallback = (qstring: TQStringDecoded) => {
    filterCallback({
      regions: qstring.regions?.json,
      names: qstring.name?.json,
      industryRoles: qstring.industryRoles?.json,
    });
  };

  const currNameFilterCallback = (options: TOptionToShow[]) => {
    const qstring: TQStringDecoded = decodeQString() || {
      ...byDefaultFilterSettings,
    };
    qstring.name = {
      json: options,
      value: namesRef.current.value,
    };
    window.history.pushState(
      {},
      "",
      "?q=" + encodeURIComponent(btoa(JSON.stringify(qstring)))
    );
    invoqueFilterCallback(qstring);
  };

  const currRegionFilterCallback = (options: TOptionToShow[]) => {
    const qstring: TQStringDecoded = decodeQString() || {
      ...byDefaultFilterSettings,
    };
    qstring.regions = {
      json: options,
      value: "",
    };
    window.history.pushState(
      {},
      "",
      "?q=" + encodeURIComponent(btoa(JSON.stringify(qstring)))
    );
    invoqueFilterCallback(qstring);
  };
  const currIndustryRolesFilterCallback = (options: TOptionToShow[]) => {
    const qstring: TQStringDecoded = decodeQString() || {
      ...byDefaultFilterSettings,
    };
    qstring.industryRoles = {
      json: options,
      value: "",
    };
    window.history.pushState(
      {},
      "",
      "?q=" + encodeURIComponent(btoa(JSON.stringify(qstring)))
    );
    invoqueFilterCallback(qstring);
  };

  const initialFilterSetup = (
    industryRolesData: TGenericIdName[],
    regionsData: TGenericIdName[],
    filterValues?: TQStringDecoded
  ) => {
    namesRef.current.value = filterValues?.name?.value || "";

    const regionsQS: TOptionToShow[] = filterValues?.regions?.json || [];
    const industryRolesQS: TOptionToShow[] =
      filterValues?.industryRoles?.json || [];

    const currRegionFilters = regionsData.map(
      (data): TOptionToShow => ({
        data,
        selected: regionsQS.findIndex((r) => r.data.id === data.id) > -1,
      })
    );

    const currIndustryRolesFilters = industryRolesData.map(
      (data): TOptionToShow => ({
        data,
        selected: industryRolesQS.findIndex((i) => i.data.id === data.id) > -1,
      })
    );

    setRegionFilters(currRegionFilters);
    setIndustryRolesFilters(currIndustryRolesFilters);

    const qStringData = filterValues || byDefaultFilterSettings;
    invoqueFilterCallback(qStringData);
  };

  const { search } = useLocation();

  useEffect(() => {
    if (regionsFilter.length > 0 && industryRolesFilter.length > 0) {
      const searchValues = decodeQString();
      initialFilterSetup(
        industryRolesFilter.map((o) => o.data),
        regionsFilter.map((o) => o.data),
        searchValues
      );
    } else {
      Promise.all([getRegions(), getIndustryRoles()]).then(
        ([regions, industryRoles]) => {
          const searchValues = decodeQString();

          initialFilterSetup(industryRoles, regions, searchValues);
        }
      );
    }
    // eslint-disable-next-line
  }, [search]);

  const resetFiltersClickHandler = () => {
    window.history.pushState(
      {},
      "",
      window.location.origin + window.location.pathname
    );
    initialFilterSetup(
      industryRolesFilter.map((o) => o.data),
      regionsFilter.map((o) => o.data)
    );
  };

  return (
    <div
      className="container rounded shadow-sm p-3 my-3"
      data-testid="filter-panel"
    >
      <div className="row">
        <div className="col-lg-4">
          <InputDropdownLabNameSearch
            searchJustFromContactList={searchJustFromContactList}
            nameFilterCallback={currNameFilterCallback}
            namesRef={namesRef}
            data-testid="name-filter"
          />
        </div>
        <div className="col-md-6 col-lg-3">
          {regionsFilter.length > 0 && (
            <DropdownMultiSelect
              btnColor="info"
              title="Regions"
              optionsToShow={regionsFilter}
              setOptionsToShow={setRegionFilters}
              applyFilterCallback={currRegionFilterCallback}
              data-testid="region-filter"
            />
          )}
        </div>
        <div className="col-md-6 col-lg-3">
          {industryRolesFilter.length > 0 && (
            <DropdownMultiSelect
              btnColor="info"
              title="Open Positions"
              optionsToShow={industryRolesFilter}
              setOptionsToShow={setIndustryRolesFilters}
              applyFilterCallback={currIndustryRolesFilterCallback}
              data-testid="industry-filter"
            />
          )}
        </div>
        <div className="col-lg-2 text-center">
          <button
            className="btn btn-outline-info border-2 fw-bold py-1 w-100"
            onClick={resetFiltersClickHandler}
            data-testid="reset-button"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};
