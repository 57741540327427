import { Footer } from './main-page/footer';
import { HowItWorks } from './main-page/how-it-works';
import { Intro } from './main-page/intro';
import { Labs } from './main-page/labs';
import { StatsSection } from './main-page/stats-section';
import { Testimonials } from './main-page/testimonials';

export const MainPage = () => {
  return (
    <>
      <Intro />
      <HowItWorks />
      <Labs />
      <StatsSection />
      <Testimonials />
      <Footer />
    </>
  );
};
