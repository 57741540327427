import { useRouteNavigation } from "../../../lib/common/routes";
import { cleanURL } from "../../../lib/utils";
import { useUserService } from "../../../services/users";
import { useLocation } from "react-router-dom"

type TMenuOption = {
    label: string,
    iconClass: string,
    pathName: string,
    clickHandler?: () => void
}

export const LeftMenu = () => {
    const { logOutUser } = useUserService();
    const { getRoute, navigateToRoute } = useRouteNavigation();

    const { pathname } = useLocation();
    const clearedPathName = `/${cleanURL(pathname)}`;

    const defaultClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, customClickHandler?: () => void) => {
        e.preventDefault();
        e.stopPropagation();
        if (customClickHandler) customClickHandler();
    }

    const options: TMenuOption[] = [
        { label: 'Dashboard', iconClass: "fa-solid fa-house", pathName: getRoute("adminDashboard"), clickHandler: () => navigateToRoute("adminDashboard") },
        { label: 'Reports', iconClass: "fa-solid fa-flag", pathName: getRoute("adminReportList"), clickHandler: () => navigateToRoute("adminReportList") },
        { label: 'Positions', iconClass: "fa-solid fa-users-gear", pathName: getRoute("adminPositions"), clickHandler: () => navigateToRoute("adminPositions") },
        { label: 'Stats', iconClass: "fa-solid fa-chart-simple", pathName: "tbd" },
        { label: 'Alerts', iconClass: "fa-solid fa-triangle-exclamation", pathName: "tbd" },
        { label: 'Log out', iconClass: "fa-solid fa-right-from-bracket", clickHandler: logOutUser, pathName: "tbd" },


    ]
    return <ul className="list-group list-group-flush">
        {options.map((l, idx) => <li key={"li-" + l.label} className={"list-group-item my-1 d-inline-flex p-2 " + (clearedPathName === l.pathName ? 'active' : '')}>
            <button className="col-2 btn btn-link">
                <i className={l.iconClass + ""} />
            </button>
            <button
                onClick={(e) => { defaultClickHandler(e, l.clickHandler) }}
                className="btn btn-link ms-2">
                {l.label}
            </button>
        </li>)}
    </ul>
}