import { useRef } from 'react';
import CountUp, { useCountUp } from 'react-countup';

export const StatsSection = () => {
    const techCounterRef = useRef(null);
    const labCounterRef = useRef(null);
    const marketCounterRef = useRef(null);
    const agrCounterRef = useRef(null);


    // Dental Techs
    useCountUp({
        ref: techCounterRef,
        end: 45350,
        enableScrollSpy: true,
        duration: 2,
        // scrollSpyDelay: 1000,
    });

    // Dental Labs
    useCountUp({
        ref: labCounterRef,
        end: 9259,
        enableScrollSpy: true,
        duration: 2,
        // scrollSpyDelay: 1000,
    });

    // Market Size
    useCountUp({
        ref: marketCounterRef,
        start: 0,
        end: 7.1,
        decimals: 1,
        prefix: '$',
        suffix: 'B',
        enableScrollSpy: true,
        duration: 2,
        // scrollSpyDelay: 1000,
    });

    // Annual Growth Rate
    useCountUp({
        ref: agrCounterRef,
        start: 0,
        end: 6.7,
        decimals: 1,
        suffix: '%',
        enableScrollSpy: true,
        duration: 2,
        // scrollSpyDelay: 1000,
    });

    return (
        <div className="container-fluid p-0">
            <div className="container p-5 px-lg-0">
                <div className="row mb-4">
                    <div className="col-lg-5 text-center">
                        <h1 className='m-0' style={{ fontSize: "xxx-large" }}>INDUSTRY</h1>
                        <h1 className="fw-bold home-title-h1" > STATS</h1>
                    </div>
                    <div className="col-lg-7 px-3">
                        <h6 className="lh-lg">
                            At WinWinGo, we're proud of our impressive track record.
                            <br />
                            With a high success rate in matching skilled dental technicians with top-tier labs,
                            our platform stands out in the industry. Get the latest industry stats at a glance:</h6>
                        <p><small>(Data from the Bureau of Labor and Statistics)</small></p>
                    </div>
                </div>
                <div className="row m-md-5 m-lg-3 m-lg-0 bg-dark rounded shadow-lg">
                    <div className="col-lg-5 image-section-implant rounded shadow"></div>
                    <div className="col-lg-7 my-5 text-center">
                        <div className="row mb-lg-5 text-white">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-5 mb-5">
                                <h1 className='stats-h1' ref={techCounterRef}><CountUp start={0} end={45350} /></h1>
                                <h2>Dental Techs</h2>
                            </div>
                            <div className="col-lg-5 mb-5">
                                <h1 className='stats-h1' ref={labCounterRef}><CountUp start={0} end={9259} /></h1>
                                <h2>Dental Labs</h2>
                            </div>
                        </div>
                        <div className="row text-white">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-5 mb-5">
                                <h1 className='stats-h1' ref={marketCounterRef}><CountUp start={0} end={7.1} /></h1>
                                <h2>Market Size</h2>
                            </div>
                            <div className="col-lg-5">
                                <h1 className='stats-h1' ref={agrCounterRef}><CountUp start={0} end={6.7} /></h1>
                                <h3>Annual Growth Rate</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}