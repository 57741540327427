import { createContext, useContext, useEffect, useState } from "react";
import { TUserInfo, TUserTypes } from "../lib/types/users";
import { Footer } from "../components/sections/main-page/footer";
import { getFirebaseAppAuth } from "../services/users";
import { EmergentChat } from "../components/chat/chat";
import { useServiceContext } from "./service";
import { Header } from "../components/common/header";
import { BlockedUserNotice } from "../components/common/block-notice";

type TSecureSectionProps = {
    children: any,
    type?: TUserTypes,
    allowUserEnabled?: boolean,
    types?: TUserTypes[]
}

type AuthInformation = {
    isLoggedIn: boolean,
    setIsLoggedIn: (val: boolean) => void,
    getUserTokenId: () => Promise<string>,
    setUserTokenId: (val: string) => void,
    logOutUser: () => void,
    userInfo?: TUserInfo,
    setUserInfo: (v: TUserInfo) => void,
    SecureSection: (p: TSecureSectionProps) => React.ReactElement,
    logInUser: (tokenId: string, userInfo: TUserInfo) => void
    updateUserInfoInStorage: (info: TUserInfo) => void,
    updateUserInfoFromLocalStorage: () => void,
}

const AuthContext = createContext<AuthInformation>({
    isLoggedIn: false,
    setIsLoggedIn: (v: boolean) => { },
    getUserTokenId: async () => "",
    setUserTokenId: (v: string) => { },
    logOutUser: () => { },
    setUserInfo: () => { },
    SecureSection: (p: TSecureSectionProps) => (<></>),
    logInUser: (tokenId: string, userInfo: TUserInfo) => { },
    updateUserInfoInStorage: (info) => { },
    updateUserInfoFromLocalStorage: () => { }
});

export const useAuthContext = () => {
    return useContext(AuthContext);
}

export const AuthProvider = (props: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userTokenId, setUserTokenIdState] = useState("");
    let lastTimeTokenId: number | undefined = undefined;
    const [userInfo, setUserInfo] = useState<TUserInfo>({
        id: "",
        name: "",
        other: undefined,
        type: "lab",
        enabled: true,
        images: {
            background: "",
            external: "",
            internal: "",
            logo: "",
            resume: "",
        }
    })
    const { chats: { removeSocket } } = useServiceContext();

    const cleanUp = () => {
        setUserTokenId("");
        setUserInfo({
            id: "",
            name: '',
            other: undefined,
            type: "lab",
            enabled: true,
            images: {
                background: "",
                external: "",
                internal: "",
                logo: "",
                resume: "",
            }
        });
        updateUserInfoInStorage(undefined);
        localStorage.setItem('tokenId', "");
        removeSocket();
    }

    const logOutUser = () => {
        cleanUp();
        setIsLoggedIn(false);
    }

    const updateUserInfoInStorage = (info?: TUserInfo) => {
        localStorage.setItem('userInfo', info ? JSON.stringify(info) : "");
    }

    const logInUser = (tokenId: string, userInfo?: TUserInfo) => {
        setIsLoggedIn(true);

        if (userInfo) {
            setUserInfo(userInfo);
            updateUserInfoInStorage(userInfo);
        }
        setUserTokenId(tokenId);
        localStorage.setItem('tokenId', tokenId);
    }


    const SecureSection = ({
        children,
        type = "other",
        types,
        allowUserEnabled }: TSecureSectionProps): React.ReactElement => {

        let isUserTypeAllowed = false;
        if (type) {
            isUserTypeAllowed = type !== "other" ? userInfo.type === type : true;
        } else if (types && types.length > 0) {
            isUserTypeAllowed = types.includes(userInfo.type);
        }

        if (!type && (!types || types.length === 0))
            throw new Error("An user type must be indicated");


        //        const isUserTypeAllowed = type !== "other" ? userInfo.type === type : true;
        const isUserEnabled = allowUserEnabled !== undefined ? allowUserEnabled : userInfo.enabled;
        const { chats: {
            showChat, userIdToChat
        } } = useServiceContext();


        if (!!!isLoggedIn && !!!isUserTypeAllowed) {
            return (<>
                <Header title="" />
                <div className="container text-center mt-5" style={{ minHeight: "10rem" }}>
                    <p>
                        You're not authorized to be here
                    </p>
                </div >
                <Footer />
            </>)
        } else if (!!!isUserEnabled) {
            return (<>
                <Header title="" />
                <div className="container text-center mt-5" style={{ minHeight: "10rem" }}>
                    <BlockedUserNotice />
                </div >
                <Footer />
            </>)
        }
        else {
            return (<>
                {children}
                {showChat && <EmergentChat withUserId={userIdToChat.id} withUserName={userIdToChat.name} />}
            </>)
        }
    }

    const setUserTokenId = (tokenId: string) => {
        lastTimeTokenId = Date.now();
        setUserTokenIdState(tokenId);
    }

    function delay(milliseconds: number) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    const getUserTokenId = async (): Promise<string> => {
        const nowTime = Date.now();

        if (!lastTimeTokenId || (nowTime - lastTimeTokenId) > 300000) {
            const frbAppAuth = getFirebaseAppAuth();
            if (!frbAppAuth.currentUser) {
                await delay(1000);
                return await getUserTokenId();
            }
            const newToken = await frbAppAuth.currentUser.getIdToken();
            if (!newToken) {
                await delay(1000);
                return await getUserTokenId();
            }
            if (newToken) {
                logInUser(
                    newToken,
                    undefined as any
                )
                return newToken
            }
        }
        return userTokenId;
    }

    const retrieveFromLocalStorage = () => {
        const userInfols = localStorage.getItem('userInfo');
        const tokenIdls = localStorage.getItem('tokenId');

        if (!userInfols || !tokenIdls) return;

        setIsLoggedIn(true);
        setUserTokenId(tokenIdls);
        setUserInfo(JSON.parse(userInfols) as TUserInfo);
    }

    const updateUserInfoFromLocalStorage = () => {
        const userInfols = localStorage.getItem('userInfo');
        if (!userInfols) return;
        setUserInfo(JSON.parse(userInfols) as TUserInfo);
    }

    useEffect(() => {

        setTimeout(() => {

            retrieveFromLocalStorage();
        }, 0);
        //eslint-disable-next-line
    }, []);



    const value = {
        isLoggedIn,
        getUserTokenId,
        setIsLoggedIn,
        userTokenId,
        setUserTokenId,
        logOutUser,
        userInfo,
        setUserInfo,
        SecureSection,
        logInUser,
        updateUserInfoInStorage,
        updateUserInfoFromLocalStorage
    }

    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    )
}