import { useParams } from "react-router-dom"
import { Header } from "../../common/header"
import { Footer } from "../main-page/footer"
import { useSubscriptionService } from "../../../services/subscriptions";
import { NotificationProvider } from "../../../contexts/notification-context";
import { useRouteNavigation } from "../../../lib/common/routes";


export const SubscriptionOKTech = () => {

    const { subscriptionPlanId, checkoutsessionid } = useParams();
    const intSubscriptionPlanId = parseInt(subscriptionPlanId || "-1", 10);
    const { getRoute, navigateRouteClickHandler } = useRouteNavigation();

    const { savePaymentInfo } = useSubscriptionService();

    savePaymentInfo(checkoutsessionid as string, intSubscriptionPlanId);

    return (
        <>
            <div className="bg-dark">
                <NotificationProvider>
                    <Header title="" />
                </NotificationProvider>
                <div className="container-fluid welcome-abroad-bg welcome-abroad-bg__tech">                 <div className="container">
                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col text-center py-5 px-3">
                            <h2><strong>Welcome Aboard</strong></h2>
                            <br />
                            <h3>Let's Start the Next Chapter <br /> of Your Adventure</h3>
                            <a className="btn btn-primary mt-3 mt-md-5 mb-5 rounded-3"
                                onClick={(e) => navigateRouteClickHandler('dashboardTech', e)}
                                href={getRoute('dashboardTech')}
                            >

                                Open My Dashboard
                            </a>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            </div>
        </>
    )

}