import { PropsWithChildren, useEffect } from "react"
import { ASSETS_ROUTES } from "../../../lib/common/constants"
import { LeftMenu } from "./left-menu"
import { evaluators, useWindowSizeMatch } from "../../../lib/window-size-matcher"

type TLayoutProps = {
    title: string
}
export const Layout = (props: TLayoutProps & PropsWithChildren) => {
    const { title } = props;
    const { conditionFulfilled: isSmallDevice } = useWindowSizeMatch({ condition: evaluators.lessThan(768) })
    useEffect(() => {
        document.body.style.backgroundColor = "#f5f5f9";
    }, [])
    return <>
        <div className="container-fluid ps-0 admin-bg">
            {!isSmallDevice.fulfilled && (<div className="d-none d-md-block">
                <div className="col-md-3 col-lg-2 vh-100 p-4 admin-section admin-left-menu mb-2 position-fixed" >
                    <img src={ASSETS_ROUTES.IMGS.logo} alt="WWG logo" className="img-fluid rounded-1" />
                    <div className="mt-4"></div>
                    <LeftMenu />
                </div>
            </div>)}

            <div className="row ms-0 mt-4">
                {!isSmallDevice.fulfilled && <div className="col d-none d-md-block admin-main">
                    <div className="row mb-3">
                        <div className="col ">
                            <div className="admin-section px-3">

                                <h3 className="py-3">{title}</h3>
                            </div>
                        </div>
                    </div>
                    {props.children}
                </div>
                }
                {isSmallDevice.fulfilled &&
                    <div className="d-block d-md-none">
                        <div className="row mb-3">
                            <div className="">
                                <div className="admin-section rounded-3 admin-left-menu row m-0">
                                    <div className="col">
                                        {/* Collapse Menu */}
                                        <div className="mt-3">
                                            <button className="btn btn-primary px-3 py-2 rounded-1"
                                                type="button" data-toggle="collapse" data-target="#collapseBOmenu" aria-expanded="false" aria-controls="collapseBOmenu">
                                                <i className="fa-solid fa-bars"></i>
                                            </button>
                                        </div>
                                        <div className="collapse card mt-2" id="collapseBOmenu" style={{ borderRadius: "10px" }}>
                                            <LeftMenu />
                                        </div>
                                    </div>
                                    {/* Title */}
                                    <div className="col-10 text-start">
                                        <h3 className="py-3">{title}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.children}
                    </div>
                }
            </div>

        </div>
    </>
}