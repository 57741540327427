import { useRouteNavigation } from "../../lib/common/routes";
import { Header } from "../common/header"
import { Footer } from "./main-page/footer"

export const Story = () => {
    const { navigateRouteClickHandler } = useRouteNavigation();

    return (
        <>
            <Header title="" />
            <div className="container-fluid">
                <div className="container">
                    <div className="row px-4">
                        <div className="col-lg-6 my-md-5 mt-5 px-0 px-lg-5 mx-lg-3" >
                            <div>
                                <div>
                                    <h5 className="fw-bold pb-2 ">Our Story</h5>
                                    <h1 className="fw-bold pb-2">Helping Dental Techs find Paradise</h1>
                                </div>
                                <div className="mt-4">
                                    <p >Dental labs compete for lab talent
                                        only on the basis of salaries and benefits.</p>

                                    <p >An approach that practically
                                        guarantees a win/lose outcome.</p>
                                    <p>
                                        At a time when dental lab work is in high demand, dental labs around the
                                        country are finding it harder and harder to hire qualified dental techs.
                                    </p>
                                    <p>
                                        And yet, today’s impersonal, supermarket job sites with their dry, generic job
                                        listings fail to serve these dental labs, and the dental technicians who are
                                        seeking that ideal job.
                                    </p>
                                    <p>
                                        The result is that dental labs almost exclusively compete only on salaries and
                                        benefits when looking for staff.
                                    </p>
                                    <p>
                                        This leaves them scrambling around for good and skilled employees.
                                    </p>
                                    <p className="">
                                        The result?
                                    </p>
                                    <p className="">
                                        Qualified, talented and ambitious dental techs being stuck in a mediocre lab
                                        in an unhappy living environment.
                                    </p>
                                    <p>
                                        While top labs in choice locations offering great work and living environments
                                        are desperate for the personnel they need.
                                    </p>
                                    <div className="py-5 px-4 mx-auto my-5 bg-light fw-semibold rounded shadow">
                                        <p>
                                            What if there was a better way to connect the two so that both can win?
                                            Career choices are no longer only about work, or what one earns.
                                        </p>
                                        <p>
                                            It’s also all about life before and after work.
                                        </p>
                                        <p>
                                            Those moments where we choose to spend our time and our hard-earned
                                            income.
                                        </p>
                                        <p>
                                            About where we can walk, hike, shop, run, bike, swim, or simply discover a
                                            new restaurant or a place to unwind on the weekend.
                                        </p>
                                        <p>

                                            About where we want to start a family and see our children to grow up.
                                        </p>
                                        <p>

                                            About the lifestyle that suits each of us individually.
                                        </p>
                                    </div>
                                    <p>

                                        This small detail has been completely overlooked by today’s “one-size-fits-all”
                                        approach to dental lab tech job listings.
                                    </p>
                                    <p>

                                        Dental techs have a life beyond the four walls of the lab where they perform
                                        their magic to help others achieve a healthier, happier life.
                                    </p>
                                    <p className="fw-bold">

                                        Quality of life before and after work is a major factor in choosing which lab
                                        they’ll call home.
                                    </p>

                                </div>
                            </div>


                        </div>
                        <div className="col-lg-5 mt-md-3">
                            <div className="mt-5 row gap-5 text-end">
                                <div className="">
                                    <img
                                        alt=""
                                        src="https://images.unsplash.com/photo-1531983412531-1f49a365ffed?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        className="rounded shadow story-img"
                                    />
                                </div>
                                <div className="text-start">
                                    <img
                                        alt=""
                                        src="https://images.unsplash.com/photo-1688991710857-f9339ae9fa5f?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        className="rounded shadow story-img"
                                    />
                                </div>
                                <div className="">
                                    <img
                                        alt=""
                                        src="https://images.unsplash.com/photo-1468493858157-0da44aaf1d13?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        className="rounded shadow story-img"
                                    />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="my-md-5 mt-5 px-0 px-lg-5 mx-lg-3">
                        <h1 className="ms-3">CEO's Vision</h1>
                        <div className="row bg-light rounded">
                            <div className="col-lg-6 p-5">
                                <p className="fw-bold">
                                    Career moves are now “life decisions.”
                                </p>
                                <p>

                                    It’s why, in creating a career platform specifically for dental labs and dental
                                    technicians, we took a holistic approach that looks more like a travel brochure
                                    than an outdated job site.
                                    Because every dental lab is different and operates in a unique setting.
                                </p>
                                <p>

                                    Labs need a way to introduce candidates to the advantages of that lab and its
                                    environment.
                                </p>
                                <p>

                                    The dental tech looking for a job needs to get a real feel for the prospective
                                    employer and the environment where the lab is located.
                                </p>
                                <p>

                                    To restore the power of choice to both dental labs and dental lab techs ready to
                                    make a shift, or have a change of venue.
                                </p>
                                <p>
                                    This is why WinWinGo came into existence.
                                </p>
                                <p>
                                    And precisely why <strong>our mission is helping dental techs find paradise.</strong>
                                </p>
                                <button className="btn btn-dark mt-3 px-5 rounded-3"
                                    onClick={(e) => navigateRouteClickHandler("aboutMark", e)}>
                                    About Mark</button>
                                <div className="text-end pt-4">
                                    <img src="images/global/signature-Mark-Hidde_black.png" alt="Signature" className="w-50" />
                                </div>
                            </div>
                            <div className="col-lg-6 p-md-5 p-0">
                                <img src="/images/global/pic-quotemark-color.jpg" alt="MarkPhoto" className="mark-img w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};