import { useEffect, useState } from "react";
import { useAuthContext } from "../../../contexts/auth-context"
import { TReportStats, TUsersStats, getStats, getUserStats } from "../../../services/backoffice/backoffice-service";
import { TProcessStates } from "../../../lib/types/general";

export const useMainBusinessLogic = () => {
    const { getUserTokenId } = useAuthContext();
    const [reportStats, setReportStats] = useState<TReportStats>({ underReview: 0, unhandled: 0, appealed: 0 });
    const [userStats, setUserStats] = useState<TUsersStats>({
        labs: { disabled: 0, enabled: 0 },
        techs: { disabled: 0, enabled: 0 }
    });
    const [workingState, setWorkingState] = useState<TProcessStates>("idle");

    const refreshReportStats = async () => {
        setWorkingState("working");
        const userTokenId = await getUserTokenId();
        const statsRepo = await getStats(userTokenId);

        setReportStats(statsRepo.data.data);
        setWorkingState("done");
    }

    const refreshUserStats = async () => {
        setWorkingState("working");
        const userTokenId = await getUserTokenId();
        const statsUsers = await getUserStats(userTokenId);

        setUserStats(statsUsers.data.data)
        setWorkingState("done");
    }


    useEffect(() => {
        Promise.all([refreshUserStats(), refreshReportStats()])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        reportStats,
        userStats,
        refreshReportStats,
        refreshUserStats,
        workingState
    }
}