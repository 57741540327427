import { Header } from "../common/header"
import { SignupTechForm } from "../signup-forms/signup-tech"
import { Footer } from "./main-page/footer"

export const DentalTechSignup = () => {
    return (
        <>
            <Header title="Dental Tech Signup" />
            <div className="container-fluid gray-container">
                <div className="container px-0 px-md-5">
                    <div className="row px-4 py-5">
                        <SignupTechForm />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}