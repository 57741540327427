import { apiRequestSecureGet, apiRequestSecuredPost } from '../lib/common/api';

import { TChatResponse } from '../lib/types/chats';

const endpointAPIChats = 'chats/conversations';
const endpointAPIStartConversation = 'chats/conversation/start/';
const endpointAPIEndConversation = 'chats/conversation/end/';
const endpointAPIGetMessages = 'chats/conversation/messages/';

export const getConversations = async (
  userTokenId: string,
  withUserId: string
): Promise<TChatResponse> => {
  const resp = await apiRequestSecureGet<TChatResponse>(
    endpointAPIChats,
    userTokenId
  );

  return resp.data;
};
export const getMessages = async (
  userTokenId: string,
  withUserId: string,
  lastMessageDateNoTZ?: string
): Promise<TChatResponse> => {
  const dateFilter =
    lastMessageDateNoTZ === undefined
      ? ''
      : `/${encodeURIComponent(lastMessageDateNoTZ)}`;
  const resp = await apiRequestSecureGet<TChatResponse>(
    endpointAPIGetMessages + encodeURIComponent(withUserId) + dateFilter,
    userTokenId
  );

  return resp.data;
};

export const startChatting = async (
  userTokenId: string,
  withUserId: string
): Promise<TChatResponse> => {
  const resp = await apiRequestSecureGet<TChatResponse>(
    endpointAPIStartConversation + encodeURIComponent(withUserId),
    userTokenId
  );
  return resp.data;
};

export const endChatting = async (
  userTokenId: string,
  withUserId: string
): Promise<TChatResponse> => {
  const resp = await apiRequestSecureGet<TChatResponse>(
    endpointAPIEndConversation + encodeURIComponent(withUserId),
    userTokenId
  );
  return resp.data;
};

export const sendMessage = async (
  content: string,
  userTokenId: string,
  conversationId: string
) => {
  return await apiRequestSecuredPost(
    endpointAPIGetMessages + encodeURIComponent(conversationId),
    userTokenId,
    {
      content,
    }
  );
};
