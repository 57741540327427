import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom"
import { TApiLab, TLabFilter } from "../../../lib/types/labs";
import { useLabs } from "../../../services/laboratories";
import { map, latLng, tileLayer, MapOptions, latLngBounds, Map, marker, LatLngBoundsExpression, icon, LatLng, popup, Marker } from "leaflet";

import 'leaflet/dist/leaflet.css';
import { Header } from "../../common/header";
import { useFileManagement } from "../../../services/file-management";
import { TGenericIdName, TProcessStates } from "../../../lib/types/general";
import { getRegionsWhereLabsAreHiring } from "../../../services/regions";
import { TRegion } from "../../../lib/types/regions";
import { DropdownMultiSelect, TOptionToShow } from "../../common/dropdown-multi-select";
import { TLabsFilterSettings } from "../../common/dental-lab-search-filter-panel";
import "../../../sass/bootstrap/bootstrap.scss"
import "../../../sass/index.scss"
import { getIndustryRoles } from "../../../services/generalInfo";
import { Footer } from "../main-page/footer";
import { useAuthContext } from "../../../contexts/auth-context";
import { useRouteNavigation } from "../../../lib/common/routes";
import { ASSETS_ROUTES, LAB_SEARCH_PAGINATION_SIZE } from "../../../lib/common/constants";
import { Pagination } from "../../common/pagination";
import { Spinner } from "../../common/spinner";
import { NotificationProvider } from "../../../contexts/notification-context";

let myMap: {

    map?: Map,
    markers: Marker[]
} = {
    map: undefined,
    markers: []
}

export const useHiringLabsLogic = (mapRef: React.MutableRefObject<any>) => {

    const { state, public: { getHiringLabs } } = useLabs();
    const [labs, setLabs] = useState<TApiLab[]>([]);
    const [industryRoles, setIndustryRoles] = useState<TGenericIdName[]>([]);
    const { isLoggedIn } = useAuthContext();
    const mapInitialSetup: MapOptions = {
        zoom: 12,
    };
    const [totalCount, setTotalCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1);

    const getGeoBounds = (labs: TApiLab[]): LatLngBoundsExpression => {

        if (labs.length === 0) return latLngBounds(latLng(51.505, -0.09), latLng(51.505, -0.09));

        const lats = labs.map(l => parseFloat(l.zip_code_location?.split(",")[0] || "0"));
        const longs = labs.map(l => parseFloat(l.zip_code_location?.split(",")[1] || "0"));

        return latLngBounds(latLng(Math.min(...lats), Math.min(...longs)), latLng(Math.max(...lats), Math.max(...longs)))
    }

    const getLabLatLng = (lab: TApiLab): LatLng => {
        const coordinates = lab.zip_code_location?.split(",").map(n => parseFloat(n)) || [1, 1];
        return latLng(coordinates[0], coordinates[1]);
    }

    const setMarkers = (labs: TApiLab[], map: Map, showLabName: boolean) => {
        if (myMap.markers.length > 0) {

            myMap.markers.forEach(m => m.removeFrom(map));

        };

        if (labs.length === 0) return;

        const markerIcon = icon({
            iconUrl: '/images/global/map-marker-icon.png',
            iconSize: [38, 55],
            iconAnchor: [19, 55],
            popupAnchor: [0, -50],

        });
        labs.forEach(l => {

            const latLng = getLabLatLng(l);
            const labMarker = marker(latLng, {
                icon: markerIcon
            });

            const thePopup = popup({
                closeButton: false
            }).setLatLng(latLng).setContent(`<strong class="${showLabName ? '' : 'blurredText'}" >${l.name}</strong><br/>${l.other.positions.length} open positions`);

            labMarker.bindPopup(thePopup);
            labMarker.addEventListener("mouseover", (e) => {
                labMarker.openPopup();
                const labElements = document.getElementsByClassName(`scrollToLab-${l.id}`);
                if (labElements && labElements[0]) {
                    Array.of(...labElements).forEach(e => {

                        e.scrollIntoView({ behavior: "smooth" });
                        e.getElementsByClassName("card-maps")[0].classList.add("card-selected");
                    })
                }
                //thePopup.openOn(labMarker);
            })

            labMarker.addEventListener("mouseout", () => {
                thePopup.close();
                const labElements = document.getElementsByClassName(`scrollToLab-${l.id}`);
                if (labElements && labElements[0]) {
                    Array.of(...labElements).forEach(e => {

                        e.getElementsByClassName("card-maps")[0].classList.remove("card-selected");
                    })
                }
            })

            //labMarker.bindPopup(`<b><img style="height: 70px" src="${getImagePath(l.images?.logo, l.id)}"/> <br/> ${l.name}</b>`);
            labMarker.addTo(map);
            myMap.markers.push(labMarker);
        })
    }


    const enableMap = (labs: TApiLab[]) => {
        if (!mapRef.current || !myMap) {
            setTimeout(() => {
                enableMap(labs);
            }, 1000);
            return;
        }

        if (!myMap.map) {
            myMap.map = map(mapRef.current, mapInitialSetup);
            tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(myMap.map);
        };

        myMap.map.fitBounds(getGeoBounds(labs));

        setMarkers(labs, myMap.map, isLoggedIn);

    }


    const centerLabOnMap = (lab: TApiLab, map: Map) => {
        map.setView(getLabLatLng(lab), 15);
    }

    const getLabs = async (filter: TLabFilter, offset: number) => {
        const data = await getHiringLabs(filter, offset);
        setLabs(data.data);
        enableMap(data.data);
        setTotalCount(data.totalCount)
        setPageNumber(offset + 1)
    }

    useEffect(() => {
        getIndustryRoles().then(industryRoles => {
            setIndustryRoles(industryRoles);

        });
        return () => {
            myMap.map = undefined;
        }
    },
        //eslint-disable-next-line
        []);

    return {
        workingState: state,
        labs,
        industryRoles,
        centerLabOnMap,
        getLabs,
        testable_getGeoBounds: getGeoBounds,
        showRegisteringInvitation: !isLoggedIn,
        totalCount,
        pageNumber,
    }
}

type LabInfoProps = {
    data: TApiLab,
    centerLabClickHandler: (lab: TApiLab) => void,
    industryRoles: TGenericIdName[],
    showRegisteringInvitation: boolean
}
export const LabInfo = (props: LabInfoProps) => {
    const { data, centerLabClickHandler, industryRoles, showRegisteringInvitation } = props;
    const { userInfo } = useAuthContext();
    const { getImagePath } = useFileManagement();
    const { navigateRouteClickHandler } = useRouteNavigation();
    const notificationRef = useRef<any>();

    const idHashHue = (id: string) => {
        const l = id.length;
        let ret = 1;
        for (let i = 0; i < l; i++) {
            ret = ((id.charCodeAt(i) % 365) * ret) % 359;
        }

        return ret;
    }


    const imgPath = showRegisteringInvitation ? "/images/global/default_lab_logo.jpeg" : data.images?.logo ? getImagePath(data.images.logo, "fixed", data.id) : ASSETS_ROUTES.IMGS.labAvatarUrl;
    const imgLogoStyle: CSSProperties | undefined = showRegisteringInvitation ? {
        filter: `hue-rotate(${idHashHue(props.data.id)}deg)`
    } : undefined;
    const industryRolesNames = data.other.positions.map(p => industryRoles.find(v => v.id === p)?.name).join(", ");

    const clickHandler = (lab: TApiLab) => {
        if (notificationRef.current) {
            notificationRef.current.classList.add('card-notification-show');
        }
        centerLabClickHandler(lab);
    }
    const registrationMessage = "Please create an account to see lab details and connect with labs";

    return <div className="col-md-4 h-100 mb-2">
        <div className="d-none d-md-block">
            <a data-testid="labInfo"
                className={`scrollToLab-${data.id} d-flex text-decoration-none card-maps`}
                href="#dummyValue" onClick={() => clickHandler(data)} >
                <div className="card card-maps rounded w-100 " >
                    <img className="card-img-top card-lg-img " style={{ ...imgLogoStyle }} src={imgPath} alt={data.name + ' logo'} />
                    <div className="card-body ">
                        <h6 className={"my-2 fw-bold " + ((showRegisteringInvitation ? "blurredText" : ""))}
                            title={showRegisteringInvitation ? registrationMessage : data.name}
                        >
                            {data.name}
                        </h6>
                        <p className="card-text">
                            Open positions: <span style={{ fontWeight: "lighter" }}> <small>{industryRolesNames}</small></span>
                        </p>
                    </div>
                    {
                        !showRegisteringInvitation && userInfo && userInfo.type === "tech" &&
                        <div className="card-body d-flex align-items-end">
                            <button
                                onClick={(e) => navigateRouteClickHandler("dentalLabInfo", e, data.id, true)}
                                className="btn btn-primary w-100">
                                see more
                            </button>
                        </div>
                    }

                </div>
            </a>
        </div>
        <div className="d-block d-md-none m-1">
            <a data-testid="labInfoSmall"
                className={`scrollToLab-${data.id} text-decoration-none`}
                href="#dummyValue" onClick={() => clickHandler(data)} >
                <div className="card card-maps mb-3 w-100 rounded">
                    <div className="row">
                        <div className="col-3 ">
                            <img className="rounded-start w-100 h-100" style={{ ...imgLogoStyle, objectFit: "cover" }} src={imgPath} alt={data.name + ' logo'} />
                        </div>
                        <div className="col-9">
                            <div className="card-body">
                                <a href="#dummyValue" className="text-decoration-none">
                                    <h6 className={"fw-bold " + ((showRegisteringInvitation ? "blurredText" : ""))} title={showRegisteringInvitation ? registrationMessage : data.name}>
                                        {data.name}
                                    </h6>
                                    <p>
                                        Open positions: <span style={{ fontWeight: "lighter" }}>{data.other.positions.map(p => industryRoles.find(v => v.id === p)?.name).join(", ")}</span>
                                    </p>
                                    {
                                        !showRegisteringInvitation && userInfo && userInfo.type === "tech" &&
                                        <div>
                                            <button
                                                onClick={(e) => navigateRouteClickHandler("dentalLabInfo", e, data.id, true)}
                                                className="btn btn-link p-0">
                                                see more...
                                            </button>
                                        </div>
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>


    </div >
}

type TSearchRowProps = {
    regions: TGenericIdName[],
    filterCallback: (filterSettings: TLabsFilterSettings) => void;
    initialRegionFromMainSection: string,
    positions: TGenericIdName[],
}

type TQStringDecoded = {

    regions: {
        json: TOptionToShow[];
        value: string;
    };
    industryRoles: {
        json: TOptionToShow[];
        value: string;
    };
};
const decodeQString = (): TQStringDecoded | undefined => {
    const qString = window.location.search;
    if (!qString) return undefined;

    const parameters = qString.split("?")[1].split("q=");
    if (parameters.length < 2) return undefined;
    const valueToDecode = JSON.parse(atob(decodeURIComponent(parameters[1])));
    return valueToDecode;
};

export const SearchRow = (props: TSearchRowProps) => {
    const { regions, filterCallback, initialRegionFromMainSection, positions } = props;
    const [regionsFilter, setRegionFilters] = useState<TOptionToShow[]>([]);
    const [industryRolesFilter, setIndustryRolesFilters] = useState<
        TOptionToShow[]
    >([]);

    const byDefaultFilterSettings: TQStringDecoded = {
        regions: {
            json: [],
            value: "",
        },
        industryRoles: {
            json: [],
            value: "",
        },
    };

    const invoqueFilterCallback = (qstring: TQStringDecoded) => {
        filterCallback({
            names: [],
            regions: qstring.regions?.json,
            industryRoles: qstring.industryRoles?.json,
        });
    };



    const currRegionFilterCallback = (options: TOptionToShow[]) => {
        const qstring: TQStringDecoded = decodeQString() || {
            ...byDefaultFilterSettings,
        };
        qstring.regions = {
            json: options,
            value: "",
        };

        window.history.pushState(
            {},
            "",
            "?q=" + encodeURIComponent(btoa(JSON.stringify(qstring)))
        );
        invoqueFilterCallback(qstring);
    };
    const currPositionsFilterCallback = (options: TOptionToShow[]) => {
        const qstring: TQStringDecoded = decodeQString() || {
            ...byDefaultFilterSettings,
        };
        qstring.industryRoles = {
            json: options,
            value: "",
        };

        window.history.pushState(
            {},
            "",
            "?q=" + encodeURIComponent(btoa(JSON.stringify(qstring)))
        );
        invoqueFilterCallback(qstring);
    };

    useEffect(() => {

        const currFilters = decodeQString();
        let selectedRegions: TOptionToShow[] = [];

        if (currFilters?.regions && currFilters.regions.json.length > 0) {
            selectedRegions = currFilters.regions.json;
        } else {
            selectedRegions = [{
                selected: true,
                data: { id: initialRegionFromMainSection, name: "" }
            }]
        }
        const regionFilterOptions = regions.map((r): TOptionToShow => ({
            data: r,
            selected: selectedRegions.findIndex(p => p.data.id === r.id) > -1
        }))

        setRegionFilters(regionFilterOptions);

        let selectedIndustryRoles: TOptionToShow[] = [];
        if (currFilters?.industryRoles && currFilters.industryRoles.json.length > 0) {
            selectedIndustryRoles = currFilters.industryRoles.json;
        }
        const positionsFilterOptions = positions.map((i): TOptionToShow => ({
            data: i,
            selected: selectedIndustryRoles.findIndex(p => p.data.id === i.id) > -1
        }));
        setIndustryRolesFilters(positionsFilterOptions);

        invoqueFilterCallback({
            industryRoles: {
                json: selectedIndustryRoles,
                value: "",
            },
            regions: {
                json: selectedRegions,
                value: ""
            }
        });


        // eslint-disable-next-line
    }, [regions, positions])


    return <>
        {/* <div className="col-md-1 d-none d-md-block"></div> */}
        <div className="col-12 d-flex align-items-center justify-content-center">
            <i className="fa-solid fa-map-location-dot me-3 fa-xl"></i>
            <p className="d-none d-md-block mt-2 me-2">
                Filter by regions:
            </p>
            <DropdownMultiSelect
                title="regions"
                optionsToShow={regionsFilter}
                setOptionsToShow={setRegionFilters}
                applyFilterCallback={currRegionFilterCallback}
                data-testid="region-filter"
                btnColor="info"
            />
        </div>

        {industryRolesFilter.length > 0 && (
            <div className="col-12 d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-users-between-lines me-3 fa-xl"></i>
                <p className="d-none d-md-block mt-2 me-2">
                    Filter by open positions:
                </p>
                <DropdownMultiSelect
                    btnColor="info"
                    title="positions"
                    optionsToShow={industryRolesFilter}
                    setOptionsToShow={setIndustryRolesFilters}
                    applyFilterCallback={currPositionsFilterCallback}
                    data-testid="industry-filter"
                />
            </div>
        )}
    </>
}


export const HiringLabs = () => {

    const theParams = useParams();
    const mapRef = useRef<any>();
    const mapCardsRef = useRef<HTMLDivElement>(null);
    const { workingState, labs, centerLabOnMap, industryRoles, getLabs, showRegisteringInvitation, totalCount, pageNumber } = useHiringLabsLogic(mapRef);
    const [regions, setRegions] = useState<TRegion[]>([]);
    const [positions, setPositions] = useState<TGenericIdName[]>([]);
    const [workingStateHiringRegions, setWorkingStateHiringRegions] = useState<TProcessStates>("idle");
    const [filterSettings, setFilterSettings] = useState<TLabsFilterSettings>({
        names: [],
        regions: [],
        industryRoles: []
    });

    const filterCallback = (filterSettings: TLabsFilterSettings, offset: number) => {
        getLabs({
            filterPositions: filterSettings.industryRoles.map(v => v.data.id),
            filterRegions: filterSettings.regions.map(v => v.data.id)
        }, offset);
        setFilterSettings(filterSettings)
    }

    useEffect(() => {
        setWorkingStateHiringRegions("working");

        Promise.all([getRegionsWhereLabsAreHiring(), getIndustryRoles()]).then(([hiringRegions, positionsList]) => {
            setRegions(hiringRegions.data);
            setPositions(positionsList);
            setWorkingStateHiringRegions("done");

        })

    }, []);

    return <>
        <NotificationProvider>
            <Header title="Labs hiring by region" />
            <div className="container-fluid">
                <div className="rounded border border-1 border-light shadow w-100 mb-4 p-3">
                    <div className="row row-cols-md-auto g-4 justify-content-center" ref={mapCardsRef}>
                        {workingStateHiringRegions === "working" && <Spinner />}
                        {workingStateHiringRegions === "done" &&
                            <SearchRow
                                positions={positions}
                                initialRegionFromMainSection={theParams.regionId as string}
                                filterCallback={(filterSettings) => filterCallback(filterSettings, 0)}
                                regions={regions}
                            />
                        }
                    </div>
                </div>
                <div className="row m-0">
                    {/* map */}
                    <div className="col-md-5 map-sass m-0" ref={mapRef}></div>
                    {/* lab list */}
                    <div className="col-md-7 pt-2 map-list-sass" >
                        <div className="row">
                            {
                                workingState === "working" && <Spinner />
                            }
                            {(myMap.map !== undefined && (workingState === "done" || workingState === "idle")) &&
                                labs.map((lab, idx) => <LabInfo
                                    showRegisteringInvitation={showRegisteringInvitation}
                                    industryRoles={industryRoles}
                                    centerLabClickHandler={(lab: TApiLab) => { centerLabOnMap(lab, myMap.map as Map) }}
                                    data={lab} key={`${lab.name}_${idx}_${lab}`} />)
                            }
                        </div>
                        <Pagination
                            currentPage={pageNumber}
                            totalCount={totalCount}
                            itemsPerPage={LAB_SEARCH_PAGINATION_SIZE}
                            refObject={mapCardsRef}
                            onPageChange={(page: number) => { filterCallback(filterSettings, page) }}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </NotificationProvider>
    </>
}