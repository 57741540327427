import { Header } from "../common/header"
import { SignupLabForm } from "../signup-forms/signup-lab"
import { Footer } from "./main-page/footer"

export const DentalLabSignup = () => {
    return (
        <>
            <Header title="Dental Lab Signup" />
            <div className="container-fluid">
                <div className="container" >
                    <div className="row mx-lg-5 px-lg-5">
                        <SignupLabForm />
                    </div>
                </div>
            </div>
            <Footer />

        </>
    )
}