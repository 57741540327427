import { CSSProperties, KeyboardEvent, useRef, useState } from "react"

type TPillInput = {
    hook: TusePillInputReturn,
    style?: CSSProperties
}

type TusePillInputReturn = {
    values: string[],
    addValue: (val: string) => void,
    removeValueAt: (idx: number) => void,
    setValues: React.Dispatch<React.SetStateAction<string[]>>
}

type TusePillInput = (initialValues: string[]) => TusePillInputReturn

export const usePillInput: TusePillInput = (initialValues: string[] = []) => {

    const [values, setValues] = useState<string[]>([...initialValues]);

    const addValue = (newValue: string) => {
        setValues((before) => {
            const newValues = [...before, newValue];
            return newValues;
        });
    }

    const removeValueAt = (idx: number) => {
        setValues(old => {
            old.splice(idx, 1);
            const newValues = [...old];
            return newValues;
        })
    }

    return {
        values,
        addValue,
        removeValueAt,
        setValues
    }
}

export const PillInput = ({ hook }: TPillInput) => {

    const { values, addValue, removeValueAt } = hook;
    const inputRef = useRef<HTMLInputElement>(null);

    const keyDownHandler = (e: KeyboardEvent) => {
        e.stopPropagation();
        const target = (e.target as HTMLInputElement);
        if (e.key === 'Backspace') {
            if (target.value) return;

            if (!values.length) return;

            removeValueAt(values.length - 1);
            return;
        }
        if (e.key !== 'Enter' && e.key !== ',') return;
        e.preventDefault();
        const newValue = target.value as string;

        if (!newValue) return;

        if (values.find(val => val.toLocaleLowerCase() === newValue.toLocaleLowerCase())) return;

        addValuesHandler();

        target.value = "";

    }

    const addValuesHandler = () => {
        if (!inputRef.current || !inputRef.current.value.trim()) return;

        const pills = inputRef.current.value.split(",").map(pill => pill.trim()).filter(pill => pill);

        pills.forEach(pill => {
            if (!values.includes(pill.toLowerCase())) {
                addValue(pill);
            }
        });

        inputRef.current.value = "";
    }


    return (<>
        <div data-testid="container" className="row" >
            <div className="col-9">
                {values.map((val, idx) => (<span key={idx}
                    className="badge rounded-pill text-bg-info text-white py-0 px-2 d-inline-flex align-items-center me-2 text-wrap" data-testid="pill">{val}
                    <button key={idx} type="button" className="btn p-0 ps-1 text-white d-flex" onClick={(e) => removeValueAt(idx)}><small>x</small></button>
                </span >))
                }
                <input data-testid="input-area" ref={inputRef} className="form-control mt-2 py-0" onKeyDown={keyDownHandler}>
                </input>
            </div>
            <div className="col-3 p-0 d-inline-flex align-items-end ">
                <button className="btn btn-primary text-white fw-bold px-4 py-0"
                    onClick={addValuesHandler}
                    type="button"
                >+</button>
            </div>
        </div>

    </>
    )
}