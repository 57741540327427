import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom"
import { Header } from "../common/header";
import { getIndustryRoles } from "../../services/generalInfo";
import { TGenericIdName, TProcessStates } from "../../lib/types/general";
import { useTechnicians } from "../../services/technicians";
import { InviteButton } from "../common/invite-button";
import { TApiTechnician, TEducation, TExperience } from "../../lib/types/dentalTechs"
import { NotificationProvider, useNotificationContext } from "../../contexts/notification-context";
import { useAuthContext } from "../../contexts/auth-context";
import { getCacheKillQueryString } from "../../lib/common/api";
import { FileUploaderComponent, useFileManagement } from "../../services/file-management";
import { BackButton } from "../common/backbutton";
import { useUserService } from "../../services/users";
import { PositionsCheckListNoFormik } from "../common/positions-check-list";
import { PillInput, usePillInput } from "../common/pillInput";
import { ConnectionRequestInfoAndButtons } from "../common/notifications";
import { getRegions } from "../../services/regions";
import { Footer } from "./main-page/footer";
import { ASSETS_ROUTES, ERROR_IS_FRAUD } from "../../lib/common/constants";
import { ReportButton } from "../common/report-button";
import { Spinner } from "../common/spinner";
import { UserNotExist } from "../common/user-not-exist";
import { ErrorMessageFraud, ErrorMessageSticky } from "../common/alert-message";
import { Spinner as SpinnerSmall } from "../sections/backoffice/common/spinner"
import { emailRegex, numberRegex, phoneFormat, phoneRegex } from "../../lib/common/validation";


declare global {
    interface Window { wwgTechInfo_dataWasModified: boolean; }
}


type TDentalTechProps = {
    editable?: boolean,
    userExist: boolean,
    techInfo?: TApiTechnician,
}

const currencyFormater = new Intl.NumberFormat();


export const TechInfo = ({ editable = false, techInfo, userExist: paramUserExist }: TDentalTechProps) => {

    const { userId } = useParams();
    const { updateTechAPI, techAPI2UserINfo } = useUserService();
    const { userInfo: currentUserInfo, updateUserInfoInStorage, updateUserInfoFromLocalStorage } = useAuthContext();
    const { getImagePath, makeDroppableCapable } = useFileManagement();
    const { getTech } = useTechnicians();
    const [regions, setRegions] = useState<TGenericIdName[]>([]);
    const [positions, setPositions] = useState<TGenericIdName[]>([]);

    const [userInfo, setUserInfo] = useState<TApiTechnician>({
        id: "",
        name: "",
        inmycontactlist: false,
        enabled: true,
        images: {
            background: "",
            external: "",
            internal: "",
            logo: "",
            resume: "",
        },
        other: {
            desiredRegion: "",
            desiredSalaryPerYear: 0,
            email: "",
            firstName: "",
            hobbies: [],
            imgUrl: "",
            interests: [],
            lastName: "",
            positions: [],
            yearsOfExperience: 0,
            linkedin: "",
            location: "",
            phone: "",
            secondaryEmail: "",
            zip: "",
            description: "",
        },
    });
    const [userExist, setUserExist] = useState<boolean>(true);
    const [logoImg, setLogo] = useState<string | undefined>(ASSETS_ROUTES.IMGS.techAvatarUrl);
    const [backgroundImg, setBackgroundImg] = useState<string | undefined>(undefined);
    const [resume, setResume] = useState<string | undefined>(undefined);
    const [workingState, setWorkingState] = useState<TProcessStates>('done');
    const [successUpload, setSuccessUpload] = useState<boolean>(false);
    const [inappropriateContent, setInappropriateContent] = useState(false);
    const [unsupportedImageType, setUnsupportedImageType] = useState(false);


    const refLogoPhoto = useRef<HTMLDivElement>(null);
    const refBackgroundPhoto = useRef<HTMLDivElement>(null);

    const updateTechAPIAndLogalStorage = async (data: TApiTechnician, name?: string) => {
        await updateTechAPI(data, name);
        updateUserInfoInStorage(techAPI2UserINfo(data, name));
        window.wwgTechInfo_dataWasModified = true;
    }

    const updateLogo = (newfile?: string, inappropriateContent?: boolean, unsupportedImageType?: boolean) => {
        if (inappropriateContent) {
            setInappropriateContent(true);
            setLogo(ASSETS_ROUTES.IMGS.techAvatarUrl);
        } else if (unsupportedImageType) {
            setUnsupportedImageType(true);
            setLogo(ASSETS_ROUTES.IMGS.techAvatarUrl);
        } else {
            if (!newfile) {
                setLogo(ASSETS_ROUTES.IMGS.techAvatarUrl);
            } else {
                const cacheKill = editable ? `?${getCacheKillQueryString()}` : "";
                setInappropriateContent(false);
                setUnsupportedImageType(false);
                setLogo(
                    getImagePath(newfile, "fixed", !editable ? userId : undefined) + cacheKill
                );
            }
        }
    }


    const updateBackgroundImg = (newfile?: string, inappropriateContent?: boolean, unsupportedImageType?: boolean) => {
        if (inappropriateContent) {
            setInappropriateContent(true)
            setBackgroundImg(undefined)
        } else if (unsupportedImageType) {
            setUnsupportedImageType(true);
            setBackgroundImg(undefined);
        }
        else {
            if (!newfile) {
                setBackgroundImg(undefined);
            } else {
                console.log("updateBackgoundImg - setting new background");
                const cacheKill = editable ? `?${getCacheKillQueryString()}` : "";
                setInappropriateContent(false)
                setUnsupportedImageType(false);
                setBackgroundImg(
                    getImagePath(newfile, "fixed", !editable ? userId : undefined) + cacheKill
                );
            }
        }
    }

    const updateResume = (newfile?: string) => {
        if (!newfile) {
            setResume(undefined);
        } else {
            const cacheKill = editable ? `?${getCacheKillQueryString()}` : "";
            setResume(getImagePath(newfile, "fixed", !editable ? userId : undefined) + cacheKill);
            setSuccessUpload(true);
        }
    }

    const bringUserInfo = async () => {
        if (!paramUserExist) {
            setUserExist(false);
            return;
        };

        const info = techInfo !== undefined ? [techInfo] : await getTech(userId || currentUserInfo?.id as string);
        if (info.length === 0) {
            setUserExist(false);
            return;
        }
        setUserInfo(info[0]);
        setUserExist(true);

        const extraValues = await Promise.all([getRegions(), getIndustryRoles()]);
        setRegions(extraValues[0]);
        setPositions(extraValues[1]);

        updateLogo(info[0]?.images.logo);
        updateBackgroundImg(info[0]?.images.background);
        updateResume(info[0]?.images.resume);

        if (info[0].images.logo) {
            setLogo(getImagePath("logo", "fixed", info[0].id) + '?' + getCacheKillQueryString());
        }
        if (info[0]?.images?.background) {
            setBackgroundImg(getImagePath("background", "fixed", info[0].id) + '?' + getCacheKillQueryString());
        }
        //eslint-disable-next-line


        setWorkingState('done');

    }

    useEffect(() => {
        bringUserInfo();
        if (editable) {
            makeDroppableCapable(refLogoPhoto, "logo", "image", "fixed", (newfile, inappropriateContent, unsupportedImageType) => {
                updateLogo(newfile, inappropriateContent, unsupportedImageType);
            });

            makeDroppableCapable(refBackgroundPhoto, "background", "image", "fixed", (newfile, inappropriateContent, unsupportedImageType) => {
                updateBackgroundImg(newfile, inappropriateContent, unsupportedImageType);
            });
        }

        return () => {
            if (window.wwgTechInfo_dataWasModified) {
                window.wwgTechInfo_dataWasModified = false;
                updateUserInfoFromLocalStorage();
            }
        }
        // eslint-disable-next-line
    }, [userId])


    const positionsList = (): TGenericIdName[] => {
        if (!userInfo || !userInfo?.other?.positions) return [];

        return positions.filter((pos) => {
            return userInfo.other?.positions.includes(pos.id)
        })
    }

    const NameSection = () => {
        const nameRef = useRef<any>();
        const [isEditing, setIsEditing] = useState(false);
        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditing(true);
        }

        const saveName = async () => {
            const newName = nameRef.current.value;
            setCurrWorkingState("working");
            setIsEditing(false);
            await updateTechAPIAndLogalStorage({
                ...userInfo,
            }, newName);
            setUserInfo({
                ...userInfo,
                name: newName
            });
            setCurrWorkingState("done");
        }

        return (
            <>
                {!isEditing && (<>
                    {userId && (
                        <div className="text-center text-md-start">
                            <h3 className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : 'fw-bold'}>
                                {workingState === 'done' ? (nameRef.current?.value || userInfo?.name) : <>&nbsp;</>}
                            </h3>
                        </div>
                    )}
                    {!userId && (<div className="d-flex align-items-center justify-content-between w-100">
                        <div role="button" onClick={editableOnClickHandler}
                            className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : ' fs-3 fw-bold'}>
                            {workingState === 'done' ? (nameRef.current?.value || userInfo?.name) : <>&nbsp;</>}
                        </div>
                        <div className="fileTooltip rightEdit " role="button" data-tooltip=" Edit "
                            onClick={editableOnClickHandler}
                        >
                            <i className="fa-regular fa-pen-to-square fa-xs p-2 w-100 h-100 shadow d-flex justify-content-center align-items-center bg-light rounded-circle" data-testid="edit-icon"
                            ></i>
                        </div>
                    </div>)
                    }
                </>
                )}
                {
                    isEditing &&
                    (<div className="rounded p-3 shadow w-auto h-auto mb-3">
                        <input className="text-center mb-2 form-control form-control-md" ref={nameRef} type="text" defaultValue={userInfo?.name} />
                        <div className="d-grid gap-2 mt-2 w-100 d-md-block">
                            <button className="btn btn-primary px-4" onClick={saveName}>Save</button>
                            <button className="btn btn-danger ms-md-2 px-3 " onClick={() => {
                                nameRef.current.value = userInfo?.name;
                                setIsEditing(false);
                            }} >Cancel</button>
                        </div>
                    </div>
                    )
                }
            </>
        )
    }


    const TechInfoSection = () => {
        const [isEditing, setIsEditing] = useState(false);
        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditing(true);
        }

        const [errorYears, setErrorYears] = useState('');
        const [errorSalary, setErrorSalary] = useState('');
        const [errorZip, setErrorZip] = useState('');

        const saveRegions = async () => {

            const yearsValid = refYearsOfExperience.current.value;
            const salaryValid = refDesiredSalary.current.value;
            const zipValid = refZip.current.value;

            let hasError = false;

            if (!numberRegex.test(yearsValid)) {
                setErrorYears('Please use numbers only for Years of Experience.');
                hasError = true;
            } else {
                setErrorYears('');
            }
            if (!numberRegex.test(salaryValid)) {
                setErrorSalary('Please use numbers only for Desired Salary.');
                hasError = true;
            } else {
                setErrorSalary('');
            }
            if (!numberRegex.test(zipValid)) {
                setErrorZip('Please use numbers only for your ZIP.');
                hasError = true;
            } else {
                setErrorZip('');
            }

            if (hasError) {
                return;
            }



            setCurrWorkingState("working");
            await updateTechAPIAndLogalStorage({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    desiredRegion: refRegions.current.value,
                    yearsOfExperience: refYearsOfExperience.current.value,
                    interests: [...interestPills.values],
                    hobbies: [...hobbiesPills.values],
                    desiredSalaryPerYear: refDesiredSalary.current.value,
                    location: refLocation.current.value,
                    zip: refZip.current.value,
                }
            }, undefined);

            setUserInfo({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    desiredRegion: refRegions.current.value,
                    yearsOfExperience: refYearsOfExperience.current.value,
                    interests: [...interestPills.values],
                    hobbies: [...hobbiesPills.values],
                    desiredSalaryPerYear: refDesiredSalary.current.value,
                    location: refLocation.current.value,
                    zip: refZip.current.value,
                }
            });
            setIsEditing(false);
            setCurrWorkingState("done");
        }

        const refRegions = useRef<any>({});
        const refYearsOfExperience = useRef<any>({});
        const refDesiredSalary = useRef<any>({});
        const interestPills = usePillInput([]);
        const hobbiesPills = usePillInput([]);
        const refLocation = useRef<any>({});
        const refZip = useRef<any>({});

        useEffect(() => {

            refRegions.current.value = userInfo.other.desiredRegion;
            refYearsOfExperience.current.value = userInfo?.other?.yearsOfExperience;
            refDesiredSalary.current.value = userInfo?.other?.desiredSalaryPerYear;
            interestPills.setValues([...(userInfo?.other?.interests || [])]);
            hobbiesPills.setValues([...(userInfo?.other?.hobbies || [])]);
            refLocation.current.value = userInfo?.other?.location;
            refZip.current.value = userInfo?.other?.zip;
            //eslint-disable-next-line
        }, [])

        return (
            <>
                {!isEditing &&
                    <>
                        <div className="d-flex justify-content-between w-100 mb-3">
                            <h5 className="fw-bold"><i className="fa-solid fa-address-card me-3 text-info"></i>PERSONAL PROFILE</h5>
                            {!userId &&
                                <div className="fileTooltip rightEdit ms-auto" role="button" data-tooltip=" Edit "
                                    onClick={editableOnClickHandler}
                                    data-testid="edit-icon">
                                    <i className="fa-regular fa-pen-to-square fa-xs p-2 w-100 h-100 shadow d-flex justify-content-center align-items-center bg-light rounded-circle" data-testid="edit-icon"
                                    ></i>
                                </div>
                            }
                        </div>
                        <div className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : ''}>
                            {!!userInfo?.other.yearsOfExperience && <div className="row mb-3">
                                <div className="col-5"><strong>Experience</strong></div>
                                <div className="col-7">{userInfo?.other?.yearsOfExperience} years</div>
                            </div>}
                            <div className="row mb-3">
                                <div className="col-5"><strong>Desired Region</strong></div>
                                <div className="col-7">
                                    {regions.find(p => p.id === (refRegions?.current?.value || userInfo?.other?.desiredRegion))?.name}
                                </div>
                            </div>
                            {!!userInfo?.other.desiredSalaryPerYear && <div className="row mb-3">
                                <div className="col-5"><strong>Desired Salary</strong></div>
                                <div className="col-7"> {currencyFormater.format(userInfo?.other?.desiredSalaryPerYear) && `$ ${currencyFormater.format(userInfo?.other?.desiredSalaryPerYear)} yearly`}</div>
                            </div>}
                            {!userId && (
                                <div className="row mb-3">
                                    <div className="col-5"><strong>Current Address</strong></div>
                                    <div className="col-7">{userInfo?.other?.location}</div>
                                </div>
                            )}
                            {!!userInfo?.other.zip && <div className="row mb-3">
                                <div className="col-5"><strong>ZIP Code</strong></div>
                                <div className="col-7">{userInfo?.other?.zip}</div>
                            </div>}
                            <div className="row mb-3">
                                <div className="col-5"><strong>Interests</strong></div>
                                <div className="col-7">
                                    {userInfo?.other?.interests.map((interest, index) => (
                                        <span key={index} className="badge rounded-pill text-bg-info text-white me-2">
                                            {interest}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-5"><strong>Hobbies</strong></div>
                                <div className="col-7">
                                    {userInfo?.other?.hobbies.map((hobbies, index) => (
                                        <span key={index} className="badge rounded-pill text-bg-info text-white me-2">
                                            {hobbies}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    isEditing &&
                    <div className="row align-items-end gap-2">
                        <div className="col-md-5">
                            <h6>Years of Experience: </h6>
                        </div>
                        <div className="col-md-6">
                            <input
                                className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading form-control' : 'form-control'}
                                ref={refYearsOfExperience}
                                type="text" defaultValue={userInfo?.other?.yearsOfExperience} />
                        </div>
                        {errorYears && (
                            <div className="col-12">
                                <p className="text-danger m-0"><small>{errorYears}</small></p>
                            </div>
                        )}
                        <div className="col-md-5">
                            <h6>Interested in working in:</h6>
                        </div>
                        <div className="col-md-6">
                            <select
                                className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading form-control' : 'form-select form-control'}
                                ref={refRegions}
                                defaultValue={refRegions.current?.value || userInfo?.other?.desiredRegion}
                            >
                                {regions.map(r => (<option key={r.id} value={r.id}>{r.name}</option>))}
                            </select>
                        </div>
                        <div className="col-md-5">
                            <h6>Desired Salary:</h6>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <p className="mb-0">$</p>
                            <input
                                className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading form-control' : 'form-control d-inline-block mx-1'}
                                placeholder="0"
                                ref={refDesiredSalary}
                                type="text"
                                defaultValue={userInfo?.other?.desiredSalaryPerYear}
                            />
                            <p className="mb-0">yearly</p>
                        </div>
                        {errorSalary && (
                            <div className="col-12">
                                <p className="text-danger m-0"><small>{errorSalary}</small></p>
                            </div>
                        )}
                        <hr className="mt-3" />
                        <div className="col-md-5">
                            <h6>Current address:</h6>
                        </div>
                        <div className="col-md-6">
                            <input
                                className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading form-control' : 'form-control'}
                                placeholder="Street Address, City, State"
                                ref={refLocation}
                                type="text" defaultValue={userInfo?.other?.location} />
                        </div>
                        <div className="col-md-5">
                            <h6>ZIP code:</h6>
                        </div>
                        <div className="col-md-6 position-relative">
                            <input
                                className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading form-control ' : 'form-control'}
                                placeholder="ZIP code"
                                ref={refZip}
                                type="text" defaultValue={userInfo?.other?.zip} />
                        </div>
                        {errorZip && (
                            <div className="col-12">
                                <p className="text-danger m-0"><small>{errorZip}</small></p>
                            </div>
                        )}
                        <hr className="my-3" />
                        <div className="p-3 my-3 rounded ai-container">
                            <h6> <i className="fa-solid fa-wand-magic-sparkles me-3 fa-lg" style={{ color: "#6468fe" }}></i>
                                Don't forget to include your interests and hobbies,
                                as our AI system will use this information to match you with the best labs.</h6>
                            <div className="row">
                                <div className="col-md-5 mt-3">
                                    <h6> Interests:</h6>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <PillInput hook={interestPills} />
                                </div>
                                <div className="col-md-5 mt-3">
                                    <h6> Hobbies:</h6>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <PillInput hook={hobbiesPills} />
                                </div>
                            </div>
                        </div>
                        <div className="d-grid gap-3 mt-2 w-100 d-md-block">
                            <button className="btn btn-primary px-4" onClick={saveRegions}>Save</button>
                            <button className="btn btn-danger px-3 ms-md-2" onClick={() => {
                                refRegions.current.value = userInfo?.other?.desiredRegion;
                                refYearsOfExperience.current.value = userInfo?.other?.yearsOfExperience;
                                refDesiredSalary.current.value = userInfo?.other?.desiredSalaryPerYear;
                                interestPills.setValues([...(userInfo?.other?.interests || [])]);
                                hobbiesPills.setValues([...(userInfo?.other?.hobbies || [])]);
                                setIsEditing(false);
                            }} >Cancel</button>
                        </div>
                    </div>
                }


            </>)
    }


    const PositionsSection = () => {
        const [isEditing, setIsEditing] = useState(false);
        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditing(true);
        }

        const savePositions = async () => {
            const newPositions = Object.keys(refPositions.current).filter((val) => refPositions.current[val])

            setCurrWorkingState("working");
            await updateTechAPIAndLogalStorage({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    positions: newPositions,
                }
            }, undefined);
            setUserInfo({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    positions: newPositions
                }
            });
            setIsEditing(false);
            setCurrWorkingState("done");
        }

        let defaultValue: {
            [key: string]: boolean
        } = {}

        const refPositions = useRef<any>({});

        useEffect(() => {
            const selectedPositions = userInfo.other?.positions.reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr]: true

                }
            }, defaultValue);

            refPositions.current = selectedPositions;
            //eslint-disable-next-line
        }, [])

        return (
            <>
                {!isEditing &&
                    <>
                        <div className="d-flex justify-content-between w-100 mb-3">
                            <h5 className="fw-bold"><i className="fa-solid fa-award me-3 text-info"></i>SKILLS</h5>
                            {!userId &&
                                <div className="fileTooltip rightEdit ms-auto" role="button" data-tooltip=" Edit "
                                    onClick={editableOnClickHandler}
                                    data-testid="edit-icon">
                                    <i className="fa-regular fa-pen-to-square fa-xs p-2 w-100 h-100 shadow d-flex justify-content-center align-items-center bg-light rounded-circle" data-testid="edit-icon"
                                    ></i>
                                </div>
                            }
                        </div>
                        <div className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : 'text-start'} >
                            {positionsList().map((pos) => (
                                <span className="badge rounded-pill border-2 border-info border text-dark me-2 mb-2 fs-6"> {pos.name}</span>
                            ))}
                        </div>
                    </>
                }
                {
                    isEditing &&
                    (<div className="p-3 mb-3">
                        <h6 className="fw-bold"> POSITIONS I'M LOOKING FOR: </h6>
                        <div className={"" + ((workingState !== 'done' || currWorkingState !== 'done') ? ' cssLoading' : '')}>
                            <div className="row">
                                <PositionsCheckListNoFormik
                                    positions={positions}
                                    checkedList={refPositions.current}
                                    refs={refPositions}
                                    wideColumns={true}
                                />
                            </div>

                            <div className="d-grid gap-3 mt-3 w-100 d-md-block">
                                <button className="btn btn-primary px-4" onClick={savePositions}>Save</button>
                                <button className="btn btn-danger ms-md-2 px-4" onClick={() => {
                                    setIsEditing(false);
                                }} >Cancel</button>
                            </div>
                        </div>
                    </div>
                    )
                }
            </>)
    }

    const InviteButtons = () => {

        const { invitationsList } = useNotificationContext();

        return (
            <div className="my-2">
                {
                    userInfo?.invitationsenttome === true &&
                    invitationsList.findIndex(p => p.from === userInfo.id) > -1 &&
                    <ConnectionRequestInfoAndButtons
                        userId={userInfo.id}
                        wideStyle={false}
                    />

                }
                {
                    !userInfo?.invitationsenttome &&
                    userInfo.invitationsentstate === 'accepted' &&
                    (<p className="invitation-accepted text-center">
                        <small>
                            <img src="/images/global/connected.svg" alt="connected" style={{
                                height: "1rem",
                                marginBottom: "0.3rem",
                                marginRight: ".3rem"
                            }} /> Invitation accepted
                        </small>
                    </p>
                    )
                }
                {
                    !userInfo?.invitationsenttome &&
                    userInfo.invitationsentstate !== 'accepted' &&
                    <p>
                        <InviteButton
                            userToInviteId={userId as string} userToInviteInvitationState={userInfo?.invitationsentstate} />

                    </p>
                }
            </div>
        )
    }



    const ExperienceSection = () => {

        const [newExperience, setNewExperience] = useState<TExperience[]>([]);
        const [isEditing, setIsEditing] = useState(false);
        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
        const [currentExperience, setCurrentExperience] = useState<number | null>(null);
        const [copiedExperience, setCopiedExperience] = useState<TExperience | null>(null)

        const saveExperience = async () => {
            setCurrWorkingState("working");
            userInfo.experience = { experience: [...(userInfo?.experience?.experience || [])] };
            await updateTechAPIAndLogalStorage({
                ...userInfo
            }, undefined);

            setUserInfo({
                ...userInfo,
            });
            setIsEditing(false);
            setCurrWorkingState("done");
        }
        const ExperienceRow = ({ experienceRow, rowIsEditing, idx }: { experienceRow: TExperience, rowIsEditing: boolean, idx: number }) => {

            let yearLine = undefined;

            if (experienceRow.yearStart) {
                if (experienceRow.yearEnd && experienceRow.yearEnd !== experienceRow.yearStart) {
                    yearLine = `${experienceRow.yearStart} - ${experienceRow.yearEnd}`
                } else {
                    yearLine = experienceRow.yearStart + "";
                }
            }
            return <>

                {!rowIsEditing &&
                    <div>
                        <div>
                            <p className="d-flex justify-content-between mb-2">
                                <strong>{experienceRow.Employer}</strong>
                                {editable && !isEditing &&
                                    <div className="d-none d-md-flex">
                                        <div className="d-flex justify-content-center align-items-center bg-light shadow rounded-circle"
                                            style={{ height: "25px", width: "25px" }}
                                            role="button"
                                            onClick={() => editExperience(idx)}>
                                            <i className="fa-regular fa-pen-to-square fa-xs"
                                                data-testid="edit-icon"></i>
                                        </div>
                                        <div className="ms-2 d-flex justify-content-center align-items-center bg-danger rounded-circle"
                                            style={{ height: "25px", width: "25px" }}
                                            onClick={() => removeExperience(idx)}
                                            role="button"
                                        >
                                            <i className="fa-regular fa-trash-can fa-xs text-white"
                                                data-testid="delete-icon"></i>
                                        </div>
                                    </div>
                                }
                            </p>
                        </div>
                        {(!!experienceRow.position) && <p>
                            {experienceRow.position}
                            {(!!yearLine) && <><br />{yearLine}</>}
                        </p>}
                    </div>
                }
                {rowIsEditing &&
                    <div>
                        <p className="mb-2">
                            <strong>
                                <input
                                    className="form-control"
                                    placeholder="Employer Name"
                                    type="text"
                                    onChange={(e) => experienceRow.Employer = e.target.value}
                                    defaultValue={experienceRow.Employer} />
                            </strong>

                        </p>
                        <p>
                            <input
                                className="form-control"
                                placeholder="Position"
                                type="text"
                                onChange={(e) => experienceRow.position = e.target.value}
                                defaultValue={experienceRow.position} />
                            <div className="row mt-2">
                                <div className="col">
                                    <input
                                        className="form-control"
                                        type="number"
                                        defaultValue={experienceRow.yearStart}
                                        onChange={(e) => experienceRow.yearStart = parseInt(e.target.value || "0", 10)}
                                        placeholder="Start year" />
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control"
                                        type="number"
                                        defaultValue={experienceRow.yearEnd}
                                        onChange={(e) => experienceRow.yearEnd = parseInt(e.target.value || "0", 10)}
                                        placeholder="End year" />
                                </div>
                            </div>

                        </p>
                    </div>}

            </>
        }

        const addExperience = () => {
            setIsEditing(true);

            setNewExperience([
                ...newExperience,
                {
                    Employer: "",
                    position: "",
                    yearEnd: new Date().getFullYear(),
                    yearStart: new Date().getFullYear()
                }
            ]
            )
        }

        const saveNewExperience = async () => {

            const oldExperience = userInfo?.experience?.experience || [];
            const emptyNewExperience = newExperience.filter(exp => exp.Employer !== "" && exp.position !== "");

            if (!emptyNewExperience.length) {
                setNewExperience(emptyNewExperience);
                setIsEditing(false);
                return;
            }
            userInfo.experience = {
                experience:
                    [...oldExperience, ...newExperience]
            }
            setNewExperience([]);
            await saveExperience();
        }

        const removeExperience = async (idx: number) => {
            const oldExperience = userInfo?.experience?.experience || [];
            oldExperience.splice(idx, 1);

            userInfo.experience = { experience: [...oldExperience] };
            await saveExperience();
        }

        const cancelNewExperience = () => {
            setIsEditing(false);
            setNewExperience([]);
        }

        const editExperience = (idx: number) => {
            setIsEditing(true)
            setCurrentExperience(idx);

            const originalExperience = userInfo.experience?.experience?.[idx];
            if (originalExperience) {
                const copyExperience: TExperience = { ...originalExperience };
                setCopiedExperience(copyExperience);
            }
        };

        const saveEditedExperience = async (idx: number) => {
            const updatedExperience = [...(userInfo?.experience?.experience || [])];
            setUserInfo({
                ...userInfo,
                experience: {
                    ...userInfo.experience,
                    experience: updatedExperience
                }
            });
            await saveExperience();
            setCurrentExperience(null);
        };

        const cancelEditExperience = () => {
            const oldExperience = [...(userInfo.experience?.experience || [])];
            if (copiedExperience !== null && currentExperience !== null) {
                oldExperience[currentExperience] = copiedExperience;
                setUserInfo({
                    ...userInfo,
                    experience: {
                        ...userInfo.experience,
                        experience: oldExperience,
                    },
                });
                setCopiedExperience(null);
            };
            setCurrentExperience(null);
            setIsEditing(false)
        }

        return <>
            <div >
                <h5 className="fw-bold mb-3"><i className="fa-solid fa-briefcase me-3 text-info"></i>EXPERIENCE</h5>
                {
                    <ul className="list-group list-group-flush rounded" >
                        {!!!userInfo.experience?.experience && (
                            <p style={{ color: "#a5a5a5" }} className="m-0">
                                <small> No experience provided yet.</small>
                            </p>)}
                        {(!!userInfo.experience?.experience) && userInfo.experience.experience.map((e, idx) =>
                        (<li data-testid="experience_item"
                            key={"li_experience_current_" + idx}
                            className={"list-group-item py-3 " + ((workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : '')}>

                            {currentExperience === idx ? (
                                <>
                                    <ExperienceRow
                                        key={"li_experience_current_row" + idx}
                                        rowIsEditing={true}
                                        experienceRow={e}
                                        idx={idx}
                                    />
                                    <div className="d-grid gap-2 mt-2 w-100 d-md-block">
                                        <button
                                            className="btn btn-primary px-4"
                                            onClick={() => saveEditedExperience(idx)}
                                        > Save
                                        </button>
                                        <button
                                            className="btn btn-danger ms-md-2"
                                            onClick={() => cancelEditExperience()}
                                        > Cancel
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <ExperienceRow
                                        key={"li_experience_current_row" + idx}
                                        rowIsEditing={false}
                                        experienceRow={e}
                                        idx={idx}
                                    />
                                    {editable && !isEditing &&
                                        <div className="d-sm-block d-md-none">
                                            <button
                                                className="btn btn-outline-dark py-0 px-5 me-3"
                                                onClick={() => editExperience(idx)}
                                            > Edit
                                            </button>
                                            <button
                                                className="btn btn-danger ms-2 py-0"
                                                onClick={() => removeExperience(idx)}
                                            > Delete
                                            </button>
                                        </div>
                                    }

                                </>
                            )}
                        </li>
                        ))}
                        {newExperience.map((e, idx) => <li
                            key={"li_newExperience_" + idx}
                            className="list-group-item py-3">
                            <ExperienceRow
                                rowIsEditing={true}
                                key={"li_newExperience_row" + idx}
                                experienceRow={e}
                                idx={idx}
                            />
                            {(editable && isEditing) && <>
                                <div className="col text-end">
                                    <button className="btn btn-primary px-4" onClick={saveNewExperience}>Save</button>
                                    <button className="btn btn-danger ms-2" onClick={cancelNewExperience}>Cancel</button>
                                </div>

                            </>}
                        </li>)}
                    </ul>
                }
                {(editable && !isEditing) && <div className="row">
                    <div className="col mt-3">
                        <button
                            data-testid="experience_add"
                            className="w-100 btn btn-outline-info" onClick={addExperience}>Add experience</button>
                    </div>
                </div>}
            </div>
        </>
    }

    const EducationSection = () => {

        const [newEducation, setNewEducation] = useState<TEducation[]>([]);
        const [isEditing, setIsEditing] = useState(false);
        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
        const [currentEducation, setCurrentEducation] = useState<number | null>(null);
        const [copiedEducation, setCopiedEducation] = useState<TEducation | null>(null)


        const saveEducation = async () => {
            setCurrWorkingState("working");
            userInfo.education = { education: [...(userInfo?.education?.education || [])] };
            await updateTechAPIAndLogalStorage({
                ...userInfo
            }, undefined);

            setUserInfo({
                ...userInfo,
            });
            setIsEditing(false);
            setCurrWorkingState("done");
        }
        const EducationRow = ({ educationRow, rowIsEditing, idx }: { educationRow: TEducation, rowIsEditing: boolean, idx: number }) => {

            let yearLine = undefined;

            if (educationRow.yearStart) {
                if (educationRow.yearEnd && educationRow.yearEnd !== educationRow.yearStart) {
                    yearLine = `${educationRow.yearStart} - ${educationRow.yearEnd}`
                } else {
                    yearLine = educationRow.yearStart + "";
                }
            }
            return <>

                {!rowIsEditing &&
                    <div>
                        <div>
                            <p className="d-flex justify-content-between mb-2">
                                <strong>{educationRow.schoolName}</strong>
                                {editable && !isEditing &&
                                    <div className="d-none d-md-flex">
                                        <div className="d-flex justify-content-center align-items-center bg-light shadow rounded-circle"
                                            style={{ height: "25px", width: "25px" }}
                                            onClick={() => editEducation(idx)}
                                            role="button"
                                        >
                                            <i className="fa-regular fa-pen-to-square fa-xs"
                                                data-testid="edit-icon"></i>
                                        </div>
                                        <div className="ms-2 d-flex justify-content-center align-items-center bg-danger rounded-circle"
                                            style={{ height: "25px", width: "25px" }}
                                            onClick={() => removeEducation(idx)}
                                            role="button"
                                        >
                                            <i className="fa-regular fa-trash-can fa-xs text-white"
                                                data-testid="delete-icon"></i>
                                        </div>
                                    </div>
                                }
                            </p>
                        </div>
                        {(!!educationRow.degree_or_certificate) && <p>
                            {educationRow.degree_or_certificate}
                            {(!!yearLine) && <><br />{yearLine}</>}
                        </p>}
                    </div>}
                {rowIsEditing && <>
                    <p className="mb-2">
                        <strong>
                            <input
                                className="form-control"
                                placeholder="School Name"
                                type="text"
                                onChange={(e) => educationRow.schoolName = e.target.value}
                                defaultValue={educationRow.schoolName} />
                        </strong>

                    </p>
                    <p>
                        <input
                            className="form-control"
                            placeholder="Education degree / title / certificate"
                            type="text"
                            onChange={(e) => educationRow.degree_or_certificate = e.target.value}
                            defaultValue={educationRow.degree_or_certificate} />
                        <div className="row mt-2">
                            <div className="col">
                                <input
                                    className="form-control"
                                    type="number"
                                    defaultValue={educationRow.yearStart}
                                    onChange={(e) => educationRow.yearStart = parseInt(e.target.value || "0", 10)}
                                    placeholder="Start year" />
                            </div>
                            <div className="col">
                                <input
                                    className="form-control"
                                    type="number"
                                    defaultValue={educationRow.yearEnd}
                                    onChange={(e) => educationRow.yearEnd = parseInt(e.target.value || "0", 10)}
                                    placeholder="End year" />
                            </div>
                        </div>

                    </p>
                </>}

            </>
        }

        const addEducation = () => {
            setIsEditing(true);

            setNewEducation([
                ...newEducation,
                {
                    degree_or_certificate: "",
                    schoolName: "",
                    yearEnd: new Date().getFullYear(),
                    yearStart: new Date().getFullYear()
                }
            ]
            )
        }

        const saveNewEducation = async () => {

            const oldEducation = userInfo?.education?.education || [];
            const emptyNewEducation = newEducation.filter(edu => edu.degree_or_certificate !== "" && edu.schoolName !== "");

            if (!emptyNewEducation.length) {
                setNewEducation(emptyNewEducation);
                setIsEditing(false);
                return;
            }

            userInfo.education = {
                education:
                    [...oldEducation, ...newEducation]
            }
            setNewEducation([]);
            await saveEducation();
        }

        const removeEducation = async (idx: number) => {
            const oldEducation = userInfo?.education?.education || [];
            oldEducation.splice(idx, 1);
            userInfo.education = { education: [...oldEducation] };
            await saveEducation();
        }

        const cancelNewEducation = () => {
            setIsEditing(false);
            setNewEducation([]);
        }

        const editEducation = (idx: number) => {
            setIsEditing(true);
            setCurrentEducation(idx);
            const originalEducation = userInfo.education?.education[idx];
            if (originalEducation) {
                const copyEducation: TEducation = { ...originalEducation };
                setCopiedEducation(copyEducation)
            }
        }

        const saveEditedEducation = async (idx: number) => {
            const newEducation = [...(userInfo?.education?.education || [])]
            setUserInfo({
                ...userInfo,
                education: {
                    ...userInfo.education,
                    education: newEducation
                }
            });
            await saveEducation();
            setCurrentEducation(null)
        }

        const cancelEditEducation = () => {
            setIsEditing(false)
            const oldEducation = [...(userInfo.education?.education || [])];
            if (copiedEducation !== null && currentEducation !== null) {
                oldEducation[currentEducation] = copiedEducation;
                setUserInfo({
                    ...userInfo,
                    education: {
                        ...userInfo.education,
                        education: oldEducation,
                    }
                })
                setCurrentEducation(null);
                setCopiedEducation(null)
            }
        }

        return <>
            <div >
                <h5 className="fw-bold mb-3"><i className="fa-solid fa-book me-3 text-info"></i>EDUCATION</h5>
                {
                    <ul className="list-group list-group-flush rounded" >
                        {!!!userInfo.education?.education && (
                            <p style={{ color: "#a5a5a5" }} className="m-0">
                                <small> No education provided yet.</small>
                            </p>)}
                        {(!!userInfo.education?.education) && userInfo.education.education.map((e, idx) =>
                        (<li
                            data-testid="education_item"
                            key={"li_education_current_" + idx}
                            className={"list-group-item py-3" + ((workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : '')}
                        >

                            {currentEducation === idx ? (
                                <>
                                    <EducationRow
                                        key={"li_education_current_row" + idx}
                                        rowIsEditing={true}
                                        educationRow={e}
                                        idx={idx}
                                    />
                                    <div className="d-grid gap-2 mt-2 w-100 d-md-block">
                                        <button
                                            className="btn btn-primary px-4"
                                            onClick={() => saveEditedEducation(idx)}
                                        > Save
                                        </button>
                                        <button
                                            className="btn btn-danger ms-md-2"
                                            onClick={() => cancelEditEducation()}
                                        > Cancel
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <EducationRow
                                        key={"li_education_current_row" + idx}
                                        rowIsEditing={false}
                                        educationRow={e}
                                        idx={idx}
                                    />
                                    {editable && !isEditing &&
                                        <div className="d-sm-block d-md-none">
                                            <button
                                                className="btn btn-outline-dark py-0 px-5 me-3"
                                                onClick={() => editEducation(idx)}
                                            > Edit
                                            </button>
                                            <button
                                                className="btn btn-danger ms-2 py-0"
                                                onClick={() => removeEducation(idx)}
                                            > Delete
                                            </button>
                                        </div>
                                    }
                                </>
                            )}
                        </li>))}
                        {newEducation.map((e, idx) => <li
                            key={"li_newEducation_" + idx}
                            className="list-group-item">
                            <EducationRow
                                rowIsEditing={true}
                                key={"li_newEducation_row" + idx}
                                educationRow={e}
                                idx={idx}
                            />
                            {(editable && isEditing) && <>
                                <div className="col text-end">
                                    <button className="btn btn-primary px-4" onClick={saveNewEducation}>Save</button>
                                    <button className="btn btn-danger ms-2" onClick={cancelNewEducation}>Cancel</button>
                                </div>
                            </>}
                        </li>)}
                    </ul>
                }
                {(editable && !isEditing) && <div className="row">
                    <div className="col mt-3">
                        <button data-testid="education_add" className="w-100 btn btn-outline-info" onClick={addEducation}>Add education</button>
                    </div>
                </div>}
            </div>
        </>
    }

    const ContactInfo = () => {
        const [isEditing, setIsEditing] = useState(false);
        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditing(true);
        }
        const [errorPhone, setErrorPhone] = useState('');
        const [errorSecondEmail, setErrorSecondEmail] = useState('');

        const phoneFormatHandler = (phoneNumberString: string) => {
            const match = phoneNumberString.match(phoneFormat);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
            return "Phone number not verified";
        }

        const saveContact = async () => {
            setCurrWorkingState("working");

            let linkedinValue = refLinkedin.current.value;
            const linkedinUrlPattern = /^https:\/\/www\.linkedin\.com\/in\/(.+)$/;
            const linkedinUser = linkedinValue.match(linkedinUrlPattern);
            if (linkedinUser) {
                linkedinValue = linkedinUser[1];
            }

            const phoneValid = refPhone.current.value;
            const secondEmail = refSecondaryEmail.current.value;

            let hasError = false

            if (!phoneRegex.test(phoneValid)) {
                setErrorPhone('Please enter a valid 10-digit phone number.');
                hasError = true
            } else {
                setErrorPhone('');
            }

            if (!emailRegex.test(secondEmail)) {
                setErrorSecondEmail('Please enter a valid email address (e.g., example@domain.com)');
                hasError = true
            } else {
                setErrorSecondEmail('');
            }

            if (hasError) {
                return
            }

            await updateTechAPIAndLogalStorage({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    linkedin: linkedinValue,
                    phone: refPhone.current.value,
                    secondaryEmail: refSecondaryEmail.current.value,
                }
            }, undefined);

            setUserInfo({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    linkedin: linkedinValue,
                    phone: refPhone.current.value,
                    secondaryEmail: refSecondaryEmail.current.value,
                }
            });
            setIsEditing(false);
            setCurrWorkingState("done");
        }

        const refLinkedin = useRef<any>({});
        const refPhone = useRef<any>({});
        const refSecondaryEmail = useRef<any>({});

        useEffect(() => {
            refLinkedin.current.value = `https://www.linkedin.com/in/${userInfo?.other?.linkedin}`;
            refPhone.current.value = userInfo?.other?.phone;
            refSecondaryEmail.current.value = userInfo.other.secondaryEmail;
            // eslint-disable-next-line
        }, [])

        return (
            <>
                {!isEditing &&
                    <>
                        <div className={"d-flex align-items-center justify-content-between w-100 " + ((workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : '')}>
                            <div className="mt-4">
                                {userInfo.inmycontactlist &&
                                    <>
                                        {!!userInfo.other?.phone && (<p className="m-0">
                                            <i className="fa-solid fa-phone fa-xs me-3 text-info"></i>
                                            {phoneFormatHandler(userInfo?.other?.phone)}
                                        </p>)}
                                        {!!!userInfo.other.phone && (
                                            <p style={{ color: "#a5a5a5" }} className="m-0">
                                                <i className="fa-solid fa-phone fa-xs me-3"></i>
                                                <small> No phone number to show</small>
                                            </p>)}
                                        {!!userInfo.other?.secondaryEmail && (<p className="m-0">
                                            <i className="fa-solid fa-envelope fa-xs me-3 text-info"></i>
                                            {userInfo?.other?.secondaryEmail}
                                        </p>)}
                                        {!!!userInfo.other.secondaryEmail && (
                                            <p style={{ color: "#a5a5a5" }} className="m-0">
                                                <i className="fa-solid fa-envelope fa-xs me-3"></i>
                                                <small> No email to show</small>
                                            </p>)}
                                        {!!!userInfo.other.linkedin && (
                                            <p style={{ color: "#a5a5a5" }} className="m-0">
                                                <i className="fab fa-linkedin fa-xs me-3"></i>
                                                <small> No LinkedIn to show</small>
                                            </p>)}
                                        {!!userInfo.other.linkedin && (
                                            <a href={`https://www.linkedin.com/in/${userInfo.other.linkedin}`} target="_blank" rel="noopener noreferrer" data-testid="linkedin-button">
                                                <i className="fab fa-linkedin fa-xs me-3 text-info"></i>
                                                {userInfo.other.linkedin}
                                                <i className="fa-solid fa-arrow-up-right-from-square fa-2xs ms-2"></i>
                                            </a>
                                        )}
                                    </>
                                }

                                {editable && <>
                                    <div className="" >
                                        <p>
                                            <i className="fa-solid fa-phone fa-xs text-info me-3"></i>
                                            {userInfo.other.phone ? phoneFormatHandler(userInfo.other.phone) : ""}
                                            {!!!userInfo.other.phone && (
                                                <small className="alert alert-danger py-0 px-2" role="alert">
                                                    No phone number found.
                                                </small>
                                            )}
                                        </p>
                                    </div>
                                    <div className="">
                                        <p>
                                            <i className="fa-solid fa-envelope fa-xs text-info me-3"></i>
                                            {userInfo?.other?.secondaryEmail}
                                            {!!!userInfo.other.secondaryEmail && (
                                                <small className="alert alert-danger py-0 px-2" role="alert">
                                                    No email found.
                                                </small>
                                            )}
                                        </p>
                                    </div>
                                    {!!userInfo.other?.linkedin && (
                                        <>
                                            <a href={`https://www.linkedin.com/in/${userInfo.other.linkedin}`} target="_blank" rel="noopener noreferrer" data-testid="linkedin-button">
                                                <i className="fab fa-linkedin fa-xs text-info me-3 mb-0"></i>
                                                {userInfo.other.linkedin}
                                                <i className="fa-solid fa-arrow-up-right-from-square fa-2xs ms-2"></i>
                                            </a>
                                        </>)}
                                    {(!!!userInfo.other.linkedin) && (<>
                                        <p>
                                            <i className="fab fa-linkedin fa-xs text-info me-3 mb-0"></i>
                                            <small className="alert alert-danger py-0 px-2" role="alert">
                                                No linkedIn link found.
                                            </small>
                                        </p>
                                    </>
                                    )}
                                </>}
                            </div>
                            {!userId &&
                                <div className="fileTooltip rightEdit ms-auto" role="button" data-tooltip=" Edit "
                                    onClick={editableOnClickHandler}
                                    data-testid="edit-icon">
                                    <i className="fa-regular fa-pen-to-square fa-xs p-2 w-100 h-100 shadow d-flex justify-content-center align-items-center bg-light rounded-circle" data-testid="edit-icon"
                                    ></i>
                                </div>
                            }
                            {(!userInfo.inmycontactlist && userId) && <>
                                <div className="alert alert-light fw-bold mb-0" role="alert" data-testid="send-invitation-test">
                                    Send an invitation to {userInfo.name} to see their contact information.
                                </div>
                            </>}
                        </div>
                        {/* <button>Upload Resume</button> */}
                    </>
                }
                {isEditing &&
                    <>
                        <div className="rounded p-3 shadow w-auto h-auto my-3">
                            <h6>Contact Information:</h6>
                            <p >
                                <i className="fa-solid fa-phone fa-sm me-3"></i>
                                <small className="font-weight-light">
                                    Please provide your phone number so laboratories can get in touch with you.
                                </small>
                                <input
                                    type="text"
                                    maxLength={10}
                                    className="form-control mt-2"
                                    ref={refPhone}
                                    defaultValue={`${userInfo?.other?.phone}`}
                                    placeholder="10 digit phone number"
                                />
                                {errorPhone && <p className="text-danger"><small>{errorPhone}</small></p>}
                            </p>
                            <p >
                                <i className="fa-solid fa-envelope fa-sm me-3"></i>
                                <small className="font-weight-light">
                                    Please enter the email address where you would like laboratories to contact you.
                                    <br />
                                    Note that changing this email address will not affect your login email address.
                                </small>
                                <input
                                    type="text"
                                    className="form-control mt-2"
                                    ref={refSecondaryEmail}
                                    defaultValue={`${userInfo?.other?.secondaryEmail}`}
                                    title="Please enter a valid email address (e.g., example@domain.com)"
                                />
                                {errorSecondEmail && <p className="text-danger"><small>{errorSecondEmail}</small></p>}
                            </p>

                            <p>
                                <i className="fab fa-linkedin fa-sm me-3"></i>
                                <small className="font-weight-light">
                                    If you have a LinkedIn account, please share the link or username so laboratories can connect with you.
                                </small>
                                <input
                                    type="text"
                                    className="form-control mt-2"
                                    ref={refLinkedin}
                                    defaultValue={`https://www.linkedin.com/in/${userInfo?.other?.linkedin}`}
                                />
                            </p>

                            <div className="d-grid gap-2 mt-2 w-100 d-md-block">
                                <button className="btn btn-primary px-4" onClick={saveContact}>Save</button>
                                <button className="btn btn-danger ms-md-2" onClick={() => {
                                    refLinkedin.current.value = `https://www.linkedin.com/in/${userInfo?.other?.linkedin}`;
                                    setIsEditing(false);
                                }} >Cancel</button>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    };

    const Resume = () => {
        const refResume = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (userInfo && userInfo.images && userInfo.images.resume) {
                setResume(getImagePath(userInfo.images.resume, "fixed", !editable ? userId : undefined));
                setSuccessUpload(true);
            }
            makeDroppableCapable(refResume, "resume", "resume", "fixed", (newfile) => {
                updateResume(newfile);
            });
            // eslint-disable-next-line
        }, [userInfo, userId, editable, updateResume])

        const successUploadMessage = (
            <div className="d-flex text-start justify-content-center flex-column align-items-center">
                <p className="text-success fw-bold"><small> Your resume has been successfully uploaded! </small></p>
                <p className="d-none d-lg-block d-xl-block"><small>Drag and drop a new file or click to update. </small></p>
                <a href={resume} className="btn-link">See resume. <i className="fa-solid fa-arrow-up-right-from-square fa-2xs"></i></a>
            </div>
        );

        return (
            <>
                {!userId &&
                    <div className="p-3 me-md-3 mb-3">
                        <div>
                            <h5 className="fw-bold mb-3"><i className="fa-solid fa-file me-3 text-info"></i>RESUME</h5>
                            <p>
                                <small>PDF or Word type files, max 10MB.</small>
                            </p>
                        </div>
                        <div ref={refResume}
                            className="rounded p-2 border-secondary shadow-sm dashed-border text-center d-flex justify-content-center flex-column align-items-center"
                        >
                            <p>
                                {successUpload ? successUploadMessage : "Drag and drop your resume onto this area or "}
                            </p>
                            <FileUploaderComponent relatedComponent={refResume} tooltipPosition="left" fileToolTipText="" />
                        </div>
                    </div>
                }
            </>
        )
    };

    const TechStatement = () => {
        const [isEditing, setIsEditing] = useState(false);
        const [showError, setShowError] = useState(false);
        const [currWorkingState, setCurrWorkingState] = useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditing(true);
        }

        const saveStatement = async () => {

            setCurrWorkingState("working");
            setShowError(false);
            try {
                await updateTechAPIAndLogalStorage({
                    ...userInfo,
                    other: {
                        ...userInfo.other,
                        description: refStatement.current.value,
                    }
                }, undefined);

                setUserInfo({
                    ...userInfo,
                    other: {
                        ...userInfo.other,
                        description: refStatement.current.value,
                    }
                });
                setIsEditing(false);
            } catch (error: unknown) {
                if (ERROR_IS_FRAUD(error)) {
                    setShowError(true);
                }
                setIsEditing(true);
            }

            setCurrWorkingState("done");
        }

        const refStatement = useRef<any>({});

        useEffect(() => {

            refStatement.current.value = userInfo.other.description;
            //eslint-disable-next-line
        }, [])
        return (
            <>
                {!isEditing &&
                    <>
                        <div className="d-flex justify-content-between w-100">
                            <h5 className="fw-bold"><i className="fa-solid fa-align-left me-3 text-info"></i>ABOUT ME</h5>
                            {!userId &&
                                <div className="fileTooltip rightEdit ms-auto" role="button" data-tooltip=" Edit "
                                    onClick={editableOnClickHandler}
                                    data-testid="edit-icon">
                                    <i className="fa-regular fa-pen-to-square fa-xs p-2 w-100 h-100 shadow d-flex justify-content-center align-items-center bg-light rounded-circle" data-testid="edit-icon"
                                    ></i>
                                </div>
                            }
                        </div>
                        <div className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading ' : 'mt-3 overflow-auto aboutTech'} >
                            <p>
                                {userInfo?.other?.description}
                            </p>
                            {!!!userInfo.other.description && (
                                <p style={{ color: "#a5a5a5" }} className="m-0">
                                    <small> No information provided yet.</small>
                                </p>)}
                        </div>
                    </>
                }
                {isEditing &&
                    <>
                        {showError && <ErrorMessageFraud closeClickHandler={() => { setShowError(false) }} />}
                        <div className={(workingState !== 'done' || currWorkingState !== 'done') ? 'cssLoading' : ''}>
                            <h6> <i className="fa-solid fa-align-left me-2"></i>About Me:</h6>
                            <p >
                                <small className="font-weight-light">
                                    Please provide a compelling statement of how your skills and contributions can positively impact the company.
                                </small>
                                <textarea
                                    disabled={currWorkingState === 'working'}
                                    className="form-control text-justify mt-2"
                                    ref={refStatement}
                                    rows={6}
                                    defaultValue={userInfo?.other?.description || ""}
                                />
                            </p>
                        </div>
                        <div className="d-grid gap-3 mt-2 w-100 d-md-block">
                            <button
                                disabled={currWorkingState === 'working'}
                                className="btn btn-primary px-4" onClick={saveStatement}>Save {currWorkingState === 'working' && <span className="ms-2"><SpinnerSmall /></span>}</button>
                            <button
                                disabled={currWorkingState === 'working'}
                                className="btn btn-danger ms-md-2 px-3"
                                onClick={() => {
                                    setShowError(false);
                                    setIsEditing(false);
                                }} >Cancel</button>
                        </div>
                    </>
                }
            </>
        )
    }

    return <>
        {!userExist && <UserNotExist />}
        {userExist && <>
            <div className={"container-fluid labprofilecontentblock" + (workingState === 'done' ? '' : 'cssLoading')}
                ref={refBackgroundPhoto}
                style={{
                    [backgroundImg ? 'backgroundImage' : '']: `url(${backgroundImg})`,
                    height: "20rem"
                }}>
                <div className="container">
                    <div className="row pt-3">
                        {editable &&
                            <FileUploaderComponent relatedComponent={refBackgroundPhoto} tooltipPosition="right" fileToolTipText="Click here to edit your background photo, or drag and drop it onto this area." />
                        }
                        {!editable && <BackButton btnText={currentUserInfo?.type === "admin" ? " Return to list" : " Return to techs list"} />}
                        {(inappropriateContent || unsupportedImageType) && (
                            <ErrorMessageSticky closeClickHandler={() => { }}
                                message={inappropriateContent ? "Image not accepted. Try again with another image." : unsupportedImageType ? "Image format type not accepted. Make sure your image is in JPEG or PNG format." : ""} />
                        )}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    <div className="row gap-3 justify-content-center lab-row">
                        <div className="col-lg-5 bg-light rounded p-4">
                            <div className="row">
                                <div className="col-md-4 my-2 p-0 d-flex justify-content-center">
                                    <div ref={refLogoPhoto}
                                        className={"rounded-circle border border-2 border-light profile-logo " + (workingState === 'done' ? '' : 'cssLoading')}
                                        style={{
                                            backgroundImage: `url(${workingState === 'done' ? logoImg : ASSETS_ROUTES.IMGS.techAvatarUrl})`
                                        }}
                                    >
                                        {editable &&
                                            <FileUploaderComponent relatedComponent={refLogoPhoto} tooltipPosition="left" fileToolTipText="Click here to edit your photo, or drag and drop it onto this area." />
                                        }
                                    </div>
                                </div>
                                <div className="col-md-8 my-2 d-flex justify-content-between flex-column">
                                    <NameSection />
                                    <ContactInfo />
                                    {currentUserInfo?.type === "lab" && <>
                                        {userInfo && userInfo.images && userInfo.images.resume &&
                                            <div className="my-2">
                                                <a className="btn btn-outline-primary w-100 rounded-3" href={resume}>View Resume
                                                    <i className="fa-solid fa-arrow-up-right-from-square fa-2xs ms-3"></i></a>
                                            </div>
                                        }
                                    </>}
                                    {currentUserInfo?.type === "lab" && <>
                                        {!userInfo.inmycontactlist &&
                                            <InviteButtons />
                                        }
                                    </>}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 bg-light rounded p-4">
                            <TechStatement />
                        </div>

                    </div>
                </div>

                <div className="container p-0 mt-4">
                    <div className="row gap-3 justify-content-center lab-row">
                        <div className="col-lg-5 ">
                            <div className="row bg-light rounded p-4 mb-4">
                                <PositionsSection />
                            </div>
                            <div className="row bg-light rounded p-4 mb-4">
                                <TechInfoSection />
                            </div>
                            {currentUserInfo?.type === "tech" &&
                                <div className="row bg-light rounded p-4 mb-4">
                                    <Resume />
                                </div>
                            }
                        </div>

                        <div className="col-lg-6 ">
                            {/* {((!!userInfo.experience) || editable) && */}
                            <div className="row bg-light rounded p-4 mb-4">
                                <ExperienceSection />
                            </div>
                            {/* } */}
                            {/* {((!!userInfo.education) || editable) && <> */}
                            <div className="row bg-light rounded p-4">
                                <EducationSection />
                            </div>
                            {/* </>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
    </>
}

export const DentalTechInfo = ({
    editable = false,
}: Pick<TDentalTechProps, 'editable'>) => {

    const { userId } = useParams();
    const { getTech, state } = useTechnicians();
    const [userInfo, setUserInfo] = useState<TApiTechnician[]>([]);

    useEffect(() => {
        if (userId) {

            getTech(userId).then(userInfo => {
                setUserInfo(userInfo)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    return (
        <>
            <NotificationProvider>
                <Header title="" />
                <>
                    {state === "working" && <Spinner />}
                    {state !== "working" && <>
                        <TechInfo editable={editable}
                            userExist={userId === undefined ? true : userInfo.length > 0}
                            techInfo={userInfo[0]} />
                        {userInfo.length > 0 &&
                            <div className="container-fluid">
                                <div className="container">
                                    <div className="row justify-content-center justify-content-md-end lab-row mt-5">
                                        <div className="w-auto py-3 px-5 text-center text-md-end">
                                            <ReportButton userId={userId || ""} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </>}
                </>
                <Footer />
            </NotificationProvider >
        </>
    )
}