type TMessageType = {
    message: string,
    closeClickHandler?: () => void
}

export const ErrorMessageSticky = ({ message }: TMessageType) => {
    return (<>

        <div className="alert bg-danger text-white text-center py-3 mt-2 mx-auto error-message-alert" role="alert">
            <i className="fa-solid fa-square-xmark fa-2xl me-2"></i>
            <span>
                {message}
            </span>
        </div>
    </>)
}

export const ErrorMessageFraud = ({ closeClickHandler }: { closeClickHandler?: () => void }) => {
    return <ErrorMessageSticky
        closeClickHandler={closeClickHandler}
        message="We detected your content may be considered as Advertising, Scam or Fraud, please review it and try again." />
};